@charset 'UTF-8';
/* Slider */
.slick-loading .slick-list
{
    background: #fff url('./ajax-loader.gif') center center no-repeat;
}

@font-face {
  font-family: 'swiper-icons';
  src: url("../../fonts/swiper-icons.ttf") format("woff");
  font-weight: 400;
  font-style: normal;
}


/* Icons */
@font-face
{
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;

    src: url('./fonts/slick.eot');
    src: url('./fonts/slick.eot?#iefix') format('embedded-opentype'), url('./fonts/slick.woff') format('woff'), url('./fonts/slick.ttf') format('truetype'), url('./fonts/slick.svg#slick') format('svg');
}
/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;
		
    position: absolute;
    top: 50%;

    display: block;
		/*display:none !important;*/
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 0.5;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .5	;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{		
    left: 8px;
    z-index: 1050
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{		
  	content: '←';
    color: #aea7a7;
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
}

.slick-next
{
    right: 8px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    content: '→';
    color: #aea7a7;
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.	
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 18px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
    border: 1px solid #fff;
}

.csliderNoArrows {
	background: #fff;
	color: #838080;
	font-size: 15px;
	height: 100%;
	/*margin: 10px;*/
	/*padding: 2%;*/
	position: relative;
	text-align: left;
	margin-left: -10px;
	margin-right: -10px;
}

.csliderNoArrows button {
	display: block !important;
}

.cslider {
	background: #fff;
	color: #838080;
	font-size: 15px;
	height: 100%;
	margin: 0px;
	position: relative;
	text-align: left;
}

.space-items-slider {
	padding-right: 2%;
	padding-left: 2%;
}

.sliderzoomable .slider-img img {
	 transition: .3s linear;
}


.sliderzoomable .slider-img:hover img {
/*	  transform: scale(1.15);*/
}

.slick-thumb-zoom{
	position: fixed;
	bottom:0;
	left:0;
	margin-left:10px;
}

.slick-thumb-zoom li
{
    position: relative;
    display: inline-block;
    width: 90px !important;
    height: 90px !important;
    margin: 0 1px;
    padding: 0;
    cursor: pointer;
}

.slick-thumb-zoom  li.slick-active
{
   /* opacity: .75;*/
    color: black;
    border: 0.2em solid #ddd;
}


@media only screen and (max-width: 767.98px) {
	.csliderNoArrows ul {
		background: #fff;
		color: #838080;
		font-size: 10px;
		height: 227px;
		margin: 0px;
		padding: 2%;
		position: relative;
/*		text-align: left;*/
	}
	.cslider ul {
		background: #fff;
		color: #838080;
		font-size: 10px;
		height: 227px;
		margin: 10px;
		padding: 2%;
		position: relative;
		text-align: left;
	}
	.csliderNoArrows button {
		display: block !important;
	}
	
	.slick-dots{
		bottom: -20px !important;
	}
}

.slick-arrow img {
	opacity: 0.8 !important;
}

.slick-arrow img:hover{
	opacity: 0.6 !important;
}

.slick-thumb{
	/*argin-top: 10px;*/
	position: relative;
	display: "flex" !important;
}

.slick-thumb li
{
    position: relative;

    display: inline-block;

    width: 75px !important;
    height: 75px !important;
    margin: 0 1px;
    padding: 0;
		border: 0.2em solid transparent;
    cursor: pointer;
    border-radius: 2px;
}

.slick-thumb li.slick-active
{
   /* opacity: .75;*/
    color: black;
    border: 0.2em solid #ddd;
}
