.rstm-toggle-icon {
  display: inline-block; }
  .rstm-toggle-icon-symbol {
    width: 2rem;
    height: 2rem;
    color: black;
		font-weight: bold;

    text-align: center;
    line-height: 2rem; }

.rstm-tree-item-group {
  list-style-type: none;
  padding-left: 0;
  /*border-top: 1px solid #ccc;*/
  text-align: left;
  width: 100%;
  font-size: 15px;
  font-family: 'Roboto','Helvetica Neue',Helvetica,Arial,sans-serif;
   }

.rstm-tree-item {
  /*padding: 0.75rem 1rem;*/
  padding-top: 0rem !important;
  /*padding-left: 0.5rem !important;*/
  cursor: pointer;
  /*color: #686058;*/
  background: none;
  /*border-bottom: 1px solid #ccc;*/
  box-shadow: none;
  z-index: unset;
  position: relative; 
}

.rstm-tree-item span:hover {
	font-weight: bold;
	border-bottom: 1px solid #bab6b2;
}	
	
.rstm-tree-item--active {
  font-weight:bold; 
  color: #333;
	border-bottom: none; 
}
.rstm-tree-item--focused {
/*box-shadow: 0 0 5px 0 #222;*/
	font-weight: bold;
	z-index: 999; 
}

.rstm-search {
  padding: 1rem 1.5rem;
  border: none;
  width: 100%; 
}

.rstm-tree-item-level0{
	margin-left: -1rem !important;
	font-size: 17px;
	line-height: 26px;
	font-family: 'Roboto','Helvetica Neue',Helvetica,Arial,sans-serif;
}
.rstm-tree-item-level1{
	margin-left: -2rem !important;
	font-size: 15px;
	font-family: 'Roboto','Helvetica Neue',Helvetica,Arial,sans-serif;
}

.rstm-tree-item-level2{
	margin-left: -3rem !important;
	font-size: 13px;
	font-family: 'Roboto','Helvetica Neue',Helvetica,Arial,sans-serif;
}

