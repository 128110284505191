/*
Author       : Dreamguys
Template Name: Doccure - Bootstrap Admin Template
Version      : 1.3
*/

/*============================
 [Table of CSS]

1. General
2. Table
3. Helper Class
4. Bootstrap Classes
5. Select2
6. Nav Tabs
7. Components
8. Header
9. Sidebar
10. Content
11. Login
12. Lock Screen
13. Notifications
14. Dashboard
15. Invoice
16. Calendar
17. Inbox
18. Mail View
19. Error
20. Chat
21. Product
22. Product Details
23. Profile
24. Template Options
25. Blog
26. Blog Details
27. Blog Grid
28. Responsive
29. Custom
30. CheckOut
========================================*/

/*-----------------
	1. General
-----------------------*/

@import url('https://fonts.googleapis.com/css?family=Mada:400,500,600,700&display=swap');

@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src: url(../fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
	src: local('Material Icons'),
	local('MaterialIcons-Regular'),
	url(../fonts/MaterialIcons-Regular.woff2) format('woff2'),
	url(../fonts/MaterialIcons-Regular.woff) format('woff'),
	url(../fonts/MaterialIcons-Regular.ttf) format('truetype');
}

p {
	/*
	margin-top:0;
	margin-bottom:0.5rem;
	*/
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
	
}

@font-face {
    font-family: "Akkurat";
    src: url("../fonts/ci/Akkurat.ttf");
}
@font-face {
    font-family: "Akkurat Bold";
    src: url("../fonts/ci/Akkurat-Bold.ttf");
    font-weight: bold;
}


@font-face {
    font-family: "Roboto Light";
    src: url("../fonts/ci/RobotoCondensed-Light.ttf");
}
@font-face {
    font-family: "Roboto Regular";
    src: url("../fonts/ci/RobotoCondensed-Regular.ttf");
    /*font-weight: bold;*/
}
 
@font-face {
    font-family: "Roboto Condensed";
    src: url("../fonts/ci/RobotoCondensed-Regular.ttf");
    /*font-style: oblique;*/
}
 
@font-face {
    font-family: "Roboto";
    src: url("../fonts/ci/Roboto-Regular.ttf");
/*    font-weight: bold;
    font-style: oblique;*/
}



html {
	height: 100%;
}
body {
	background-color: #fff;
	font-family: 'Roboto Condensed','Helvetica Neue',Helvetica,Arial,sans-serif;
	font-size: 13px;
	color: #696158;
	font-weight: 400;
	/*height: 100%;*/
	line-height: normal;
	overflow-x: hidden;
	text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Roboto Condensed','Helvetica Neue',Helvetica,Arial,sans-serif;
	margin-top: 0;
	margin-bottom: 0rem;
}


.h7-responsive{
	font-weight: bold !important;
	font-size: 103% !important;
}

.h6-responsive{
	font-weight: bold !important;
	font-size: 105% !important;	
}
.h5-responsive{
	font-weight: bold !important;
	font-size: 120% !important;	
}
.h4-responsive{
	font-weight: bold !important;
	font-size: 140% !important;	
}
.h3-responsive{
	font-weight: bold !important;
	font-size: 169% !important;	
}
.h2-responsive{
	font-weight: bold !important;
	font-size: 180% !important;	
}
.h1-responsive{
	font-weight: bold !important;
	font-size: 200% !important;	
}




img{
	vertical-align: bottom;
}

.attrib-link, .attrib-link:hover, .attrib-link:active, .attrib-link:focus, .attrib-link:visited {
	color: #d50032 !important;
	font-weight:600 !important;
}

.attrib-link:hover {
	text-decoration: underline !important;
}

a:hover,
a:active,
a:focus {
	outline: none;
	text-decoration: none;
	/*color: #000 !important;*/
	/*	background-color: #fff;
	border-color: #fff;*/
}
/*
a.active, a.active:hover, a.active:focus {
	background-color: #fff;
	border-color: #fff;
	outline: none;
	text-decoration: none;
}
*/
a span:hover,
a span:active,
a span:focus {
/*	color: #000 !important;*/
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}
.form-control {
    border: 1px solid #ddd;
    box-shadow: none;
    color: #333;
    font-size: 15px;
    height: 40px;
}
.form-control:focus {
	border-color: #d50032;
	box-shadow: none;
	outline: 0 none;
}
.form-control.form-control-sm {
	height: calc(1.5em + .5rem + 2px);
}

.form-control.form-control-lg {
	height: calc(1.5em + 1rem + 2px);
}
.resp-menu{display: none;}
/*input,*/
button,
a {
	/*transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
	-ms-transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;*/
	color:#696158;
	letter-spacing: 0;
	font-family: 'Roboto Condensed','Helvetica Neue',Helvetica,Arial,sans-serif !important;
	font-weight: 400;
}
a:active{
	color: #696158 ;
}
a:hover{
	color: #696158 ;
}
input, input:focus,
button, button:focus {
	outline: none;
}
input[type="file"] {
    height: auto;
    min-height: calc(1.5em + .75rem + 2px);
}
input[type=text],
input[type=password] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
textarea.form-control {
	resize: vertical;
}
.navbar-nav > li {
	float: left;
}
.form-group {
	margin-bottom: 0.5rem;
}
.input-group .form-control {
	height: 40px;
}
.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
	background-color: rgba(0, 0, 0, 0.1);
	border-color: rgba(0, 0, 0, 0.1);
}
.material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	font-feature-settings: 'liga';
}
.font-weight-600 {
	font-weight: 600;
}
.breadcrumb-item.active {
    color: #6c757d;
}

.container-page{
	/*min-height: 570px;	*/
}
/*-----------------
	2. Table
-----------------------*/

.table {
	color: #696158;
	max-width: 100%;
	margin-bottom: 0;
	width: 100%;
}
.table-striped > tbody > tr:nth-of-type(2n+1) {
	background-color: #f8f9fa;
}
.table.no-border > tbody > tr > td,
.table > tbody > tr > th,
.table.no-border > tfoot > tr > td,
.table.no-border > tfoot > tr > th,
.table.no-border > thead > tr > td,
.table.no-border > thead > tr > th {
	border-top: 0;
	padding: 10px 8px;
}
.table-nowrap td,
.table-nowrap th {
	white-space: nowrap
}
.table.dataTable {
	border-collapse: collapse !important;
}
table.table td h2 {
	display: inline-block;
	font-size: inherit;
	font-weight: 400;
	margin: 0;
	padding: 0;
	vertical-align: middle;
}
table.table td h2.table-avatar {
    align-items: center;
    display: inline-flex;
    font-size: inherit;
    font-weight: 400;
    margin: 0;
    padding: 0;
    vertical-align: middle;
	white-space: nowrap;
}
table.table td h2 a {
	color: #333;
}
table.table td h2 a:hover {
	color: #d50032;
}
table.table td h2 span {
	color: #888;
	display: block;
	font-size: 12px;
	margin-top: 3px;
}
.table thead {
	background-color: #f8f9fa;
	border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}
.table thead tr th {
	font-weight: 600;
	border: 0;
	text-align: center;
}

.table tbody tr td {
	text-align: center;
}
/*	
.table td, .table th{
	text-align: start;
}*/
.table tbody tr {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.table tbody tr:last-child {
	border-color: transparent;
}
.table.table-center td,
.table.table-center th {
	vertical-align: middle;
}
.table-hover tbody tr:hover {
	background-color: #f7f7f7;
}
.table-hover tbody tr:hover td {
	color: #474648;
}
.table-striped thead tr {
	border-color: transparent;
}
.table-striped tbody tr {
	border-color: transparent;
}
.table-striped tbody tr:nth-of-type(even) {
	background-color: rgba(255, 255, 255, 0.3);
}
.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(235, 235, 235, 0.4);
}
.table-bordered {
	border: 1px solid rgba(0, 0, 0, 0.05) !important;
}
.table-bordered th,
.table-bordered td {
	border-color: rgba(0, 0, 0, 0.05);
}
.card-table .card-body {
    padding: 0;
}
.card-table .card-body .table > thead > tr > th {
    border-top: 0;
}
.card-table .card-body .table tr td:first-child,
.card-table .card-body .table tr th:first-child {
    padding-left: 1.5rem;
}
.card-table .card-body .table tr td:last-child,
.card-table .card-body .table tr th:last-child {
    padding-right: 1.5rem;
}
.card-table .table td, .card-table .table th {
    border-top: 1px solid #e2e5e8;
    /*padding: 0.5rem 0.5rem;*/
    padding: 0.25rem 0.25rem;
    /*white-space: nowrap;*/
}
#delete_modal .modal-content {
	text-align: center;
}
/*-----------------
	3. Helper Class
-----------------------*/
#toggle_bttn {
    align-items: center;
    color: #333;
    display: inline-flex;
    float: left;
    font-size: 30px;
    height: 60px;
    justify-content: center;
    margin-left: 15px;
    padding: 0 15px;}

.mini-sidebar .menu{width: 58px !important}
.mini-sidebar .menu:hover{width: 240px !important}
/* .menu OLD-dropdown li:hover{background:#000;} */
.p-20 {
	padding: 20px !important;
}
.p-t-0 {
	padding-top: 0 !important;
}
.m-0 {
	margin: 0 !important;
}
.m-r-5 {
	margin-right: 5px !important;
}
.m-r-10 {
	margin-right: 10px !important;
}
.m-l-5 {
	margin-left: 5px !important;
}
.m-l-15 {
	margin-left: 15px !important;
}
.m-t-5 {
	margin-top: 5px !important;
}
.m-t-0 {
	margin-top: 0 !important;
}
.m-t-10 {
	margin-top: 10px !important;
}
.m-t-15 {
	margin-top: 15px !important;
}
.m-t-20 {
	margin-top: 20px !important;
}
.m-t-30 {
	margin-top: 30px !important;
}
.m-t-50 {
	margin-top: 50px !important;
}
.m-b-5 {
	margin-bottom: 5px !important;
}
.m-b-10 {
	margin-bottom: 10px !important;
}
.m-b-15 {
	margin-bottom: 15px !important;
}
.m-b-20 {
	margin-bottom: 20px !important;
}
.m-b-30 {
	margin-bottom: 30px !important;
}
.block {
	display: block !important;
}
.cal-icon {
	position: relative;
	width: 100%;
}
.cal-icon:after {
	color: #979797;
	content: "\f073";
	display: block;
	font-family: "FontAwesome";
    font-size: 15px;
    margin: auto;
    position: absolute;
    right: 15px;
    top: 10px;
}

/*-----------------
	4. Bootstrap Classes
-----------------------*/

.btn.focus, .btn:focus {
    box-shadow: unset;
}
.btn-white {
	background-color: #fff;
	border: 1px solid #ccc;
	color: #333;
}
.btn.btn-rounded {
	border-radius: 50px;
}
.bg-primary,
.badge-primary {
	background-color: #d50032 !important;
}
a.bg-primary:focus, 
a.bg-primary:hover, 
button.bg-primary:focus, 
button.bg-primary:hover {
    background-color: #19c1dc !important;
}
.bg-success,
.badge-success {
	background-color: #7bb13c !important;
}
a.bg-success:focus, 
a.bg-success:hover, 
button.bg-success:focus, 
button.bg-success:hover {
    background-color: #699834 !important;
}
.bg-info,
.badge-info {
	background-color: #009efb !important;
}
a.bg-info:focus, 
a.bg-info:hover, 
button.bg-info:focus, 
button.bg-info:hover {
    background-color: #028ee1 !important;
}
.bg-warning,
.badge-warning {
	background-color: #ffdb94 !important;
	color : #0f0f0e !important;
}
a.bg-warning:focus, 
a.bg-warning:hover, 
button.bg-warning:focus, 
button.bg-warning:hover {
    background-color: #ffca5f !important;
}
.bg-danger,
.badge-danger {
	background-color: #e84646 !important;
}
a.bg-danger:focus, 
a.bg-danger:hover, 
button.bg-danger:focus, 
button.bg-danger:hover {
    background-color: #e63333 !important;
}
.bg-white {
	background-color: #fff;
}
.bg-purple,
.badge-purple {
    background-color: #9368e9 !important;
}
.text-primary,
.dropdown-menu > li > a.text-primary {
	color: #d50032 !important
}
.text-success,
.dropdown-menu > li > a.text-success {
	color: #699834 !important;
}
.text-danger,
.dropdown-menu > li > a.text-danger {
	color: #e84646 !important;
}
.text-info,
.dropdown-menu > li > a.text-info {
	color: #009efb !important;
}
.text-warning,
.dropdown-menu > li > a.text-warning {
	color: #ffd682 !important;
}
.text-purple,
.dropdown-menu > li > a.text-purple {
	color: #7460ee !important;
}
.text-muted {
  color: #757575 !important;
  font-style: oblique;
	font-size: 12px;
}

.text-secondary {
    color:#676159 !important;
}
.border-primary {
    border-color: #d50032!important;
}
.btn-primary {
	background-color: #d50032;
	border-color: #d50032;
	color: #fff !important;
}
.ant-switch-checked {
    background-color: #55ce63;
}
.alert-secondary {
  color: #676159;
  background-color: #e6e6e6;
  border-color: #676159;
}
.alert{
	border-radius: 5px;	
}


.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
    background-color: #d50032;
    border-color: #d50032
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #d50032;
}
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
	background-color: #19c1dc;
	border: 1px solid #19c1dc;
}
.btn-primary.active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle {
	background-color: #19c1dc;
	border-color: #19c1dc;
	color: #fff;
}
.btn-primary.active:focus:not(:disabled):not(.disabled), 
.btn-primary:active:focus:not(:disabled):not(.disabled), 
.show > .btn-primary.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-primary.disabled, .btn-primary:disabled {
    background-color: #d50032;
    border-color: #d50032;
    color: #fff;
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #676159;
  border-color: #676159;
}

.btn-secondary.click, .btn-secondary:click {
  color: #fff;
  background-color: #676159;
  border-color: #676159;
}

.btn-secondary.active:focus:not(:disabled):not(.disabled), 
.btn-secondary:active:focus:not(:disabled):not(.disabled), 
.show > .btn-secondary.dropdown-toggle:focus {
	box-shadow: unset;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.active,
.btn-secondary:active,
.open > .dropdown-toggle.btn-secondary {
	background-color: #676159;
	border: 1px solid #676159;
	color: #fff;
}

.btn-secondary {
	background-color: #676159;
	border-color: #676159;
	color: #fff !important;
}

.btn-secondary.active.focus,
.btn-secondary.active:focus,
.btn-secondary.active:hover,
.btn-secondary.focus:active,
.btn-secondary:active:focus,
.btn-secondary:active:hover,
.open > .dropdown-toggle.btn-secondary.focus,
.open > .dropdown-toggle.btn-secondary:focus,
.open > .dropdown-toggle.btn-secondary:hover {
	background-color: #676159;
	border: 1px solid #676159;
}


.btn-success {
	background-color: #7bb13c;
	border: 1px solid #7bb13c
}

.btn-success:hover,
.btn-success:focus,
.btn-success.active,
.btn-success:active,
.open > .dropdown-toggle.btn-success {
	background-color: #699834;
	border: 1px solid #699834;
	color: #fff;
}
.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover {
	background-color: #699834;
	border: 1px solid #699834
}
.btn-success.active:not(:disabled):not(.disabled),
.btn-success:active:not(:disabled):not(.disabled),
.show > .btn-success.dropdown-toggle {
	background-color: #699834;
	border-color: #699834;
	color: #fff;
}
.btn-success.active:focus:not(:disabled):not(.disabled), 
.btn-success:active:focus:not(:disabled):not(.disabled), 
.show > .btn-success.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-success.disabled, .btn-success:disabled {
    background-color: #7bb13c;
    border-color: #7bb13c;
    color: #fff;
}
.btn-info {
	background-color: #009efb;
	border: 1px solid #009efb
}
.btn-info:hover,
.btn-info:focus,
.btn-info.active,
.btn-info:active,
.open > .dropdown-toggle.btn-info {
	background-color: #028ee1;
	border: 1px solid #028ee1
}
.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover {
	background-color: #028ee1;
	border: 1px solid #028ee1
}
.btn-info.active:not(:disabled):not(.disabled),
.btn-info:active:not(:disabled):not(.disabled),
.show > .btn-info.dropdown-toggle {
	background-color: #028ee1;
	border-color: #028ee1;
	color: #fff;
}
.btn-info.active:focus:not(:disabled):not(.disabled), 
.btn-info:active:focus:not(:disabled):not(.disabled), 
.show > .btn-info.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-info.disabled, .btn-info:disabled {
    background-color: #009efb;
    border-color: #009efb;
    color: #fff;
}
.btn-warning {
	background-color: #ffd682;
	border: 1px solid #ffd682
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.active,
.btn-warning:active,
.open > .dropdown-toggle.btn-warning {
	background-color: #ffca5f;
	border: 1px solid #ffca5f
}
.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover {
	background-color: #ffca5f;
	border: 1px solid #ffca5f
}
.btn-warning.active:not(:disabled):not(.disabled),
.btn-warning:active:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle {
	background-color: #ffca5f;
	border-color: #ffca5f;
	color: #fff;
}
.btn-warning.active:focus:not(:disabled):not(.disabled), 
.btn-warning:active:focus:not(:disabled):not(.disabled), 
.show > .btn-warning.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-warning.disabled, .btn-warning:disabled {
    background-color: #ffd682;
    border-color: #ffd682;
    color: #fff;
}
.btn-danger {
	background-color: #e84646;
	border: 1px solid #e84646;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.active,
.btn-danger:active,
.open > .dropdown-toggle.btn-danger {
	background-color: #e63333;
	border: 1px solid #e63333;
}
.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover {
	background-color: #e63333;
	border: 1px solid #e63333;
}
.btn-danger.active:not(:disabled):not(.disabled),
.btn-danger:active:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle {
	background-color: #e63333;
	border-color: #e63333;
	color: #fff;
}
.btn-danger.active:focus:not(:disabled):not(.disabled), 
.btn-danger:active:focus:not(:disabled):not(.disabled), 
.show > .btn-danger.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-danger.disabled, .btn-danger:disabled {
    background-color: #f62d51;
    border-color: #f62d51;
    color: #fff;
}
.btn-light.active:focus:not(:disabled):not(.disabled), 
.btn-light:active:focus:not(:disabled):not(.disabled), 
.show > .btn-light.dropdown-toggle:focus {
    box-shadow: unset;
}
.btn-dark.active:focus:not(:disabled):not(.disabled), 
.btn-dark:active:focus:not(:disabled):not(.disabled), 
.show > .btn-dark.dropdown-toggle:focus {
    box-shadow: unset;
}
/*
.btn-outline-primary {
	color: #d50032;
	border-color: #d50032;
}
.btn-outline-primary:hover {
	background-color: #d50032;
	border-color: #d50032;
}
.btn-outline-primary:focus, 
.btn-outline-primary.focus {
	box-shadow: none;
}
.btn-outline-primary.disabled, 
.btn-outline-primary:disabled {
	color: #d50032;
	background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, 
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
	background-color: #d50032;
	border-color: #d50032;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, 
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
	box-shadow: none;
}
*/
.btn-outline-success {
	color: #7bb13c;
	border-color: #7bb13c;
}
.btn-outline-success:hover {
	background-color: #7bb13c;
	border-color: #7bb13c;
}
.btn-outline-success:focus, .btn-outline-success.focus {
	box-shadow: none;
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
	color: #7bb13c;
	background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, 
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
	background-color: #7bb13c;
	border-color: #7bb13c;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, 
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-info {
	color: #009efb;
	border-color: #009efb;
}
.btn-outline-info:hover {
	color: #fff;
	background-color: #009efb;
	border-color: #009efb;
}
.btn-outline-info:focus, .btn-outline-info.focus {
	box-shadow: none;
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
	background-color: transparent;
	color: #009efb;
}
.btn-outline-info:not(:disabled):not(.disabled):active, 
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
	background-color: #009efb;
	border-color: #009efb;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, 
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-warning {
	color: #ffd682;
	border-color: #ffd682;
}
.btn-outline-warning:hover {
	color: #212529;
	background-color: #ffd682;
	border-color: #ffd682;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
	box-shadow: none;
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
	background-color: transparent;
	color: #ffd682;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, 
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
	color: #212529;
	background-color: #ffd682;
	border-color: #ffd682;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, 
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-danger {
	color: #e84646;
	border-color: #e84646;
}
.btn-outline-danger:hover {
	color: #fff;
	background-color: #e84646;
	border-color: #e84646;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
	box-shadow: none;
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
	background-color: transparent;
	color: #e84646;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, 
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
	background-color: #e84646;
	border-color: #e84646;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, 
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-light {
    color: #ababab;
    border-color: #e6e6e6;
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #ababab;
}
.pagination > .active > a, 
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
	background-color: #d50032;
	border-color: #d50032;
}
.pagination > li > a, 
.pagination > li > span {
	color: #d50032;
}
.page-link:hover {
	color: #d50032;
}
.page-link:focus {
	box-shadow: unset;
}
.page-item.active .page-link {
	background-color: #d50032;
	border-color: #d50032;
}
.dropdown-menu {
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	transform-origin: left top 0;
	box-shadow: inherit;
	background-color: #fff;
}
.dropdown-item.active, .dropdown-item:active {
    background-color: #d50032;
}
.navbar-nav .open .dropdown-menu {
	border: 0;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	background-color: #fff;
}
.dropdown-menu {
	font-size: 14px;
	margin-top: -11px;
	color: #696158;
}
.dropdown-item-header{
	padding: 0px 15px;	
	color: #696158;
}
 /*cambiado  de solo margin-bottom 1.575rem; */
 /*revisar!!!!*/
.card {
  border: 0; /*1px solid #fff;*/
  margin-bottom: 0.5rem;
	margin-top: 0.5rem;
	background:transparent;
}
.card-body {
	padding: 0rem;
}
.card-header {
	border-bottom: 1px solid #e6e6e6;
	padding: 0.5rem 0.5rem;
}
.card-footer {
	background-color: #fff;
	border-top: 1px solid #e6e6e6;
	padding: 1rem 1.5rem;
}
.card .card-header {
    background-color: #fff;
    border-bottom: 1px solid #e6e6e6;
}
.card .card-header .card-title {
    margin-bottom: 0;
}
.modal-footer.text-left {
	text-align: left;
}
.modal-footer.text-center {
	text-align: center;
}
.btn-light {
	border-color: #e6e6e6;
	color: #a6a6a6;
}
.bg-success-light {
    background-color: rgba(15, 183, 107,0.12) !important;
	color: #26af48 !important;
	font-size: 14px !important;
}
.bg-success-light i{font-size: 14px;}
.bg-danger-light {
    background-color: rgba(242, 17, 54,0.12) !important;
	color: #e63c3c !important;
	font-size: 14px !important;
}
.table-avatar a{    color: #333 !important;}
.bg-danger-light i{font-size: 14px;}

.bootstrap-datetimepicker-widget table td.active, .bootstrap-datetimepicker-widget table td.active:hover {
	background-color: #d50032;
	text-shadow: unset;
}
.bootstrap-datetimepicker-widget table td.today:before{
	border-bottom-color:#d50032;
}
.bg-info-light{
	background-color:rgba(2, 182, 179,0.12) !important;
	color:#1db9aa !important;
}
.bg-primary-light{
	background-color:rgba(17, 148, 247,0.12) !important;
	color:#2196f3 !important;
}
.bg-danger-light{
	background-color:rgba(242, 17, 54,0.12) !important;
	color:#e63c3c !important;
}
.bg-warning-light{
	background-color:rgba(255, 152, 0,0.12) !important;
	color:#f39c12 !important;
}
.bg-success-light{
	background-color:rgba(15, 183, 107,0.12) !important;
	color:#26af48 !important;
}
.bg-purple-light{
	background-color:rgba(197, 128, 255,0.12) !important;
	color:#c580ff !important;
}
.bg-default-light{
	background-color:rgba(40, 52, 71,0.12) !important;
	color:#283447 !important;
}

/*-----------------
	5. Select2
-----------------------*/

.select2-container .select2-selection--single {
	border: 1px solid #ddd;
	height: 40px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 38px;
	right: 7px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-color: #ddd transparent transparent;
	border-style: solid;
	border-width: 6px 6px 0;
	height: 0;
	left: 50%;
	margin-left: -10px;
	margin-top: -2px;
	position: absolute;
	top: 50%;
	width: 0;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: transparent transparent #ddd;
	border-width: 0 6px 6px;
}
.select2-container .select2-selection--single .select2-selection__rendered {
	padding-right: 30px;
	padding-left: 15px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #333;
	font-size: 15px;
	font-weight: normal;
	line-height: 38px;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #d50032;
}
.select2-container--default .select2-selection--multiple {
	border: 1px solid #ddd;
	min-height: 40px;
}

/*-----------------
	6. Nav Tabs
-----------------------*/

.nav-tabs {
	border-bottom: 1px solid #e6e6e6;
}
.card-header-tabs {
    border-bottom: 0;
}
.nav-tabs > li > a {
	margin-right: 0;
	color: #888;
	border-radius: 0;
}
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
	border-color: transparent;
	color: #333;
}
.nav-tabs.nav-tabs-solid > li > a {
	color: #333;
}
.nav-tabs.nav-tabs-solid > .active > a,
.nav-tabs.nav-tabs-solid > .active > a:hover,
.nav-tabs.nav-tabs-solid > .active > a:focus {
	background-color: #d50032;
	border-color: #d50032;
	color: #fff;
}
.tab-content {
	padding-top: 20px;
}	
.tab-view{	
	background: #fff;border-bottom:none;border-color:none	
}	
 .profile .nav-link.active{    background: #d50032 !important;	
    color: #ffff !important;}
.nav-tabs .nav-link {
	border-radius: 0;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    background-color: #eee;
    border-color: transparent;
	color: #333;
}

.profile .nav-link{color: #333;}

.profile .nav-link.active{    
	background: #d50032 !important;
	color: #ffff !important;
}

.nav-tabs.nav-justified > li > a {
	border-radius: 0;
	margin-bottom: 0;
}
.nav-tabs.nav-justified > li > a:hover,
.nav-tabs.nav-justified > li > a:focus {
	border-bottom-color: #ddd;
}
.nav-tabs.nav-justified.nav-tabs-solid > li > a {
	border-color: transparent;
}
.nav-tabs.nav-tabs-solid > li > a {
	color: #333;
}
.nav-tabs.nav-tabs-solid > li > a.active,
.nav-tabs.nav-tabs-solid > li > a.active:hover,
.nav-tabs.nav-tabs-solid > li > a.active:focus {
	background-color: #d50032;
	border-color: #d50032;
	color: #fff;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
	border-radius: 0.25rem;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a {
	border-radius: 0.25rem;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:hover,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:focus {
	border-radius: 0.25rem;
}
.nav-tabs-justified > li > a {
	border-radius: 0;
	margin-bottom: 0;
}
.nav-tabs-justified > li > a:hover,
.nav-tabs-justified > li > a:focus {
	border-bottom-color: #ddd;
}
.nav-tabs-justified.nav-tabs-solid > li > a {
	border-color: transparent;
}
.nav-tabs.nav-justified.nav-tabs-top {
	border-bottom: 1px solid #ddd;
}
.nav-tabs.nav-justified.nav-tabs-top > li > a,
.nav-tabs.nav-justified.nav-tabs-top > li > a:hover,
.nav-tabs.nav-justified.nav-tabs-top > li > a:focus {
	border-width: 2px 0 0 0;
}
.nav-tabs.nav-tabs-top > li {
	margin-bottom: 0;
}
.nav-tabs.nav-tabs-top > li > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
	border-width: 2px 0 0 0;
}
.nav-tabs.nav-tabs-top > li.open > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
	border-top-color: #ddd;
}
.nav-tabs.nav-tabs-top > li+li > a {
	margin-left: 1px;
}
.nav-tabs.nav-tabs-top > li > a.active,
.nav-tabs.nav-tabs-top > li > a.active:hover,
.nav-tabs.nav-tabs-top > li > a.active:focus {
	border-top-color: #d50032;
}
.nav-tabs.nav-tabs-bottom > li {
	margin-bottom: -1px;
}
.nav-tabs.nav-tabs-bottom > li > a.active, 
.nav-tabs.nav-tabs-bottom > li > a.active:hover, 
.nav-tabs.nav-tabs-bottom > li > a.active:focus {
	border-bottom-width: 2px;
	border-color: transparent;
	border-bottom-color: #d50032;
	background-color: transparent;
	transition: none 0s ease 0s; 
	-moz-transition: none 0s ease 0s; 
	-o-transition: none 0s ease 0s; 
	-ms-transition: none 0s ease 0s; 
	-webkit-transition: none 0s ease 0s;
}
.nav-tabs.nav-tabs-solid {
	background-color: #fafafa;
	border: 0;
}
.nav-tabs.nav-tabs-solid > li {
	margin-bottom: 0;
}
.nav-tabs.nav-tabs-solid > li > a {
	border-color: transparent;
}
.nav-tabs.nav-tabs-solid > li > a:hover,
.nav-tabs.nav-tabs-solid > li > a:focus {
	background-color: #f5f5f5;
}
.nav-tabs.nav-tabs-solid > .open:not(.active) > a {
	background-color: #f5f5f5;
	border-color: transparent;
}
.nav-tabs-justified.nav-tabs-top {
	border-bottom: 1px solid #ddd;
}
.nav-tabs-justified.nav-tabs-top > li > a,
.nav-tabs-justified.nav-tabs-top > li > a:hover,
.nav-tabs-justified.nav-tabs-top > li > a:focus {
	border-width: 2px 0 0 0;
}

/*-----------------
	7. Components
-----------------------*/

.section-header {
    margin-bottom: 1.875rem;
}
.section-header .section-title {
    color: #333;
}
.line {
    background-color: #d50032;
    height: 2px;
    margin: 0;
    width: 60px;
}
.comp-buttons .btn {
	margin-bottom: 5px;
}
.pagination-box .pagination {
	margin-top: 0;
}
.comp-dropdowns .btn-group {
	margin-bottom: 5px;
}
.progress-example .progress {
	margin-bottom: 1.5rem;
}
.progress-xs {
	height: 4px;
}
.progress-sm {
	height: 15px;
}
.progress.progress-sm {
	height: 6px;
}
.progress.progress-md {
	height: 8px;
}
.progress.progress-lg {
	height: 18px;
}
.row.row-sm {
    margin-left: -3px;
    margin-right: -3px;
}
.row.row-sm > div {
    padding-left: 3px;
    padding-right: 3px;
}
.table-responsive {
	white-space: nowrap;
}

/* Avatar */

.card-label > label {
	font-size: 12px;
}
	
.table-striped tbody tr:nth-of-type(odd){background-color:#fff}
.avatar {
	position: relative;
	display: inline-block;
	width: 3rem;
	height: 3rem
}
	
.avatar > img {	
	border-radius: 50%;	
	width: 100%;	
	height: 100%;	
	-o-object-fit: contain;	
    object-fit: contain;	
}	
.sorting_1 p {margin-bottom: 0px;}
.avatar-title {
	width: 100%;
	height: 100%;
	background-color: #d50032;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
}
.avatar-away:before,
.avatar-offline:before,
.avatar-online:before {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 25%;
	height: 25%;
	border-radius: 50%;
	content: '';
	border: 2px solid #fff;
}
.avatar-online:before {
	background-color: #7bb13c;
}
.avatar-offline:before {
	background-color: #e84646;
}
.avatar-away:before {
	background-color: #ffd682;
}
.avatar .border {
	border-width: 3px !important;
}
.avatar .rounded {
	border-radius: 6px !important;
}
.avatar .avatar-title {
	font-size: 18px;
}
.avatar-xs {
	width: 1.65rem;
	height: 1.65rem;
}
.avatar-xs .border {
	border-width: 2px !important;
}
.avatar-xs .rounded {
	border-radius: 4px !important;
}
.avatar-xs .avatar-title {
	font-size: 12px;
}
.avatar-xs.avatar-away:before,
.avatar-xs.avatar-offline:before,
.avatar-xs.avatar-online:before {
	border-width: 1px;
}
.avatar-sm {
	width: 2.5rem;
	height: 2.5rem;
}
.avatar-sm .border {
	border-width: 3px !important;
}
.avatar-sm .rounded {
	border-radius: 4px !important;
}
.avatar-sm .avatar-title {
	font-size: 15px;
}
.avatar-sm.avatar-away:before,
.avatar-sm.avatar-offline:before,
.avatar-sm.avatar-online:before {
	border-width: 2px;
}
.avatar-lg {
	width: 3.75rem;
	height: 3.75rem;
}
.avatar-lg .border {
	border-width: 3px !important;
}
.avatar-lg .rounded {
	border-radius: 8px !important;
}
.avatar-lg .avatar-title {
	font-size: 24px;
}
.avatar-lg.avatar-away:before,
.avatar-lg.avatar-offline:before,
.avatar-lg.avatar-online:before {
	border-width: 3px;
}
.avatar-xl {
	width: 5rem;
	height: 5rem;
}
.avatar-xl .border {
	border-width: 4px !important;
}
.avatar-xl .rounded {
	border-radius: 8px !important;
}
.avatar-xl .avatar-title {
	font-size: 28px;
}
.avatar-xl.avatar-away:before,
.avatar-xl.avatar-offline:before,
.avatar-xl.avatar-online:before {
	border-width: 4px;
}
.avatar-xxl {
	width: 5.125rem;
	height: 5.125rem;
}
.avatar-xxl .border {
	border-width: 6px !important;
}
.avatar-xxl .rounded {
	border-radius: 8px !important;
}
.avatar-xxl .avatar-title {
	font-size: 30px;
}
.avatar-xxl.avatar-away:before,
.avatar-xxl.avatar-offline:before,
.avatar-xxl.avatar-online:before {
	border-width: 4px;
}
.avatar-group {
	display: inline-flex;
}
.avatar-group .avatar + .avatar {
	margin-left: -.75rem;
}
.avatar-group .avatar-xs + .avatar-xs {
	margin-left: -.40625rem;
}
.avatar-group .avatar-sm+.avatar-sm {
	margin-left: -.625rem;
}
.avatar-group .avatar-lg + .avatar-lg {
	margin-left: -1rem;
}
.avatar-group .avatar-xl + .avatar-xl {
	margin-left: -1.28125rem;
}
.avatar-group .avatar:hover {
	z-index: 1;
}

/*-----------------
	8. Header
-----------------------*/

.header {
	background: #fff;
	border-bottom: 1px solid #f0f0f0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1001;
	height: 60px;
}
.header-center{
	text-align: center;
}
.header .header-left {
	float: left;
	height: 60px;
	padding: 0 20px;
	position: relative;
	text-align: center;
	width: 250px;
	z-index: 1;
	transition: all 0.2s ease-in-out;
	background-color: #fff;
	border-bottom: 1px solid #f0f0f0;
}
.header .header-left .logo {
	display: inline-block;
	line-height: 60px;
}
.header .header-left .logo img {
    max-height: 40px;
    width: auto;
}
.header-left .logo.logo-small {
    display: none;
}
.header .dropdown-menu > li > a {
	position: relative;
}
.header .dropdown-toggle:after {
	display: none;
}
.header .has-arrow .dropdown-toggle:after {
	border-top: 0;
	border-left: 0;
	border-bottom: 2px solid #333;
	border-right: 2px solid #333;
	content: '';
	height: 8px;
	display: inline-block;
	pointer-events: none;
	-webkit-transform-origin: 66% 66%;
	-ms-transform-origin: 66% 66%;
	transform-origin: 66% 66%;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	-webkit-transition: all 0.15s ease-in-out;
	transition: all 0.15s ease-in-out;
	width: 8px;
	vertical-align: 2px;
}
.header .has-arrow .dropdown-toggle[aria-expanded="true"]:after {
	-webkit-transform: rotate(-135deg);
	-ms-transform: rotate(-135deg);
	transform: rotate(-135deg);
}
.user-menu {
    float: right;
    margin: 0;
    position: relative;
    z-index: 99;
}
.user-menu.nav > li > a {
	color: #333;
	font-size: 14px;
	line-height: 58px;
	padding: 0 15px;
	height: 60px;
}
.user-menu.nav > li > a:hover,
.user-menu.nav > li > a:focus {
	background-color: rgba(0, 0, 0, 0.2);
}
.user-menu.nav > li > a:hover i,
.user-menu.nav > li > a:focus i {
	color: #333;
}
	
.user-dropdown button, .user-dropdown button:active, .user-dropdown button:hover{
	background-color: inherit !important;border:none !important;
	color:#696158 !important;
	}
.user-img {
	display: inline-block;
	margin-right: 3px;
	position: relative;
	margin-top: 10px;
	
}

.user-dropdown-link{
	color:#696158 !important;

}

.user-dropdown-link span{
	font-size: 14px;
	margin-left: -11px;
}

.no-padding{padding:0px !important}
.user-menu.nav > li > a.mobile_btn {
	border: 0;
	position: relative;
	padding: 0;
	margin: 0;
	cursor: pointer
}

.user-header{width:100%}
.user-menu .dropdown-menu {
    min-width: 200px;
    padding: 0;
}
.notify .dropdown-toggle {margin-top: 1px;}
.user-menu .dropdown-menu .dropdown-item {	
    padding: 7px 15px;	
}
.user-menu .dropdown-menu .dropdown-item {
    display: flex;
    align-items: center;
    border-top: 1px solid #e3e3e3;
	padding: 10px 15px;
	color: #333 !important;
}
.user-menu .dropdown-menu .dropdown-item:hover {
	color: #d50032 !important;
    background: #f5f5f5
}
.btn-primary.active:not(:disabled):not(.disabled), .btn-primary:active:not(:disabled):not(.disabled), .show > .btn-primary.dropdown-toggle {
    background-color: rgba(0, 0, 0, 0.2)!important;
    border-color: rgba(0, 0, 0, 0.2) !important;
    color: #fff;
}
.submenu li a:hover{background:#fff !important; color:#1db8ff !important; opacity:1; }

.notification-time{    color: #666;}
.header .dropdown-menu > li > a:focus,
.header .dropdown-menu > li > a:hover {
	background-color: #d50032;
	color: #fff;
}

.header .dropdown-menu > li > a:focus i,
.header .dropdown-menu > li > a:hover i {
	color: #fff;
}
.header .dropdown-menu > li > a {
	padding: 10px 18px;
}
.header .dropdown-menu > li > a i {
	color: #d50032;
	margin-right: 10px;
	text-align: center;
	width: 18px;
}
.header .user-menu .dropdown-menu > li > a i {
	color: #d50032;
	font-size: 16px;
	margin-right: 10px;
	min-width: 18px;
	text-align: center;
}
.header .user-menu .dropdown-menu > li > a:focus i,
.header .user-menu .dropdown-menu > li > a:hover i {
	color: #fff;
}
.mobile_btn {
	display: none;
	float: left;
}
.slide-nav .sidebar {
	margin-left: 0;
}
.app-dropdown .dropdown-menu {
    padding: 0;
    width: 300px;
}
.app-dropdown-menu .app-list {
    padding: 15px;
}
.app-dropdown-menu .app-item {
    border: 1px solid transparent;
    border-radius: 3px;
    color: #737373;
    display: block;
    padding: 10px 0;
    text-align: center;
}
.app-dropdown-menu .app-item i {
    font-size: 20px;
    height: 24px;
}
.app-dropdown-menu .app-item span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.app-dropdown-menu .app-item:hover,
.app-dropdown-menu .app-item:focus,
.app-dropdown-menu .app-item:active, 
.app-dropdown-menu .app-item.active {
	background-color: #f9f9f9;
	border-color: #e3e3e3;
}
.app-list > div + div {
	margin-top: 5px;
}
.app-list > .row {
	margin-left: -5px;
	margin-right: -5px;
}
.app-list > .row > .col {
	padding-left: 5px;
	padding-right: 5px;
}
.user-header {
    background-color: #f9f9f9;
    display: flex;
    padding: 10px 15px;
}
.user-header .user-text {
	margin-left: 10px;
}
.user-header .user-text h6 {
    margin-bottom: 2px;
}
.menu-title {
	font-size: 15px;
  padding: 15px 10px 10px 16px;
  color: #1db8ff;
  display: flex; /*display: block;*/
  
}
.sidebar-overlay {
    background-color: rgba(0, 0, 0, 0.6);
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 60px;
    width: 100%;
    z-index: 1000;
}

/* Search */

.top-nav-search {
    float: left;
    margin-left: 15px;
}
.top-nav-search form {
    margin-top: 10px;
	position: relative;
    width: 230px;
}
.top-nav-search .form-control {
  
    border-color: rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    color: #333;
    height: 40px;
    padding: 10px 50px 10px 15px;
}
.top-nav-search .btn {
    background-color: transparent;
    border-color: transparent;
    color: #333;
    min-height: 40px;
    padding: 7px 15px;
    position: absolute;
    right: 0;
    top: 0;
}
.top-nav-search .form-control::-webkit-input-placeholder {
	color: #333;
}
.top-nav-search .form-control::-moz-placeholder {
	color: #333;
}
.top-nav-search .form-control:-ms-input-placeholder {
	color: #333;
}
.top-nav-search .form-control::-ms-input-placeholder {
	color: #333;
}
.top-nav-search .form-control::placeholder {
	color: #333;
}
.top-nav-search.active form {
    display: block;
    left: 0;
    position: absolute;
}

/*-----------------
	9. Sidebar
-----------------------*/

.sidebar {

   /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);*/
    bottom: 0;
    left: 0;
    margin-top: 0;
    position: fixed;
    top: 60px;
    transition: all 0.2s ease-in-out 0s;
    width: 240px;
    z-index: 1001;
}
.sidebar.opened {
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	transition: all 0.4s ease;
}
.sidebar-inner {
	height: 100%;
	min-height: 100%;
	transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu {
    padding: 15px;
}
.sidebar-menu ul {
	font-size: 15px;
	list-style-type: none;
	margin: 0;
	padding: 0;
	position: relative;
}
.sidebar-menu li a {
	color: #fff;
	display: block;
	font-size: 16px;
	height: auto;
	padding: 0 20px;
}
.sidebar-menu li a:hover {
    color: #5ae8ff;
}
.sidebar-menu > ul > li > a:hover {
	background-color: #19c1dc;
    color: #fff;
}
.sidebar-menu > ul > li.active > a:hover {
    background-color: #19c1dc;
    color: #fff;
}
.sidebar-menu li.active a {
    background-color: #d50032;
    color: #fff;
}/*
.menu-title {
    color: #1db8ff;
    display: flex;
    font-size: 14px;
    opacity: 1;
    padding: 5px 15px;
    white-space: nowrap;
}*/
.menu-title > i {
	float: right;
	line-height: 40px;
}
.sidebar-menu li.menu-title a {
    color: #ff9b44;
    display: inline-block;
    margin-left: auto;
    padding: 0;
}
.sidebar-menu li.menu-title a.btn {
    color: #fff;
    display: block;
    float: none;
    font-size: 15px;
    margin-bottom: 15px;
    padding: 10px 15px;
}
.sidebar-menu ul ul a.active {
	color: #20e3ff;
	text-decoration: underline;
}
.mobile_btn {
	display: none;
	float: left;
}
.sidebar .sidebar-menu > ul > li > a span {
	transition: all 0.2s ease-in-out 0s;
	display: inline-block;
	margin-left: 10px;
	white-space: nowrap;
}
.sidebar .sidebar-menu > ul > li > a span.chat-user {
    margin-left: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}
.sidebar .sidebar-menu > ul > li > a span.badge {
	margin-left: auto;
}
.sidebar-menu ul ul a {
    display: block;
    font-size: 15px;
    padding: 7px 10px 7px 45px;
    position: relative;
}
.sidebar-menu ul ul {
	display: none;
}
.sidebar-menu ul ul ul a {
	padding-left: 65px;
}
.sidebar-menu ul ul ul ul a {
	padding-left: 85px;
}
.sidebar-menu > ul > li {
    margin-bottom: 3px;
    position: relative;
}
.sidebar-menu > ul > li:last-child {
    margin-bottom: 0;
}
.sidebar-menu .menu-arrow {
	-webkit-transition: -webkit-transform 0.15s;
	-o-transition: -o-transform 0.15s;
	transition: transform .15s;
	position: absolute;
	right: 15px;
	display: inline-block;
	font-family: 'FontAwesome';
	text-rendering: auto;
	line-height: 40px;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0);
	line-height: 18px;
	top: 11px;
}
.sidebar-menu .menu-arrow:before {
	content: "\f105";
}
.sidebar-menu li a.subdrop .menu-arrow {
	-ms-transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
}
.sidebar-menu ul ul a .menu-arrow {
	top: 10px;
}
.sidebar-menu > ul > li > a {
    align-items: center;
    border-radius: 3px;
    display: flex;
    justify-content: flex-start;
    padding: 8px 15px;
    position: relative;
    transition: all 0.2s ease-in-out 0s;
	color: #f1f1f1;
}
.sidebar-menu ul li a i {
	display: inline-block;
	font-size: 24px;
	line-height: 24px;
	text-align: left;
	vertical-align: middle;
	width: 20px;
	transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu ul li.menu-title a i {
	font-size: 16px !important;
	margin-right: 0;
	text-align: right;
	width: auto;
}
.sidebar-menu li a > .badge {
    color: #fff;
}
/*-----------------
	6. Toggle Button
-----------------------*/

.check {
	display: block;
	margin: 0;
	padding: 0;
	width: 0;
	height: 0;
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
	position: absolute;
}
.checktoggle {
    background-color: #e0001a;
    border-radius: 12px;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 24px;
    margin-bottom: 0;
    position: relative;
    width: 48px;
}
.checktoggle:after {
	content: ' ';
	display: block;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translate(5px, -50%);
	width: 16px;
	height: 16px;
	background-color: #fff;
	border-radius: 50%;
	transition: left 300ms ease, transform 300ms ease;
}
.check:checked + .checktoggle {
	background-color: #55ce63;
}
.check:checked + .checktoggle:after {
	left: 100%;
	transform: translate(calc(-100% - 5px), -50%);
}
.onoffswitch {
	margin-left: auto;
    position: relative;
	width: 73px;
    -webkit-user-select:none;
	-moz-user-select:none;
	-ms-user-select: none;
}
.onoffswitch-checkbox {
    display: none;
}
.onoffswitch-label {
    display: block; 
	overflow: hidden; 
	cursor: pointer;
	border-radius: 20px;
	margin-bottom: 0;
}
.onoffswitch-inner {
	display: block;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    width: 200%;
}
.onoffswitch-inner:before, .onoffswitch-inner:after {
    box-sizing: border-box;
    color: #fff;
    display: block;
    float: left;
    font-size: 16px;
    height: 30px;
    line-height: 32px;
    padding: 0;
    width: 50%;
}
.onoffswitch-inner:before {
	background-color: #55ce63;
    color: #fff;
    content: "ON";
    padding-left: 14px;
}
.onoffswitch-inner:after {
    content: "OFF";
    padding-right: 14px;
    background-color: #ccc;
    color: #fff;
    text-align: right;
}
.onoffswitch-switch {
    background: #fff;
    border-radius: 20px;
    bottom: 0;
    display: block;
	height: 20px;
    margin: 5px;
    position: absolute;
    right: 43px;
    top: 0;
    transition: all 0.3s ease-in 0s;
    width: 20px;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px; 
}

/*-----------------
	10. Content
-----------------------*/

.main-wrapper {
	width: 100%;
	height: 100vh;
	min-height: 100vh;
}
.page-wrapper-rigth {
	padding-top: 69px;
	margin-left: 236px;
	position: relative;
	transition: all 0.4s ease;
}
.page-wrapper {
	
	padding-top: 1px;
	position: relative;/*
	background: red;
color: #fff;
min-height:480px*/
	/*transition: all 0.4s ease;*/
}
.page-wrapper > .content {
	padding: 0 1.5rem 0;
}
.page-header {
	margin-bottom: 1.875rem;
}
.page-header .breadcrumb {
    background-color: transparent;
    color: #6c757d;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0;
    padding: 0;
}
.page-header .breadcrumb a {
	color: #333;
}
.page-title {
    color: #333;
	margin-bottom: 5px;	
    text-align: start;
}
.float-right{
	float: right !important;
}
/*-----------------
	11. Login
-----------------------*/

.login-body {
    display: table;
    height: 100vh;
    min-height: 100vh;
}
.login-wrapper {
	width: 100%;
	height: 100%;
	display: table-cell;
	vertical-align: middle;
}
.login-wrapper .loginbox {
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		display: flex;
    margin: 1.875rem auto;
    max-width: 800px;
    min-height: 500px;
    width: 100%;
}
.login-wrapper .loginbox .login-left {
    align-items: center;
    background: linear-gradient(180deg,  #122852, #d50032);;
    border-radius: 6px 0 0 6px;
    flex-direction: column;
    justify-content: center;
    padding: 80px;
    width: 400px;
    display: flex;
}
.login-wrapper .loginbox .login-right {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 40px;
    width: 400px;
}
.login-wrapper .loginbox .login-right .login-right-wrap {
    max-width: 100%;
    flex: 0 0 100%;
}
.login-wrapper .loginbox .login-right h1 {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 5px;
    text-align: center;
}
.account-subtitle {
    color: #4c4c4c;
    font-size: 17px;
    margin-bottom: 1.875rem;
    text-align: center;
}
.login-wrapper .loginbox .login-right .forgotpass a {
	color: #a0a0a0;
}
.login-wrapper .loginbox .login-right .forgotpass a:hover {
	color: #333;
	text-decoration: underline;
}
.login-wrapper .loginbox .login-right .dont-have {
	color: #a0a0a0;
	margin-top: 1.875rem;
}
.login-wrapper .loginbox .login-right .dont-have a {
	color: #333;
}
.login-wrapper .loginbox .login-right .dont-have a:hover {
	text-decoration: underline;
}
.social-login {
	text-align: center;
}
.social-login > span {
	color: #a0a0a0;
	margin-right: 8px;
}
.social-login > a {
	background-color: #ccc;
	border-radius: 4px;
	color: #fff;
	display: inline-block;
	font-size: 28px;
	height: 38px;
	line-height: 37px;
	margin-right: 14px;
	text-align: center;
	width: 117px;
}
.social-login > a:last-child {
	margin-right: 0;
}
/*
.kep-login-facebook.metro {
	background-color: #FFF !important;
	border:1px solid #bfc0c6 !important;
	color: #4c69ba !important;
	box-shadow: none !important;
	border-radius: 5px !important;	
	height: 47px !important;
	width: 117px !important
}*/

.social-login > span > button.facebook {
	background-color: #FFF !important;
	border:1px solid #bfc0c6 !important;
	color: #797979 !important;
	box-shadow: none !important;
	border-radius: 5px !important;	
	height: 47px !important;
	width: 117px !important;
	font-size: 14px;
	font-weight: 500;
}
.social-login > span > button.facebook > i {
	margin-right : 8px !important;	
	color: #4c69ba !important;
	font-size: 20px !important;
}


.social-login > button.google {
	background-color: #FFF !important;
	border:1px solid #bfc0c6 !important;
	color: #797979 !important;
	box-shadow: none !important;
	border-radius: 5px !important;	
	height: 47px !important;
	width: 117px !important;
	font-size: 14px !important;
	font-weight: 500;
}
.social-login > button.google > div {
	margin-right : -2px !important;	
}

.login-or {
	color: #a0a0a0;
	margin-bottom: 20px;
	margin-top: 20px;
	padding-bottom: 10px;
	padding-top: 10px;
	position: relative;
}
.or-line {
	background-color: #e5e5e5;
	height: 1px;
	margin-bottom: 0;
	margin-top: 0;
	display: block;
}
.span-or {
	background-color: #fff;
	display: block;
	left: 50%;
	margin-left: -20px;
	position: absolute;
	text-align: center;
	text-transform: uppercase;
	top: 0;
	width: 42px;
}

/*-----------------
	12. Lock Screen
-----------------------*/

.lock-user {
    margin-bottom: 20px;
    text-align: center;
}
.lock-user img {
    margin-bottom: 15px;
    width: 100px;
}

/*-----------------
	13. Notifications
-----------------------*/
.notify button, .notify button:hover,  .notify button:active{    background: inherit !important;
    color: #000 !important;
    font-size: 24px !important;
    border: none !important;
}
.notifications {
	padding: 0;
}
.notifications .notification-time {
	font-size: 12px;
	line-height: 1.35;
	color: #bdbdbd;
}
.notifications .media {
	margin-top: 0;
	border-bottom: 1px solid #f5f5f5;
}
.notifications .media:last-child {
	border-bottom: none;
}
.notifications-list .media a {
	display: block;
	padding: 10px 15px;
	border-radius: 2px;
}
.notifications-list .media a:hover {
	background-color: #fafafa;
}
.notifications-list .media > .avatar {
	margin-right: 10px;
}
.notifications-list .media-list .media-left {
	padding-right: 8px;
}
.topnav-dropdown-header {
	border-bottom: 1px solid #eee;
	text-align: center;
}
.topnav-dropdown-header, 
.topnav-dropdown-footer {
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    padding-left: 15px;
    padding-right: 15px;
}
.topnav-dropdown-footer {
	border-top: 1px solid #eee;
}
.topnav-dropdown-footer a {
	display: block;
	text-align: center;
	color: #333;
}
.notify .badge {
	background-color: #d50032;
    display: block;
    font-size: 10px;
    font-weight: bold;
    min-height: 15px;
    min-width: 15px;
    position: absolute;
    right: 3px;
    color: #fff;
    top: 6px;
}
.user-menu.nav > li > a > i {
	font-size: 1.5rem;
	line-height: 60px;
}
.noti-details {
	color: #989c9e;
    margin-bottom: 0;
}
.noti-title {
	color: #333;
}
.notifications .noti-content {
	height: 290px;
	width: 350px;
	overflow-y: auto;
	position: relative;
}
.notification-list {
	list-style: none;
	padding: 0;
	margin: 0;
}
.notifications ul.notification-list > li{
	margin-top: 0;
	border-bottom: 1px solid #f5f5f5;
}
.notifications ul.notification-list > li:last-child {
	border-bottom: none;
}
.notifications ul.notification-list > li a {
	display: block;
	padding: 10px 15px;
	border-radius: 2px;
}
.notifications ul.notification-list > li a:hover {
	background-color: #fafafa;
}
.notifications ul.notification-list > li .list-item {
    border: 0;
    padding: 0;
    position: relative;
}
.topnav-dropdown-header .notification-title {
    color: #333;
    display: block;
    float: left;
    font-size: 14px;
}
.topnav-dropdown-header .clear-noti {
    color: #f83f37;
    float: right;
    font-size: 12px;
    text-transform: uppercase;
}
.noti-time {
	margin: 0;
	text-align: left;
}

/*-----------------
	14. Dashboard
-----------------------*/

.dash-widget-icon {
    align-items: center;
    display: inline-flex;
    font-size: 1.875rem;
    height: 50px;
    justify-content: center;
    line-height: 48px;
    text-align: center;
    width: 50px;
	border: 3px solid;
    border-radius: 50px;
    padding: 28px;
}
.dash-count {
    font-size: 18px;
    margin-left: auto;
}
.dash-widget-info h3 {
    margin-bottom: 10px;
}
.dash-widget-header {
    align-items: center;
    display: flex;
    margin-bottom: 15px;
}
.card-chart .card-body {
	padding: 8px;
}
#morrisArea > svg, #morrisLine > svg  {
	width: 100%;
}
.activity-feed {
    list-style: none;
    margin-bottom: 0;
    margin-left: 5px;
    padding: 0;
}
.activity-feed .feed-item {
    border-left: 2px solid #e4e8eb;
    padding-bottom: 19px;
    padding-left: 20px;
    position: relative;
}
.activity-feed .feed-item:last-child {
	border-color: transparent;
	padding-bottom: 0;
}
.activity-feed .feed-item:after {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: -7px;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background-color: #177dff;
}
.activity-feed .feed-item .feed-date {
	display: block;
	position: relative;
	color: #777;
	text-transform: uppercase;
	font-size: 13px;
 }
.activity-feed .feed-item .feed-text {
	color: #777;
	position: relative;
}
.activity-feed .feed-item .feed-text a {
    color: #333;
    font-weight: 600;
}

/*-----------------
	15. Invoice
-----------------------*/

.add-btn {
    border: 1px solid transparent;
    border-radius: 20px;
    color: #666;
    display: inline-block;
    padding: .375rem .75rem;
}
.add-btn:hover,
.add-btn:active,
.add-btn:focus {
	background-color: #fff;
	border-color: #ddd;
	color: #666;
}
.add-btn span {
    align-items: center;
    background-color: #ffc107;
    border-radius: 50%;
    color: #fff;
    display: inline-flex;
    font-size: 14px;
    height: 22px;
    justify-content: center;
    margin-right: 3px;
    width: 22px;
}
.invoice-details h4 {
	color: #666;
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 1.875rem;
	text-transform: uppercase;
}
.inv-badge {
	color: #fff;
	display: inline-flex;
	font-size: 11px;
	justify-content: center;
	min-width: 80px;
}
.inv-logo {
    max-height: 80px;
}
.invoice-container {
    background-color: #fff;
	border: 1px solid #f0f0f0;
    margin: 0 auto 1.875rem;
    max-width: 900px;
	padding: 1.5rem;
	text-align: left;
}
.invoice-details,
.invoice-payment-details > li span {
	float: left;
	text-align: left;
}
.inv-logo {
	height: auto;
	max-height: 100px;
	width: auto;
}

/*-----------------
	16. Calendar
-----------------------*/

.calendar-events {
    border: 1px solid transparent;
    cursor: move;
    padding: 10px 15px;
}
.calendar-events:hover {
	border-color: #e9e9e9;
    background-color: #fff;
}
.calendar-events i {
    margin-right: 8px;
}
.calendar {
	float: left;
	margin-bottom: 0;
}
.fc-toolbar.fc-header-toolbar {
    margin-bottom: 1.5rem;
}
.none-border .modal-footer {
	border-top: none;
}
.fc-toolbar h2 {
	font-size: 18px;
	font-weight: 600;
	font-family: 'Roboto', sans-serif;
	line-height: 30px;
	text-transform: uppercase;
}
.fc-day-grid-event .fc-time {
	font-family: 'Roboto', sans-serif;
}
.fc-day {
	background: #fff;
}
.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
	z-index: 0;
}
.fc th.fc-widget-header {
	background: #eeeeee;
	font-size: 14px;
	line-height: 20px;
	padding: 10px 0;
	text-transform: uppercase;
}
.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
	border-color: #f3f3f3;
}
.fc-basic-view .fc-day-number, 
.fc-basic-view .fc-week-number {
    padding: 2px 5px;
}
.fc-button {
	background: #f1f1f1;
	border: none;
	color: #797979;
	text-transform: capitalize;
	box-shadow: none !important;
	border-radius: 3px !important;
	margin: 0 3px !important;
	padding: 6px 12px !important;
	height: auto !important;
}
.fc-text-arrow {
	font-family: inherit;
	font-size: 16px;
}
.fc-state-hover {
	background: #f3f3f3;
}
.fc-state-highlight {
	background: #f0f0f0;
}
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
	background-color: #d50032 !important;
	color: #fff !important;
	text-shadow: none !important;
}
.fc-cell-overlay {
	background: #f0f0f0;
}
.fc-unthemed .fc-today {
	background: #fff;
}
.fc-event {
	border-radius: 2px;
	border: none;
	color: #fff !important;
	cursor: move;
	font-size: 13px;
	margin: 1px 7px;
	padding: 5px 5px;
	text-align: center;
}
.fc-basic-view td.fc-week-number span {
	padding-right: 8px;
	font-weight: 700;
	font-family: 'Roboto', sans-serif;
}
.fc-basic-view td.fc-day-number {
	padding-right: 8px;
	font-weight: 700;
	font-family: 'Roboto', sans-serif;
}
.event-form .input-group .form-control {
	height: 40px;
}
.submit-section {
	text-align: center;
	margin-top: 40px;
}
.submit-btn {
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
    min-width: 200px;
    padding: 10px 20px;
}

/*-----------------
	17. Inbox
-----------------------*/

.dropdown-action {
	margin-bottom: 5px;
}
.dropdown-action .dropdown-toggle:after {
	display: none;
}
.table-inbox input[type="radio"],
.table-inbox input[type="checkbox"] {
	cursor: pointer;
}
.mail-list {
	list-style: none;
	padding: 0;
}
.mail-list > li > a {
	color: #333;
	display: block;
	padding: 10px;
}
.mail-list > li.active > a {
	color: #d50032;
	font-weight: bold;
}
.unread .name,
.unread .subject,
.unread .mail-date {
	color: #000;
	font-weight: 600;
}
.table-inbox .fa-star {
	color: #ffd200;
}
.table-inbox .starred.fa-star {
	color: #ffd200;
}
.table.table-inbox > tbody > tr > td,
.table.table-inbox > tbody > tr > th,
.table.table-inbox > tfoot > tr > td,
.table.table-inbox > tfoot > tr > th,
.table.table-inbox > thead > tr > td,
.table.table-inbox > thead > tr > th {
    border-bottom: 1px solid #f8f8f8;
    border-top: 0;
}
.table-inbox {
	font-size: 15px;
	margin-bottom: 0;
}
.table.table-inbox thead {
	background-color: #fff;
}
.note-editor.note-frame {
	border: 1px solid #ddd;
	box-shadow: inherit;
}
.note-editor.note-frame .note-statusbar {
	background-color: #fff;
}
.note-editor.note-frame.fullscreen {
	top: 60px;
}
.note-editor.note-frame .btn-light {
    background-color: #f9f9f9;
    box-shadow: unset;
    color: #333;
}
.span-title {
	font-weight: bold;
	text-transform: uppercase;
}
.form-control.search-message {
    border-color: #ccc;
    border-radius: 4px;
    height: 38px;
    width: 180px;
}
.table-inbox tr {
	cursor: pointer;
}
table.table-inbox tbody tr.checked {
	background-color: #ffffcc;
}
.mail-label {
    font-size: 16px !important;
    margin-right: 5px;
}

/*-----------------
	18. Mail View
-----------------------*/

.attachments {
	list-style: none;
	margin: 0;
	padding: 0;
}
.attachments li {
	border: 1px solid #eee;
	float: left;
	margin-bottom: 10px;
	margin-right: 10px;
	width: 180px;
}
.attach-info {
	background-color: #f4f4f4;
	padding: 10px;
}
.attach-file {
    color: #777;
    font-size: 70px;
    padding: 10px;
    min-height: 138px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.attach-file img {
	height: auto;
	max-width: 100%;
}
.mailview-header {
	border-bottom: 1px solid #ddd;
	margin-bottom: 20px;
	padding-bottom: 15px;
}
.mailview-footer {
	border-top: 1px solid #ddd;
	margin-top: 20px;
	padding-top: 15px;
}
.mailview-footer .btn-white {
    margin-top: 10px;
    min-width: 102px;
}
.sender-img {
	float: left;
	margin-right: 10px;
	width: 40px;
}
.sender-name {
	display: block;
}
.receiver-name {
	color: #777;
}
.right-action {
	text-align: right;
}
.mail-view-title {
	font-weight: 500;
	font-size: 24px;
	margin: 0;
}
.mail-view-action {
	float: right;
}
.mail-sent-time {
	float: right;
}
.inbox-menu {
	display: inline-block;
	margin: 0 0 1.875rem;
	padding: 0;
	width: 100%;
}
.inbox-menu li {
	display: inline-block;
	width: 100%;
}
.inbox-menu li + li {
	margin-top: 2px;
}
.inbox-menu li a {
	color: #333;
	display: inline-block;
	padding: 10px 15px;
	width: 100%;
	text-transform: capitalize;
	-webkit-transition: 0.3s ease;
	-moz-transition: 0.3s ease;
	transition: 0.3s ease;
}
.inbox-menu li a i {
	font-size: 16px;
	padding-right: 10px;
	color: #878787;
}
.inbox-menu li a:hover, .inbox-menu li.active a, .inbox-menu li a:focus {
	background: rgba(33, 33, 33, 0.05);
}
.compose-btn {
	margin-bottom: 1.875rem;
}
.compose-btn a {
    font-weight: 600;
    padding: 8px 15px;
}

/*-----------------
	19. Error
-----------------------*/
.sidebar.active .menu{    width: 70px !important}	
.mini-sidebar.expand-menu .sidebar .menu{    width: 240px !important}
.error-page {
    align-items: center;
    color: #1f1f1f;
    display: flex;
}
.error-page .main-wrapper {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    justify-content: center;
    width: 100%;
	min-height: unset;
}
.error-box {
    margin: 0 auto;
    max-width: 480px;
    padding: 1.875rem 0;
    text-align: center;
    width: 100%;
}
.error-box h1 {
    color: #d50032;
    font-size: 10em;
}
.error-box p {
    margin-bottom: 1.875rem;
}
.error-box .btn {
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
    min-width: 200px;
    padding: 10px 20px;
}

/*-----------------
	20. Chat
-----------------------*/

.chat-window {
    border: 1px solid #e0e3e4;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-bottom: 1.875rem;
}
.chat-window .chat-cont-left {
    border-right: 1px solid #e0e3e4;
    flex: 0 0 35%;
    left: 0;
    max-width: 35%;
    position: relative;
    z-index: 4;
}
.chat-window .chat-cont-left .chat-header {
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #e0e3e4;
    color: #324148;
    display: flex;
    height: 72px;
    justify-content: space-between;
    padding: 0 15px;
}
.chat-window .chat-cont-left .chat-header span {
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
}
.chat-window .chat-cont-left .chat-header .chat-compose {
    color: #8a8a8a;
    display: inline-flex;
}
.chat-window .chat-cont-left .chat-search {
    background-color: #f5f5f6;
    border-bottom: 1px solid #e5e5e5;
    padding: 10px 15px;
    width: 100%;
}
.chat-window .chat-cont-left .chat-search .input-group {
    width: 100%;
}
.chat-window .chat-cont-left .chat-search .input-group .form-control {
    background-color: #fff;
    border-radius: 50px;
    padding-left: 36px;
}
.chat-window .chat-cont-left .chat-search .input-group .form-control:focus {
    border-color: #ccc;
    box-shadow: none;
}
.chat-window .chat-cont-left .chat-search .input-group .input-group-prepend {
    align-items: center;
    bottom: 0;
    color: #666;
    display: flex;
    left: 15px;
    pointer-events: none;
    position: absolute;
    top: 0;
    z-index: 4;
}
.chat-window .chat-scroll {
    max-height: calc(100vh - 255px);
    overflow-y: auto;
}
.chat-window .chat-cont-left .chat-users-list {
    background-color: #fff;
}
.chat-window .chat-cont-left .chat-users-list a.media {
    border-bottom: 1px solid #e5e5e5;
    padding: 10px 15px;
    transition: all 0.2s ease 0s;
}
.chat-window .chat-cont-left .chat-users-list a.media:last-child {
	border-bottom: 0;
}
.chat-window .chat-cont-left .chat-users-list a.media .media-img-wrap {
    margin-right: 15px;
    position: relative;
}
.chat-window .chat-cont-left .chat-users-list a.media .media-img-wrap .avatar {
    height: 45px;
    width: 45px;
}
.chat-window .chat-cont-left .chat-users-list a.media .media-img-wrap .status {
	bottom: 7px;
	height: 10px;
	right: 4px;
	position: absolute;
	width: 10px;
	border: 2px solid #fff;
}
.chat-window .chat-cont-left .chat-users-list a.media .media-body {
    display: flex;
    justify-content: space-between;
}
.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name, 
.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name {
    color: #333;
    text-transform: capitalize;
}
.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
    color: #8a8a8a;
    font-size: 14px;
    line-height: 24px;
}
.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:last-child {
    text-align: right;
}
.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:last-child .last-chat-time {
    color: #8a8a8a;
    font-size: 13px;
}
.chat-window .chat-cont-left .chat-users-list a.media:hover {
	background-color: #f5f5f6;
}
.chat-window .chat-cont-left .chat-users-list a.media.read-chat .media-body > div:last-child .last-chat-time {
	color: #8a8a8a;
}
.chat-window .chat-cont-left .chat-users-list a.media.active {
	background-color: #f5f5f6;
}
.chat-window .chat-cont-right {
    flex: 0 0 65%;
    max-width: 65%;
}
.chat-window .chat-cont-right .chat-header {
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #e0e3e4;
    display: flex;
    height: 72px;
    justify-content: space-between;
    padding: 0 15px;
}
.chat-window .chat-cont-right .chat-header .back-user-list {
    display: none;
    margin-right: 5px;
    margin-left: -7px;
}
.chat-window .chat-cont-right .chat-header .media {
	align-items: center;
}
.chat-window .chat-cont-right .chat-header .media .media-img-wrap {
	position: relative;
	display: flex;
	align-items: center;
	margin-right: 15px;
}
.chat-window .chat-cont-right .chat-header .media .media-img-wrap .avatar {
	height: 50px;
	width: 50px;
}
.chat-window .chat-cont-right .chat-header .media .media-img-wrap .status {
    border: 2px solid #fff;
    bottom: 0;
    height: 10px;
    position: absolute;
    right: 3px;
    width: 10px;
}
.chat-window .chat-cont-right .chat-header .media .media-body .user-name {
    color: #333;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
}
.chat-window .chat-cont-right .chat-header .media .media-body .user-status {
    color: #666;
    font-size: 14px;
}
.chat-window .chat-cont-right .chat-header .chat-options {
    display: flex;
}
.chat-window .chat-cont-right .chat-header .chat-options > a {
    align-items: center;
    border-radius: 50%;
    color: #8a8a8a;
    display: inline-flex;
    height: 30px;
    justify-content: center;
    margin-left: 10px;
    width: 30px;
}
.chat-window .chat-cont-right .chat-body {
    background-color: #f5f5f6;
}
.chat-window .chat-cont-right .chat-body ul.list-unstyled {
    margin: 0 auto;
    padding: 15px;
    width: 100%;
}
.chat-window .chat-cont-right .chat-body .media .avatar {
    height: 30px;
    width: 30px;
}
.chat-window .chat-cont-right .chat-body .media .media-body {
	margin-left: 20px;
}
.chat-window .chat-cont-right .chat-body .media .media-body .msg-box > div {
	padding: 10px 15px;
	border-radius: .25rem;
	display: inline-block;
	position: relative;
}
.chat-window .chat-cont-right .chat-body .media .media-body .msg-box > div p {
    color: #333;
    margin-bottom: 0;
}
.chat-window .chat-cont-right .chat-body .media .media-body .msg-box + .msg-box {
	margin-top: 5px;
}
.chat-window .chat-cont-right .chat-body .media.received {
	margin-bottom: 20px;
}
.chat-window .chat-cont-right .chat-body .media:last-child {
	margin-bottom: 0;
}
.chat-window .chat-cont-right .chat-body .media.received .media-body .msg-box > div {
	background-color: #fff;
}
.chat-window .chat-cont-right .chat-body .media.sent {
    margin-bottom: 20px;
}
.chat-window .chat-cont-right .chat-body .media.sent .media-body {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 0;
}
.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box > div {
    background-color: #e3e3e3;
}
.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box > div p {
    color: #333;
}
.chat-window .chat-cont-right .chat-body .chat-date {
    font-size: 14px;
    margin: 1.875rem 0;
    overflow: hidden;
    position: relative;
    text-align: center;
    text-transform: capitalize;
}
.chat-window .chat-cont-right .chat-body .chat-date:before {
    background-color: #e0e3e4;
    content: "";
    height: 1px;
    margin-right: 28px;
    position: absolute;
    right: 50%;
    top: 50%;
    width: 100%;
}
.chat-window .chat-cont-right .chat-body .chat-date:after {
    background-color: #e0e3e4;
    content: "";
    height: 1px;
    left: 50%;
    margin-left: 28px;
    position: absolute;
    top: 50%;
    width: 100%;
}
.chat-window .chat-cont-right .chat-footer {
    background-color: #fff;
    border-top: 1px solid #e0e3e4;
    padding: 10px 15px;
    position: relative;
}
.chat-window .chat-cont-right .chat-footer .input-group {
    width: 100%;
}
.chat-window .chat-cont-right .chat-footer .input-group .form-control {
    background-color: #f5f5f6;
    border: none;
    border-radius: 50px;
}
.chat-window .chat-cont-right .chat-footer .input-group .form-control:focus {
    background-color: #f5f5f6;
    border: none;
    box-shadow: none;
}
.chat-window .chat-cont-right .chat-footer .input-group .input-group-prepend .btn, 
.chat-window .chat-cont-right .chat-footer .input-group .input-group-append .btn {
    background-color: transparent;
    border: none;
    color: #9f9f9f;
}
.chat-window .chat-cont-right .chat-footer .input-group .input-group-append .btn.msg-send-btn {
    background-color: #d50032;
    border-color: #d50032;
    border-radius: 50%;
    color: #fff;
    margin-left: 10px;
}
.msg-typing {
	width: auto;
	height: 24px;
	padding-top: 8px
}
.msg-typing span {
	height: 8px;
	width: 8px;
	float: left;
	margin: 0 1px;
	background-color: #a0a0a0;
	display: block;
	border-radius: 50%;
	opacity: .4
}
.msg-typing span:nth-of-type(1) {
	animation: 1s blink infinite .33333s
}

.msg-typing span:nth-of-type(2) {
	animation: 1s blink infinite .66666s
}

.msg-typing span:nth-of-type(3) {
	animation: 1s blink infinite .99999s
}
.chat-window .chat-cont-right .chat-body .media.received .media-body .msg-box {
	position: relative;
}
.chat-window .chat-cont-right .chat-body .media.received .media-body .msg-box:first-child:before {
    border-bottom: 6px solid transparent;
    border-right: 6px solid #fff;
    border-top: 6px solid transparent;
    content: "";
    height: 0;
    left: -6px;
    position: absolute;
    right: auto;
    top: 8px;
    width: 0;
}
.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box {
    padding-left: 50px;
    position: relative;
}
.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box:first-child:before {
	border-bottom: 6px solid transparent;
	border-left: 6px solid #e3e3e3;
	border-top: 6px solid transparent;
    content: "";
    height: 0;
    left: auto;
    position: absolute;
    right: -6px;
    top: 8px;
    width: 0;
}
.chat-msg-info {
    align-items: center;
    display: flex;
    clear: both;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 5px 0 0;
}
.chat-msg-info li {
    font-size: 13px;
    padding-right: 16px;
    position: relative;
}
.chat-msg-info li:not(:last-child):after {
	position: absolute;
	right: 8px;
	top: 50%;
	content: '';
	height: 4px;
	width: 4px;
	background: #d2dde9;
	border-radius: 50%;
	transform: translate(50%, -50%)
}
.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box .chat-msg-info li:not(:last-child)::after {
    right: auto;
    left: 8px;
    transform: translate(-50%, -50%);
    background: #aaa;
}
.chat-window .chat-cont-right .chat-body .media.received .media-body .msg-box > div .chat-time {
    color: rgba(50, 65, 72, 0.4);
}
.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-time {
    color: rgba(50, 65, 72, 0.4);
}
.chat-msg-info li a {
	color: #777;
}
.chat-msg-info li a:hover {
	color: #2c80ff
}
.chat-seen i {
	color: #00d285;
	font-size: 16px;
}
.chat-msg-attachments {
	padding: 4px 0;
	display: flex;
	width: 100%;
	margin: 0 -1px
}
.chat-msg-attachments > div {
	margin: 0 1px
}
.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-msg-info {
    flex-direction: row-reverse;
}
.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-msg-attachments {
	flex-direction: row-reverse
}
.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-msg-info li {
    padding-left: 16px;
    padding-right: 0;
    position: relative;
}
.chat-attachment img {
    max-width: 100%;
}
.chat-attachment {
	position: relative;
	max-width: 130px;
	overflow: hidden;
}
.chat-attachment {
	border-radius: .25rem;
}
.chat-attachment:before {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: #000;
	content: "";
	opacity: 0.4;
	transition: all .4s;
}
.chat-attachment:hover:before {
	opacity: 0.6;
}
.chat-attach-caption {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	color: #fff;
	padding: 7px 15px;
	font-size: 13px;
	opacity: 1;
	transition: all .4s;
}
.chat-attach-download {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0;
	transition: all .4s;
	color: #fff;
	width: 32px;
	line-height: 32px;
	background: rgba(255, 255, 255, 0.2);
	text-align: center;
}
.chat-attach-download:hover {
	color: #495463;
	background: #fff;
}
.chat-attachment:hover .chat-attach-caption {
	opacity: 0;
}
.chat-attachment:hover .chat-attach-download {
	opacity: 1;
}
.chat-attachment-list {
	display: flex;
	margin: -5px;
}
.chat-attachment-list li {
	width: 33.33%;
	padding: 5px;
}
.chat-attachment-item {
	border: 5px solid rgba(230, 239, 251, 0.5);
	height: 100%;
	min-height: 60px;
	text-align: center;
	font-size: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box > div:hover .chat-msg-actions {
	opacity: 1;
}
.chat-msg-actions {
	position: absolute;
	left: -30px;
	top: 50%;
	transform: translateY(-50%);
	opacity: 0;
	transition: all .4s;
	z-index: 2;
}
.chat-msg-actions > a {
    padding: 0 10px;
    color: #495463;
    font-size: 24px;
}
.chat-msg-actions > a:hover {
	color: #2c80ff;
}

@keyframes blink {
	50% {
		opacity: 1
	}
}
.btn-file {
    align-items: center;
    display: inline-flex;
    font-size: 20px;
    justify-content: center;
    overflow: hidden;
    padding: 0 0.75rem;
    position: relative;
    vertical-align: middle;
}
.btn-file input {
    cursor: pointer;
    filter: alpha(opacity=0);
    font-size: 23px;
    height: 100%;
    margin: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}

/*-----------------
	21. Product
-----------------------*/

.product {
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	border: 1px solid #e7e7e7;
	border-radius: 5px;
	padding: 10px;
	background-color: #fff;
	margin-bottom: 1.875rem;
}
.product-inner {
	overflow: hidden;
	position: relative;
	width: 100%;
}
.product-inner img {
	width: 100%;
	height: auto;
}
.product-inner .cart-btns {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	opacity: 0;
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.cart-btns .btn {
	width: 120px;
	display: block;
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
}
.cart-btns .btn + .btn {
	margin-top: 10px;
}
.product .pro-desc {
    margin-top: 10px;
}
.pro-desc .price {
	font-size: 18px;
	line-height: 20px;
	color: #333;
	font-weight: bold;
}
.product:hover .cart-btns {
	opacity: 1;
}
.pro-desc h5 {
	font-size: 1rem;
}
.pro-desc h5 a {
	color: #333;
}
.product-det {
	position: relative;
	display: block;
	float: left;
	width: 100%;
	min-height: 40px;
}
.product-desc {
	padding: 0 0 0 70px;
}
.product-det > img {
	top: 0;
	width: 60px;
	position: absolute;
	left: 0;
}
.product-desc span,
.product-desc a {
	width: 100%;
	margin: 0;
	padding: 0;
	display: block;
}

/*-----------------
	22. Product Details
-----------------------*/

.product-content {
	position: relative;
}
.product-content p {
	color: #333;
	margin: 0 0 20px;
}
.product-content p:last-child {
	margin-bottom: 0;
}
.proimage-thumb {
	float: left;
	list-style: none;
	padding: 0;
}
.proimage-thumb li {
	float: left;
	height: 60px;
	width: 80px;
	text-align: center;
	margin: 13px 12px 0 0;
}
.proimage-thumb li img {
	display: block;
	height: 62px;
	width: 81px;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	position: relative;
}
.rated {
	color: #fc0;
}
.product_price {
	font-size: 30px;
	font-weight: bold;
}
.review-list {
	list-style: none;
	margin: 0;
	padding: 0;
}
.review-list li {
	clear: both;
	padding-left: 80px;
}
.review-list li .review {
	margin-bottom: 1.875rem;
}
.review-list li .review-author {
	margin-left: -80px;
	position: absolute;
}
.review-list li img.avatar {
	height: 58px;
	width: 58px;
	border-radius: 58px;
}
.review-by {
	display: block;
	font-size: 14px;
	line-height: 21px;
	margin: 0 0 10px;
}
.review-list .review-block p {
	line-height: 20px;
	margin: 0;
	text-align: justify;
}
.new-review label {
	font-size: 14px;
	font-weight: 500;
}
.new-review input.form-control {
	border: 1px solid #e5e5e5;
	border-radius: 0;
	box-shadow: inherit;
	height: 40px;
}
.new-review textarea.form-control {
	border: 1px solid #e5e5e5;
	border-radius: 0;
	box-shadow: inherit;
}
.new-review .form-group {
	margin-bottom: 20px;
}
.review-submit .btn {
	background-color: #00bf6f;
	border-color: #00bf6f;
	border-radius: 0;
	font-size: 18px;
	padding: 8px 26px;
	color: #fff;
}
.review-date {
	color: #999;
}
.review-author-name {
    font-size: 18px;
    margin-bottom: 0.5rem;
    display: inline-block;
}
.product-reviews {
	margin-bottom: 1.875rem;
}
.rating {
    display: flex;
}
.rating i + i {
    margin-left: 2px;
}

/*-----------------
	23. Profile
-----------------------*/

.react-datepicker-wrapper{width:100%}	
.profile.tab-view{padding: 15px;}
.profile-header {
    background-color: #fff;
    border: 1px solid #f0f0f0;
    padding: 1.5rem;
}
.profile-menu {
    background-color: #fff;
	border: 1px solid #f0f0f0;
    padding: 0.9375rem 1.5rem;
}
.profile-menu .nav-tabs.nav-tabs-solid {
	background-color: transparent;
}
.profile-header img {
    height: auto;
    max-width: 120px;
    width: 120px;
}
.profile-tab-cont {
	padding-top: 1.875rem;
}
.about-text {
    max-width: 500px;
}
.skill-tags span {
    background-color: #f4f4f5;
    border-radius: 4px;
    color: #66676b;
    display: inline-block;
    font-size: 15px;
    line-height: 22px;
    margin: 2px 0;
    padding: 5px 15px;
}
.edit-link {
    color: #66676b;
    font-size: 16px;
    margin-top: 4px;
}
.cal-icon {
	position: relative;
	width: 100%;
}
.cal-icon:after {
	color: #979797;
	content: "\f073";
	display: block;
	font-family: "FontAwesome";
    font-size: 15px;
    margin: auto;
    position: absolute;
    right: 15px;
    top: 10px;
}
.form-title {
    width: 100%;
    max-width: 100%;
    padding: 0;
    font-size: 1.25rem;
	font-weight: 500;
    line-height: inherit;
    color: #333;
    white-space: normal;
    position: relative;
    display: block;
    margin-bottom: 20px;
}
.form-title:before {
    content: "";
    position: absolute;
    left: 0px;
    right: 0px;
    height: 1px;
    top: 50%;
    background-color: rgba(0, 0, 0, 0.1);
}
.form-title span {
    padding: 0px 0.5rem 0 0;
    background-color: #fff;
    display: inline-block;
    z-index: 0;
    position: relative;
}
.modal-title{width:100%}
/*-----------------
	24. Template Options
-----------------------*/

.skin-settings {
	z-index: 999;
	position: fixed;
	top: 50%;
	width: 190px;
	border: 0;
	box-shadow: none;
	padding: 0;
	border-bottom-left-radius: 2px;
	right: -190px;
	background: #fff;
	transition: all 0.4s ease;
}
.skin-settings.active {
	right: 0;
	transition: all 0.4s ease;
}
.skin-settings .skin-sett-icon {
    background-color: #fff;
    border-bottom-left-radius: 2px;
    border-color: #e0e0e0;
    border-style: solid;
    border-top-left-radius: 2px;
    border-width: 1px 0 1px 1px;
    color: #666;
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    height: 48px;
    left: -47px;
    line-height: 48px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 48px;
}
.skin-settings .skin-sett-body {
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;
    color: #616161;
    padding: 14px 10px;
}
.skin-sett-body h4 {
    color: #666;
    font-size: 1.125rem;
}
.skin-settings .skin-colors {
    font-size: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: left;
}
.skin-settings .skin-colors > li {
    display: inline-block;
}
.skin-settings .skin-colors li a {
    border-radius: 2px;
	cursor: pointer;
    display: block;
    height: 36px;
    margin: 0 3px;
	position: relative;
    width: 36px;
}
.skin-settings .skin-colors .skin-red {
    background-color: #c12942;
}
.skin-settings .skin-colors .skin-orange {
	background-color: #ff7000;
}
.skin-settings .skin-colors .skin-teal {
	background: linear-gradient(to right, #00a0b0 0%, #00d2e6 100%);
}
.skin-settings .skin-colors .skin-purple {
	background-color: #d50032;
}
.skin-settings .skin-colors li a.active:after {
    color: #fff;
	content: "\f00c";
    display: block;
    font-family: "FontAwesome";
    font-size: 15px;
    left: 50%;
    margin: auto;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}
	
.table-content .rdt_TableCol_Sortable{	
	font-size:18px;	
  }	
.table-content .sc-fzpans{font-size:10px}	
.table-content header{font-weight: bold;font-size: 25px;}	
.table-content .rdt_TableRow{    font-size: 16px;}	
.ant-table-content a, .ant-table-content td {color: #333; font-size: 16px;font-weight: 200;}	
.ant-table-thead > tr > th{    font-size: 16px;}	
.ant-table-thead h2{margin-bottom: 0px;}	
.ant-table-tbody > tr > td{    padding: 6px 16px;}	

/*.btn{margin: 0 2px}	*/

.ant-pagination-item-active{border-color: #c5c5c5;}
.table-avatar{color:#333}
/**/	
/* Menu Styles */
.modal-title{width: 100%;}	
a.invoice-link{   color:#d50032 !important}
.openNav .menu:hover {
	position: absolute;	
	left: -200px;	
	top: 73px;
}
	
.openNav .menu {	
	top: 73px;	
	transform: translate3d(200px, 0, 0);	
	transition: transform .45s cubic-bezier(0.77, 0, 0.175, 1);	
}
	/**/  	
.openNav .new-wrapper {	
	position: absolute;	
	transform: translate3d(200px, 0, 0);	
	width: calc(100% - 250px);	
	transition: transform .45s cubic-bezier(0.77, 0, 0.175, 1);	
}
	
.menu input[type="checkbox"] {
	display: none;	
}	
input#menu[type="checkbox"] {	
display: none;	
}	
.new-wrapper {	
  position: absolute;	
  left: 50px;	
width: calc(100% - 50px);	
transition: transform .45s cubic-bezier(0.77, 0, 0.175, 1);	
}	
#menu:checked + ul.menu-dropdown {	
	  
	  left: 0;	
  -webkit-animation: all .45s cubic-bezier(0.77, 0, 0.175, 1);	
		  animation: all .45s cubic-bezier(0.77, 0, 0.175, 1);	
}	
.sub-menu-checkbox:checked + ul.sub-menu-dropdown {	
  display: block!important;	
  -webkit-animation: grow .45s cubic-bezier(0.77, 0, 0.175, 1);	
		  animation: grow .45s cubic-bezier(0.77, 0, 0.175, 1);	
}	
.openNav .new-wrapper {	
position: absolute;	
transform: translate3d(200px, 0, 0);	
width: calc(100% - 250px);	
transition: transform .45s cubic-bezier(0.77, 0, 0.175, 1);	
}	
.downarrow {
	position: absolute;
	right: 0;
	top: 0.9rem;
	margin-right: 1.25rem;	
}	
 
.menu {	
  position: absolute;	
  display: block;	
  height:100%;	
top: 0;	
  width: 250px;	
transition: all 0.45s cubic-bezier(0.77, 0, 0.175, 1);	
background-color: #f8f8f8 !important;	
  z-index: 999;	
}	


.overflow-container {	
position: relative;	
height: auto;	
overflow-y: auto;	
background-color: #fff; 
z-index: 0;	
display:block;	
margin: 8px 8px 8px 8px;
padding-bottom: 30px;	
padding-right: 1px;	
box-shadow: 0 0 10px rgba(0,0,0,0.15);
border: 1px solid #fff;
border-radius: 20px 20px 20px 20px;	

 

}	
.mini-sidebar.expand-menu i{display:inline-block !important;}	
.mini-sidebar .downarrow i{display: none;}	
.menu a.logotype {	
position: absolute!important;	
top: 11px;	
left: 55px;	
display: block;	
font-family: 'Work Sans', sans-serif;	
text-transform: uppercase;	
font-weight: 800;	
color: #000;	
font-size: 21px;	
padding: 10px;	
}	
.menu a.logotype span {	
font-weight: 400;	
}	
.menu a.logotype:hover {	
color: #777;	
}	

/*-----------------
	25. Blog
-----------------------*/

.blog {
	border: 1px solid #f0f0f0;
	background-color: #fff;
	border-radius: 4px;
	margin-bottom: 30px;
	padding: 1.5rem;
	position: relative;
}
.blog-image {
	overflow: hidden;
}
.blog-image,
.blog-image > a,
.blog-image img {
	display: block;
	position: relative;
	width: 100%;
	height: auto;
}
.blog-image img {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
	transform: translateZ(0);
	-moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
}
.blog-image a:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.blog-image {
	margin-bottom: 30px;
}
.blog-content {
	position: relative;
}
.blog-title {
	font-size: 22px;
	margin: 0 0 10px;
}
.blog-title a {
	color: #272b41;
}
.blog-title a:hover {
	color: #20c0f3;
}
.blog-content p {
	color: #666;
	font-size: 14px;
	margin: 0 0 30px;
}
.read-more {
    display: inline-block;
    font-size: 16px;
    padding: 0;
    color: #20c0f3;
    text-decoration: underline;
    font-weight: bold;
}
.blog-info {
    color: #757575;
    font-size: 14px;
    margin-bottom: 15px;
}
.blog-info a {
	color: #757575;
}
.blog-info a:hover {
	color: #757575;
}
.blog-info i {
	color: #20c0f3;
	font-size: 14px;
	margin-right: 5px;
}
.entry-meta {
	color: #757575;
	font-size: 14px;
	list-style: none;
	margin-bottom: 15px;
	padding: 0;
}
.entry-meta li {
    display: inline-block;
    margin-right: 15px;
}
.entry-meta li:last-child {
	margin-right: 0;
}
.blog-pagination .pagination {
	margin-bottom: 30px;
}
.blog-pagination .pagination li a {
    padding: 12px 20px;
    font-weight: 600;
    color: #2E3842;
    margin-right: 10px;
    border-radius: inherit !important;
	border-color: #f0f0f0;
}
.blog-pagination .pagination li.disabled a {
	border-color: #f0f0f0;
}
.blog-pagination .pagination li.active a {
	background: #20c0f3;
	border-color: transparent;
	color: #ffffff;
}
.post-left ul {
	margin: 0;
	padding-left: 0;
	list-style: none;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
.post-left ul li {
	margin-bottom: 5px;
	margin-right: 20px;
}
.post-left ul li:last-child {
	margin-right: 0;
}
.post-author a {
	display: flex;
	align-items: center;
}
.video {
	position: relative;
	padding-bottom: 56%;
	height: 0;
	overflow: hidden;
}
.video iframe,
.video object,
.video embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: none;
}
.latest-posts {
	margin: 0;
	padding: 0;
}
.latest-posts li {
	display: table;
	width: 100%;
	margin-bottom: 20px;
}
.latest-posts li:last-child {
	padding-bottom: 0px;
	margin-bottom: 0px;
	border-bottom: none;
}
.post-thumb {
	border-radius: 4px;
	width: 80px;
	float: left;
	overflow: hidden;
}
.post-thumb a img {
	border-radius: 4px;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
	transform: translateZ(0);
	-moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
}
.post-thumb a:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.post-info {
	margin-left: 95px;
}
.post-info h4 {
	font-size: 15px;
	margin: 0 0 5px;
}
.post-info h4 a {
	color: #272b41;
}
.post-info h4 a:hover {
	color: #20c0f3;
}
.post-info p {
	color: #757575;
	font-size: 12px;
	margin: 0;
}
.post-info p i {
	color: #20c0f3;
	font-size: 14px;
	margin-right: 4px;
}
.category-widget .categories {
	list-style: none;
	margin: 0;
	padding: 0;
}
.category-widget .categories li {
    margin-bottom: 20px;
}
.category-widget .categories li:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}
.category-widget .categories li a {
    color: #2E3842;
    font-size: 15px;
    font-weight: 500;
}
.category-widget .categories li a span {
    float: right;
    color: #757575;
}
.category-widget .categories li a:hover {
	color: #20c0f3;
}
.category-widget .categories > li > a > i {
	color: #20c0f3;
	font-size: 18px;
	margin-right: 10px;
}
.tags-widget .card-body {
	padding-bottom: 1rem;
}
.tags {
	list-style: none;
	margin: 0;
	overflow: hidden;
	padding: 0;
}
.tags li {
	float: left;
}
.tag {
	background-color: #fff;
	border-radius: 3px;
	color: #272b41;
	padding: 4px 7px;
	position: relative;
	margin: 0 .5rem .5rem 0;
	font-size: 14px;
	/*border: 1px solid #ccc;*/
	display: inline-block;
}/*
.tag:hover {
	
	background-color: #20c0f3;
	border-color: #20c0f3;
	color: #fff;
	font-weight:bold
}*/
.comment-by {
	display: block;
	font-size: 14px;
	line-height: 21px;
	margin: 0 0 10px;
}
.comments-list .comment-block p {
    font-size: 14px;
    margin-bottom: 5px;
}

/*-----------------
	26. Blog Details
-----------------------*/

.blog-view .blog-title {
	font-size: 24px;
}
.blog-content p:last-child {
	margin-bottom: 0;
}
.blog-view .blog-info {
	border: 0;
	margin-bottom: 20px;
	padding: 0;
}
.social-share {
	float: left;
	list-style: none;
	margin: 0;
	padding: 0;
}
.social-share > li {
	display: inline-block;
	float: left;
	margin-left: 10px;
	text-align: center;
}
.social-share > li:first-child {
	margin-left: 0;
}
.social-share > li > a {
	border: 1px solid #dfdfdf;
	color: #666;
	display: inline-block;
	font-size: 22px;
	height: 40px;
	line-height: 40px;
	width: 40px;
}
.social-share > li > a:hover {
	background-color: #20c0f3;
	color: #fff;
	border-color: #20c0f3;
}
.blog-reply > a {
	color: #999;
	font-size: 12px;
	font-weight: 500;
}
.blog-date {
	color: #999;
	font-size: 12px;
}
.blog-comments .comments-list {
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
}
.blog-comments .comments-list li {
	clear: both;
	padding-left: 80px;
}
.blog-comments .comments-list li .comment {
	margin-bottom: 20px;
}
.blog-comments .comments-list li .comment-author {
	left: 0;
	position: absolute;
}
.blog-comments .comments-list li img.avatar {
	height: 58px;
	width: 58px;
	border-radius: 58px;
}
.blog-comments .comment-btn {
    color: #20c0f3;
    display: inline-block;
    font-weight: 500;
    font-size: 15px;
}
.blog-author-name {
    color: #272b41;
    font-size: 16px;
    font-weight: 600;
}
.new-comment label {
	font-weight: 500;
}
.comment-submit .btn {
	background-color: #20c0f3;
	border-color: #20c0f3;
	border-radius: 0;
	font-size: 18px;
	padding: 8px 26px;
	color: #fff;
}
.about-author-img {
	background-color: #fff;
	height: 100px;
	overflow: hidden;
	position: absolute;
	width: 100px;
}
.author-details {
	margin-left: 120px;
}
.about-author {
	min-height: 100px;
}
.author-details .blog-author-name {
	display: inline-block;
	margin-bottom: 10px;
}
.post-author img {
    border-radius: 100%;
    width: 28px;
    margin-right: 5px;
}
.custom-edit-service textarea.form-control{
	min-height: 200px;
}
.custom-edit-service .service-upload {
    border: 1px solid #dcdcdc;
    border-radius: .25rem;
    text-align: center;
    padding: 70px 0;
    margin-bottom: 30px;
    background-color: #fff;
    position: relative;
}
.custom-edit-service .service-upload input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}
.custom-edit-service .service-upload i{
	font-size: 50px;
    color: #858585;
}
.custom-edit-service .service-upload span {
    font-size: 15px;
    color: #858585;
    margin-top: 14px;
    display: block;
}
.custom-edit-service .upload-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
}
.custom-edit-service .upload-wrap li {
    margin: 10px;
}
.custom-edit-service .upload-wrap li:first-child{
	margin-left: 0;
}
.custom-edit-service .upload-wrap li .upload-images{
	width: 80px;
	position: relative;
}
.custom-edit-service .upload-wrap li img{
	max-width: 100%;
}
/*-----------------
	27. Blog Grid
-----------------------*/

.grid-blog .blog-title {
    font-size: 18px;
    margin: 0 0 5px;
    min-height: 43px;
}
.grid-blog .blog-content p {
	margin: 0 0 15px;
}
.grid-blog .blog-info {
	margin-bottom: 15px;
}
.grid-blog .blog-image {
	margin-bottom: 20px;
}
.blog.grid-blog {
	padding: 20px;
}
.grid-blog .entry-meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: 0;
}
.grid-blog .entry-meta li {
	margin-bottom: 10px;
}
.grid-blog .entry-meta li:last-child {
	flex: 0 0 100px;
	max-width: 100px;
}
.grid-blog .post-author {
    width: 189px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.grid-blog .post-author a:hover {
	color: #20c0f3
}

  .mini-sidebar.expand-menu i{display:inline-block !important;}
  .mini-sidebar .downarrow i{display: none;}
  .menu a.logotype {
	position: absolute!important;
	top: 11px;
	left: 55px;
	display: block;
	font-family: 'Work Sans', sans-serif;
	text-transform: uppercase;
	font-weight: 800;
	color: #000;
	font-size: 21px;
	padding: 10px;
  }
  .menu a.logotype span {
	font-weight: 400;
  }
  .menu a.logotype:hover {
	color: #777;
  }

  .primary-nav {
	  position: fixed;
	  z-index: 999;
	  height:100%;
  }
  .menu {
	  position: relative;
  }
  .menu ul {
	margin: 0;
	padding: 0;
	list-style: none;
/*	padding: 0px 1px 0px 0px;*/
  }
  .open-panel {
	border: none;
	background-color:#fff;
	padding: 0;
  }
  .hamburger {
	  background: #fff;
	  position: relative;
	  display: block;
	  text-align: center;
	  padding: 13px 0;
	  width: 50px;
	height: 73px;
	  left: 0;
	top: 0;
	  z-index: 1000;
	cursor: pointer;
  }
  .hamburger:before {
	  content:"\2630"; /* hamburger icon */
	  display: block;
	color: #000;
	line-height: 32px;
	font-size: 16px;
  }
  .openNav .hamburger:before {
	  content:"\2715"; /* close icon */
	  display: block;
	color: #000;
	line-height: 32px;
	font-size: 16px;
  }
  .hamburger:hover:before {
	color: #777;
  }
  .primary-nav .menu li {
	  position: relative;
	  /*border-bottom: 1px solid #cbd4d5;*/	  
  }
  
   .primary-nav .menu li.active {
	  position: relative;
	  /*border-bottom: 1px solid #f8f8f8;
	  border-radius: 0px 0px 5px 0px*/
  }
  
  .menu .icon {
	  position: absolute;
	  top: 6px;
	  left: 10px;
	  pointer-events: none;
	width: 24px;
	height: 24px;
	color: #fff;
  }
  .menu a{font-size: 15px;    padding: 12px 10px;
	  color: #000 ;}
	.menu a span{color:#495a5f }
  .menu,
  .menu a,
  .menu a:visited {
	text-decoration: none!important;
	  position: relative;
  }
  .submenu.show{display: block !important;}
  .submenu.hide{display: none !important;}
  .menu a {
	display: block;
	white-space: nowrap;
	font-size: 15px;
	text-align:left
  }
  /*
  .menu a:hover {
	  color: #000;
	  /*background: #fff;
	  /*opacity:1;
	  border-left: 1px solid #000 !important;
  }
  .menu a.active {
	  color: #000;
	  /*background: #fff;
	  /*opacity:1; 
	  border-left: 1px solid #000 !important;
  }*/
  .menu {
	  height:100%
  }
  
  .menu label {
	margin-bottom: 0;
	display: block;
  }
  .menu label:hover {
	cursor: pointer;
  }
  .menu:hover {
	  position: absolute;
	  left: 0;
	  top: 0;
  }
  .openNav .menu:hover {
	  position: absolute;
	  left: -200px;
	  top: 73px;
  }
  .openNav .menu {
	top: 73px;
		  transform: translate3d(200px, 0, 0);
	  transition: transform .45s cubic-bezier(0.77, 0, 0.175, 1);
  }
  .specialist-table .avatar > img{border-radius:0px}
	  /* label.hamburger {
		  display: none;
	  } */
	  .tab-view a {
		  padding: 11px 1rem;
	  }
/*-----------------
	28. Responsive
-----------------------*/

@media only screen and (min-width:780px) {
	
	
	.over-top{
		margin-top: -44px;
		z-index: -1;
	}
	.margin-resume{
		margin-top:-70px
	}
	.sticky-bar.stick {
	  -webkit-animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
	  animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
	  -webkit-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05);
	  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05);
	  position: fixed;
	  top: 0;
	  width: 100%;
	  z-index: 999;
	  left: 0;
	  -webkit-transition: all .3s ease 0s;
	  transition: all .3s ease 0s;
	  background: #fff;
	}

	.avatar-xxl {
		width: 8rem;
		height: 8rem;
	}
	.avatar-xxl .border {
		border-width: 4px !important;
	}
	.avatar-xxl .rounded {
		border-radius: 12px !important;
	}
	.avatar-xxl .avatar-title {
		font-size: 42px;
	}
	.avatar-xxl.avatar-away:before,
	.avatar-xxl.avatar-offline:before,
	.avatar-xxl.avatar-online:before {
		border-width: 4px;
	}
	
}

.btn-primary-edit-orderdata{
	font-size: 22px;
	color: #d40031;
	opacity: .5;
	margin-bottom: -18px;
	margin-top: -24px;	
}

.btn-secondary-edit-orderdata:hover{
	opacity: 1;
	color: #676159;
}

.btn-secondary-edit-orderdata{
	font-size: 22px;
	color: #676159;
	opacity: .5;
	margin-bottom: -18px;
	margin-top: -24px;
}

.btn-primary-edit-orderdata:hover{
	opacity: 1;
	color: #d40031;
}

@media only screen and (max-width: 992px) {
	
.message-top{
	  position: inherit !important;
	  width:100%;
	  display: block;
		justify-content: center;
		text-align: center;		
		font-size: 15px !important;
		line-height: 25px !important;
	}
}


@media only screen and (min-width: 992px) {
	
	
	
	.text-center-web{
		justify-content: center;
	  align-items: center;
	}
	
	.margin-resume{
		margin-top:-86px
	}

	
	.d-lg-block {
    display: block !important;
	}

	.col-lg-2_5{
		flex: 0 0 20%;
		max-width: 20%;
	}

	#toggle_btn {
		align-items: center;
		color: #333;
		display: inline-flex;
		float: left;
		font-size: 30px;
		height: 60px;
		justify-content: center;
		margin-left: 15px;
		padding: 0 15px;
	}
	.mini-sidebar .header-left .logo img {
		height: auto;
		max-height: 40px;
		width: auto;
	}
	.mini-sidebar .header .header-left .logo {
		display: none;
	}
	.mini-sidebar .header-left .logo.logo-small {
		display: block;
		width: 70px;
	}
	.mini-sidebar .header .header-left {
		padding: 0px;
		width: 65px;
	}
	.mini-sidebar .sidebar {
		width: 68px;
	}
	.mini-sidebar.expand-menu .sidebar {
		width: 240px;
	}
	.mini-sidebar .menu-title {
		visibility: hidden;
		white-space: nowrap;
	}
	.mini-sidebar.expand-menu .menu-title {
		visibility: visible;
	}
	.mini-sidebar .menu-title a {
		visibility: hidden;
	}
	.mini-sidebar.expand-menu .menu-title a {
		visibility: visible;
	}
	.modal-open.mini-sidebar .sidebar {
		z-index: 1051;
	}
	.mini-sidebar .sidebar .sidebar-menu ul > li > a span {
		display: none;
		transition: all 0.2s ease-in-out;
		opacity: 0;
	}
	.mini-sidebar.expand-menu .sidebar .sidebar-menu ul > li > a span {
		display: inline;
		opacity: 1;
	}
	.mini-sidebar.expand-menu .sidebar .sidebar-menu > ul > li > a i {
		font-size: 24px;
		width: 20px;
	}
	.mini-sidebar .page-wrapper {
		margin-left: 78px;
	}
}

@media only screen and (min-width:320px) and (max-width:1024px){
.resp-menu{display: block;}
.login-wrapper .loginbox{box-shadow:inherit;background-color: inherit;}
}
@media only screen and (max-width: 1400px) {
	.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name, 
	.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
		max-width: 180px;
	}
}

@media only screen and (min-width: 1299px) {
	
	.header-middle{	
		background-position-y: -41px !important;
		background-size: 90% !important;
	}
}

@media only screen and (min-width: 1499px) {
	
	.header-middle{	
		background-position-y: -41px !important;
		background-size: 80% !important;
	}
}


@media only screen and (max-width: 1199px) {
	
	
	.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name, 
	.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
		max-width: 150px;
	}
	.chat-window .chat-cont-left {
		flex: 0 0 40%;
		max-width: 40%;
	}
	.chat-window .chat-cont-right {
		flex: 0 0 60%;
		max-width: 60%;
	}
	.h3-responsive{
		font-size: 170%;	
	}
	.h3-responsive{
		font-size: 140%;	
	}	
	.h2-responsive{
		font-size: 160% !important;	
	}
	.h1-responsive{
		font-size: 180% !important;	
	}

}
@media only screen and (max-width: 991.98px) {
	
	.main-store-location{
		justify-content: center;
		background-position: center top;
		justify-content: center;
		background-repeat: no-repeat;
	}
	
	.header-action-2 .header-action-icon-2 > a{
		margin-right: 0px;
	}
	.header-container {
    padding-left: 10px !important;
		padding-right: 10px !important;
  }
	
	.header-action-right {
		margin-left: 24px;
		position: absolute;
		right: 0;
	}
	
	.header .header-left {
		position: absolute;
		width: 100%;
	}
	.mobile_btn {
		color: #333 !important;
		cursor: pointer;
		display: block;
		font-size: 24px;
		height: 60px;
		left: 0;
		line-height: 60px;
		padding: 0 15px;
		position: absolute;
		text-align: center;
		top: 0;
		z-index: 10;
	}
	#toggle_btn {
		display: none;
	}
	.top-nav-search {
		display: none;
	}
	.login-wrapper .loginbox .login-left {
		padding: 80px 50px;
		width: 50%;
	}
	.login-wrapper .loginbox .login-right {
		padding: 50px;
		width: 50%;
	}
	.sidebar {
		margin-left: -251px;
		width: 225px;
		-webkit-transition: all 0.4s ease;
		-moz-transition: all 0.4s ease;
		transition: all 0.4s ease;
		z-index: 1041;
	}
	.page-wrapper {
		margin-left: 0;
		padding-left: 0;
		padding-right: 0;
		-webkit-transition: all 0.4s ease;
		-moz-transition: all 0.4s ease;
		transition: all 0.4s ease;
	}
	.chat-window .chat-scroll {
		max-height: calc(100vh - 255px);
	}
	.chat-window .chat-cont-left, .chat-window .chat-cont-right {
		flex: 0 0 100%;
		max-width: 100%;
		transition: left 0.3s ease-in-out 0s, right 0.3s ease-in-out 0s;
		width: 100%;
	}
	.chat-window .chat-cont-left {
		border-right: 0;
	}
	.chat-window .chat-cont-right {
		position: absolute;
		right: calc(-100% + -1.875rem);
		top: 0;
	}
	.chat-window .chat-cont-right .chat-header {
		justify-content: start;
	}
	.chat-window .chat-cont-right .chat-header .back-user-list {
		display: block;
	}
	.chat-window .chat-cont-right .chat-header .chat-options {
		margin-left: auto;
	}
	.chat-window.chat-slide .chat-cont-left {
		left: calc(-100% + -1.875rem);
	}
	.chat-window.chat-slide .chat-cont-right {
		right: 0;
	}
	.sidebar-overlay.opened {
		display: block;
	}
	.h5-responsive{
		font-size: 110% !important;	
	}
	.h4-responsive{
		font-size: 120% !important; 
	}
	.h3-responsive{
		font-size: 140% !important;	
	}	
	.h2-responsive{
		font-size: 160% !important;	
	}
	.h1-responsive{
		font-size: 180% !important;	
	}
}


.modal-fluid {
	height: 93%;
	max-width:100% !important;
}


.fullheigth-modal .modal-content {
	height: 100%;
}

.modal-fluid {
	height: 93%
}

.zoom-modal {
	height: 100%;
	width: 100% !important;
	max-width: 100% !important;
	margin: 0 !important;
	
}

.zoom-modal .modal-content {
	height: 100%;
	width: 100%;
	border: 0px;
	border-radius: 0 !important;
}

.zoom-modal-2 {

	width: 100% !important;
	max-width: 100% !important;
	margin: 0 !important;
	
}

.zoom-modal-2 .modal-content {
	width: 100%;
	border: 0px;
	border-radius: 0 !important;
	height: 100vh;
}


.zoom-modal .modal-content .modal-header {
	border-bottom: 0px;
	padding: 0rem 0.5rem  0.5rem 0.5rem;
}

.zoom-modal .modal-content .modal-body {
	padding: 0rem 0.5rem 0rem 0.5rem;
	margin-top: -1rem;
	display: flex;
	align-items: center;
	background: #fff;
}

.zoom-modal .modal-content .modal-header button {
	font-size: 3rem;
	font-weight: normal;
	padding: 0;
	margin: 0;
	color:#696158;
	opacity:0.6;
}

.zoom-modal .modal-content .modal-header button:hover {
	opacity:0.8;
}


@media only screen and (max-width: 799.98px) {

	.profile-widget .pro-content {
		height:165px !important;
		min-height: 165px !important;
		padding: 0px !important;
		margin-top: 3px;
		padding-top: 3px;
	}
	
	.profile-widget .option {
		position: initial !important;
		left: 0px;
		width: 100%;
		cursor: pointer; 
		-webkit-justify-content: left;
		-ms-flex-pack: left;
		border-radius: 3px;
		color: rgba(105, 96, 87, 1);
		/*-webkit-transform: translate3d(100%, 0, 0);*/

	  -webkit-transform: translate3d(0, 0, 0);
	  -ms-transform: translate3d(0, 0, 0);
	  transform: translate3d(0, 0, 0);
	    
		opacity: 1 !important;
		visibility: visible !important;
		z-index: 99;
		background: #fff;
		margin-left: 0px;
		height: 40px !important;
		margin-top: 1px !important
		
	}
	
	.profile-widget a {
		font-size: 13px !important;
	}
	.profile-widget button {
		font-size: 13px !important;
	}
	
	.product-info-first-div {
	  min-height: 42px !important;
	}
	
	.pro-content p {
	  margin-bottom: 0rem !important;
	  padding-right: 0px !important;
	  padding-left: 0px !important;
	  margin-top: 0rem !important;
	  min-height: 41px !important;
	  max-height: 41px !important;
	}

	.profile-widget .product-action {
		opacity:1 !important;
	  visibility: visible !important;
	  display: flex;
	  justify-content: center;
		align-items: center;
	}

	.detail-qty-mini {
	   height: 30px;
	   border-radius: 3px 0 0 3px !important;
	}

	.mobile-col{
	  position: relative;
	  width: 100%;
	  padding-right: 0px;
	  padding-left: 0px;
	}

	.shipping-group{
		width: 100% !important;
		display: block;

		text-align: center;
	}
	
	.primary-formitem, .secondary-formitem {
    width:95% !important;
   }
	 
	
	.rigth-button {
    margin: 0px 8px 9px 0 !important;
	}
	
	.btn-primary-rigth-button, .btn-secondary-rigth-button {
    margin: 0px 8px 9px 0 !important;
	}
	
	
	.shipping-group button{
		font-size: 12px !important;
	}
	.loginModal .small-label {
		font-size: 0.85rem
	}
	.modal-fluid {
		height: 96%
	}
	
	.btn-primary-edit-orderdata, .btn-secondary-edit-orderdata {
		margin-right: 0px;
	}
	
	.pagebuilder-column-1{
		width:100%
	}
	
	body {
		font-size: 0.9375rem;
	}
	h1, .h1 {
		font-size: 2rem;
	}
	h2, .h2 {
		font-size: 1.75rem;
	}
	h3, .h3 {
		font-size: 1.5rem;
	}
	h4, .h4 {
		font-size: 1.125rem;
	}
	h5, .h5 {
		font-size: 1rem;
	}
	h6, .h6 {
		font-size: 0.875rem;
	}
	.header .has-arrow .dropdown-toggle:after {
		display: none;
	}
	.user-menu.nav > li > a > span:not(.user-img) {
		display: none;
	}
	.navbar-nav .open .dropdown-menu {
		float: left;
		position: absolute;
	}
	.navbar-nav.user-menu .open .dropdown-menu {
		left: auto;
		right: 0;
	}
	.header .header-left {
		padding: 0 15px;
	}
	.header .header-left .logo {
		display: none;
	}
	.header-left .logo.logo-small {
		display: inline-block;
	}
	.login-wrapper .loginbox .login-left {
		display: none;
	}
	.login-wrapper .loginbox {
		max-width: 450px;
		min-height: unset;
	}
	.login-wrapper .loginbox .login-right {
		float: none;
		padding: 1.875rem;
		width: 100%;
	}
	.invoice-container {
		padding: 20px;
	}
	.left-action {
		text-align: center;
		margin-bottom: 15px;
	}
	.right-action {
		text-align: center;
	}
	.top-action-left .float-left {
		float: none !important;
	}
	.top-action-left .btn-group {
		margin-bottom: 15px;
	}
	.top-action-right {
		text-align: center;
	}
	.top-action-right a.btn.btn-white {
		margin-bottom: 15px;
	}
	.mail-sent-time {
		float: left;
		margin-top: 10px;
		width: 100%;
	}
	.profile-btn {
		flex: 0 0 100%;
		margin-top: 20px;
	}
	.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name, 
	.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
		max-width: 250px;
	}
	.app-dropdown {
		display: none;
	}
	.edit-link {
		font-size: 0.875rem;
		margin-top: 0;
	}
	.product_price {
		font-size: 1.5rem;
	}
	.login-wrapper .loginbox .login-right h1 {
		font-size: 22px;
	}
	.error-box h1 {
		font-size: 6em;
	}
	.error-box .btn {
		font-size: 15px;
		min-width: 150px;
		padding: 8px 20px;
	}
	.dash-count {
		font-size: 16px;
	}
	.h5-responsive{
		font-size: 105% !important;
	}
	.h4-responsive{
		font-size: 110% !important;
	}
	.h3-responsive{
		font-size: 140% !important;
	}
	.h2-responsive{
		font-size: 150% !important;	
	}
	.h1-responsive{
		font-size: 170% !important;	
	}
}

@media only screen and (max-width: 575.98px) {
	
	
	/*
	.profile-widget .option {
		visibility: hidden !important;
		display:none !important;
	}
	*/
	.special-col{
		padding-left: 5px; 
		padding-right: 0px;
	}
	.card {
		margin-bottom: 0.25rem;
		margin-top: 0.25rem;
	}
	.page-wrapper > .content {
		padding: 0.9375rem 0.9375rem 0;
	}
	.chat-window {
		margin-bottom: 0.9375rem;
	}
	.card-body {
		padding: 1.25rem;
	}
	.card-header {
		padding: .75rem 1.25rem;
	}
	.card-footer {
		padding: .75rem 1.25rem;
	}
	.card-chart .card-body {
		padding: 5px;
	}
	.page-header {
		margin-bottom: 0.9375rem;
	}
	.account-wrapper {
		padding: 0.9375rem;
	}
	.pagination-lg .page-link {
		font-size: 1.2rem;
		padding: 0.5rem 0.625rem;
	}
	.profile-image {
		flex: 0 0 100%;
		margin-bottom: 20px;
		text-align: center;
	}
	.profile-user-info {
		text-align: center;
	}
	.profile-btn {
		text-align: center;
	}
	.invoice-details, .invoice-payment-details > li span {
		float: left;
		text-align: left;
	}
	.fc-toolbar .fc-right {
		display: inline-block;
		float: none;
		margin: 10px auto 0;
		width: 200px;
		clear: both;
	}
	.fc-toolbar .fc-left {
		float: none;
		margin: 0 auto;
		width: 200px;
	}
	.fc-toolbar .fc-center {
		display: inline-block;
		width: 100%;
		text-align: center;
	}
	.fc-toolbar .fc-center h2 {
		width: 100%;
	}
	.profile-tab-cont {
		padding-top: 1.25rem;
	}
	.chat-window .chat-cont-right .chat-header .media .media-body {
		display: none;
	}
	.h5-responsive{
		font-size: 100% !important;
	}	
	.h4-responsive{
		font-size: 110% !important;
	}
	.h3-responsive{
		font-size: 135% !important;
	}
	.h2-responsive{
		font-size: 125% !important;	
	}
	.h1-responsive{
		font-size: 115% !important;	
	}
}

@media only screen and (max-width: 479px) {
	.add-btn {
		font-size: 14px;
		padding: 6px 7px;
	}
	.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name, 
	.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
		max-width: 160px;
	}
	.page-header .breadcrumb {
		display: none;
	}
	.h5-responsive{
		font-size: 100% !important;
	}
	.h4-responsive{
		font-size: 105% !important;
	}
	.h3-responsive{
		font-size: 120% !important;
	}
	.h2-responsive{
		font-size: 125% !important;
	}
	.h1-responsive{
		font-size: 130% !important;
	}
	
	/*
	.product-info{
	  font-size: 14px;
	  font-weight: 700;	
	}
	*/
}

/****added ***/

.notification-list .dropdown-item{    padding-left: 55px;}
	.notification-list .avatar{    position: absolute;
		left: 10px;}

.notification-list .dropdown-item{    position: relative;
    padding-left: 55px !important}

.menu-hasdropdown.active.open > a{background:#d50032 !important; border-radius: 4px;}
.menu-hasdropdown:hover .submenu li{background:inherit !important}
.menu-hasdropdown.active .rotate-icon {
	
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
	-webkit-transition: all 150ms ease-in 0s;
	transition: all 150ms ease-in 0s;
	}
	
.menu-hasdropdown .rotate-icon {
	
	-webkit-transition: all 150ms ease-in 0s;
	transition: all 150ms ease-in 0s;
	}
	
.menu-hasdropdown:hover .rotate-icon {color: #000 !important;}
.rotate-icon.active {color: #000 !important;}

.menu-hasdropdown.open .sub-menu-dropdown{width:100%;display:inline-block; }

.profile-user-info{text-align: left;}
.content-left{text-align: left;}
.content-left label{text-align: left;width: 100%;}
.modal-body label{
	/*width:100%;*/
	text-align: left;}


.menu-dropdown li.active > a{
	/*background: #ecf9ff;*/
	border-left: 1px solid #000 !important;
	color:#000 !important;
	font-weight:bold;
	z-index: 1061;
}

/*.menu-dropdown li:hover > a{*/
.menu-dropdown li a:hover{
	
	/*background: #ecf9ff;*/
	/*border-left: 1px solid #000 !important;*/
	color:#000 !important;
	z-index: 1061;
	margin-left:5px
}

.menu-dropdown a i{
	margin-right:14px;
	font-size: 22px;
	
}
.menu-dropdown li span.active >a{
	background: #ecf9ff;
	color:#000 !important;
  display: inline-block;
  width: 100%;
  /*border-radius: 4px;*/
}
.menu-dropdown {
	top: 0;	
	overflow-y: auto;	
/*	padding-bottom: 79px !important;	*/
  }	
  /*
.menu-dropdown li .icon {
	  color: #fff;
	  font-size: 20px;
  }
  
.menu-dropdown li:hover .icon {
  color: #000;
}
.menu-dropdown li a.active{color:#d50032}	
.menu-dropdown li a.active {
    background: #d50032;
    color: #d50032 !important;
}
*/

.sub-menu-dropdown.submenu.active{
	display: block !important;
		
}
.sub-menu-dropdown {
	display: none;	
	background: #fff !important;
	/*border-radius: 0px 0px 5px 0px;
	border: 1px solid #1db8ff;
	border-left: 1px solid #f2f2f2;
	border-top: 1px solid #f2f2f2;
	border-left: 1px solid #f2f2f2;
	*/
}	
.sub-menu-dropdown li{margin-left: 1px !important; }	
/*
.sub-menu-dropdown.submenu  li {
	border: 1px solid #fff !important;	
}*/
.sub-menu-dropdown.submenu  li > a {
	padding: 7px 3px;
	margin-left: 12px;
}
   

.sub-menu-dropdown.submenu  li.active > a{
	color:#000 !important;
	border: 1px solid #f2f2f3 !important;	
	background: #f2f2f3 !important;	
	border-radius: 0px 5px 5px 0px !important;
	border-left: 1px solid #d50032 !important;
}
/*
.sub-menu-dropdown.submenu  li.hover > a{
	color:#000 !important;
	border: 1px solid #f2f2f3 !important;	
	background: #f2f2f3 !important;	
	border-radius: 5px 5px 5px 5px !important;	
	
	border-left: 2px solid #d50032 !important;
}
*/
 

.sub-menu-dropdown.submenu li a:hover{  
	/*color:#d50032 !important;  */
	z-index: 1061;
	margin-left:17px
}

/*-----------------
	29. Custom
-----------------------*/
 
.modal-open {
    overflow: auto;
    /*overflow: hidden;*/
}

body > .modal-backdrop  {
	overflow: hidden;
}

.steps-row-img::before{	
	background-color: transparent  !important;
}
	
.steps-row-img{	
	background-color: transparent !important;
	display: flex
}

.steps-step-center {	
	display : inline-block;
	width   : 33.333%;
}

.opcacity05{
	opacity:0.5;
}

.steps-form .steps-row::before{
	width: 68% !important;
	margin-left:16% !important;
}

.steps-form-2 .steps-row::before{
	width: 100% !important;
	top: 14px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 100%;
  height: 1px;
  background-color: #ccc;
}
.steps-form-2 .steps-row .steps-step {
  display: table-cell;
  text-align: center;
  position: relative;
}



.opcacity07{
	opacity:0.7;
}
.cbtn-circle {
  width: 26px;
  height: 26px;
  text-align: center;
  padding: 6px 0;
  font-size: 14px;
  border: 1px solid transparent;
  line-height: 1.428571429;
  border-radius: 16px;
  margin-top: 0;
  /*box-shadow: 0 2px 5px 3px rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);*/
  border: 1px solid #ccc;
  background-color: #fff;
  cursor:pointer;
}

.cbtn-circle:hover {
  width: 26px;
  height: 26px;
  text-align: center;
  padding: 6px 0;
  font-size: 14px;
  border: 1px solid transparent;
  line-height: 1.428571429;
  border-radius: 16px;
  margin-top: 0;
  /*box-shadow: 0 2px 5px 3px rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);*/
  border: 1px solid #ccc;
  background-color: #fff;
}

.cbtn-circle-active {
	
  width: 15px;
  height: 15px;
  text-align: center;
  /*padding: 6px 0;*/
  font-size: 14px;
  border: 1px solid transparent;
  line-height: 1.428571429;
  border-radius: 12px;
  margin-top: -2px;
  margin-left: 4px;
  background-color: #d50032;
 
}

.cbtn-circle-inactive {
  width: 15px;
  height: 15px;
  text-align: center;
  /*padding: 6px 0;*/
  font-size: 14px;
  border: 1px solid transparent;
  line-height: 1.428571429;
  border-radius: 12px;
  margin-top: -2px;
  margin-left: 4px;
  background-color: #eee0b9;
  
}



.loadingBG{

  display: flex;
  justify-content: center;
  align-items: center;
  z-index:1090;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.5;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  transition: opacity .15s linear;
}


.loadingBG2{

  display: flex;
  justify-content: center;
  align-items: center;
  z-index:1090;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.text-loading,
.dropdown-menu > li > a.text-loading {
	color: #d50032 !important;
	width: 4rem;
	height: 4rem;
}

.menu-dropdown li a:hover{
	
	/*background: #ecf9ff;*/
	/*border-left: 1px solid #000 !important;*/
	color:#000 !important;
	z-index: 1061;
	margin-left:5px
}

  .level0Menu{
  	display: block;
		white-space: nowrap;
		font-size: 15px;
		text-align:left;
  	padding: 12px 10px;
	  color: #000 ;
	  cursor: pointer;
	  }
	.level0Menu span{
		color:#495a5f ;
		cursor: pointer;
	}
 
  
  .level0Menu i{
	margin-right:14px;
	font-size: 22px;
	
}


.modal .modal-dialog.modal-right {
    right: 0;
}/*
.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none;
}
.modal.fade .modal-dialog {
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out,-webkit-transform .3s ease-out;
    -webkit-transform: translate(0,-50px);
    transform: translate(0,-50px);
    z-index:1051;
}*/
 .modal-full-height {
    position: absolute;
    top: 0;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 40%;
    height: auto;
    min-height: 100%;
    margin: 0;
}
/*
.modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
}
.modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none;
}
*, ::before, ::after {
    box-sizing: border-box;
}
*/
.modal-md {
		min-width: 40% !important;
    max-width: 40% !important;
}
.modal-lg, .modal-xl {
	 	min-width: 80% !important;
    max-width: 80% !important;
}

.checkbox-container {
	height: 150px;
	overflow: auto;
	border: 1px solid #7f7f7f;
	padding: 3px 0px 0px 9px;
}

.date-form-control input {
    border: 1px solid #ddd;
    box-shadow: none;
    color: #333;
    font-size: 15px;
    height: 40px;
}
.date-form-control input:focus {
	border-color: #d50032;
	box-shadow: none;
	outline: 0 none;
}
.date-form-control-sm input {
	height: calc(1.5em + .5rem + 2px);
	max-width:120px;
}

.date-form-control-lg input{
	height: calc(1.5em + 1rem + 2px);
}

/*-----------------
	30. Checkout
-----------------------*/

.info-widget {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 30px;
    margin-bottom: 30px;
}
.card-label > label {
    background-color: #fff;
    color: #959595;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    margin: 6px auto auto 8px;
    padding: 0 7px;
}
.flatpickr-mobile {
	margin-top: -5px !important;
	/*width: 46vh !important;*/
	text-align: left !important;
	justify-content: left!important;
	display: flex !important;
}
.card-label > input {
    background-color: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .05);
    display: block;
    height: 38px !important;
    margin-top: -13px;
    padding: 5px 15px 0;
    transition: border-color .3s;
    width: 100%;
}
.card-label > select {
    background-color: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .05);
    display: block;
    height: 38px;
    margin-top: -13px;
    padding: 5px 15px 0;
    transition: border-color .3s;
    width: 100%;
}

.card-label > textarea {
    background-color: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .05);
    display: block;
     
    margin-top: -9px;
    padding: 5px 15px 0;
    transition: border-color .3s;
    width: 100%;
}
.exist-customer a {
	color: #12c5c3;
	font-weight: 500;
}
.payment-widget .payment-list + .payment-list {
	margin-bottom: 15px;
}
.payment-radio {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: 600;
    color: #272b41;
    text-transform: capitalize;
}
.payment-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.payment-radio .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 19px;
    height: 19px;
    margin: 3px 0 0 0;
    border: 2px solid #ddd;
        border-top-color: rgb(221, 221, 221);
        border-right-color: rgb(221, 221, 221);
        border-bottom-color: rgb(221, 221, 221);
        border-left-color: rgb(221, 221, 221);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}
.payment-radio input:checked ~ .checkmark {
    border-color: #12c5c3;
}
.payment-radio .checkmark::after {
    position: absolute;
    left: 3px;
    top: 3px;
    content: '';
    width: 9px;
    height: 9px;
    background-color: #12c5c3;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(0.1);
    -moz-transform: scale(0.1);
    -ms-transform: scale(0.1);
    -o-transform: scale(0.1);
    transform: scale(0.1);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}
.payment-radio input:checked ~ .checkmark::after {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    visibility: visible;
}
.booking-date {
    padding: 0;
    list-style: none;
}
.booking-date li {
    position: relative;
    font-size: 15px;
    font-weight: 500;
    color: #272b41;
    text-transform: capitalize;
    margin-bottom: 15px;
}
.booking-date li span {
    float: right;
    color: #757575;
    font-weight: 400;
    font-size: 15px;
}
.booking-fee {
    padding: 0;
    list-style: none;
}
.booking-fee li {
    position: relative;
    font-size: 15px;
    font-weight: 500;
    color: #272b41;
    text-transform: capitalize;
    margin-bottom: 15px;
}
.booking-fee li span {
    float: right;
    color: #757575;
    font-weight: 400;
    font-size: 15px;
}
.booking-total {
    border-top: 1px solid #e4e4e4;
    margin-top: 20px;
    padding-top: 20px;
}
.booking-total ul {
	padding: 0;
	list-style: none;
	margin: 0;
}
.booking-total ul li span {
    font-size: 18px;
    font-weight: 600;
    color: #272b41;
}
.booking-total ul li .total-cost {
    color: #12c5c3;
    font-size: 16px;
    float: right;
}
.items-center{
	justify-content: center;
  align-items: center;
  /*text-align: center;*/
}

.items-right{
	justify-content: right;
/*  align-items: end;	*/
  /*text-align:right;*/
  
}

.items-left{
	justify-content: left;
  /*align-items: start;	*/
 /* text-align: left;*/
}

.success-cont i {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    width: 30px;
    height: 30px;
    border: 2px solid #09e5ab;
    border-radius: 50%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    font-size: 15px;
    /*margin-bottom: 8px;*/
    background-color: #09e5ab;
}

.primary-cont i {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    width: 30px;
    height: 30px;
    border: 1px solid #d50032;
    border-radius: 50%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    font-size: 14px;
    /*margin-bottom: 8px;*/
    background-color: #d50032;
}
.primary-cont i:hover {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #d50032;
    width: 30px;
    height: 30px;
    border: 1px solid #d50032;
    border-radius: 50%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    font-size: 14px;
    /*margin-bottom: 8px;*/
    background-color: #fff;
}

.fa, .fab, .fad, .fal, .far, .fas {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}

.closeModal {
		padding: 0;
		background-color: transparent;
		border: 0;
		box-shadow: 0px 0px 6px 3px rgb(44, 44, 44);
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
   
   /* text-shadow: 0 1px 0 #fff;*/
    z-index:1070;
    margin: -14px;
    border-radius: 50%;
}
.middle {
	align-content: center;
	align-items: center;
}

.items-top {
	align-content: start;
	align-items: start;
}
.center {
	
	justify-content: center;
	justify-items: center;
}

.product-image{
  cursor: pointer;
}
/*
.product-info{
  font-size: 14px !important;	;
  color: rgba(105, 96, 87, 1);
  font-weight: normal !important;	
  padding-right: 5px;
	padding-left: 6px;

	
}

.product-info p{
  margin-bottom: 0.5rem;
  padding-right: 5px;
	padding-left: 15px;
	margin-top: -0.5rem;
}*/
/*
.product-info div{
  padding-right: 5px;
	padding-left: 6px;

}
*/

.product-price{
	font-weight: 700;
	color:#d50032;
	font-size: 16px !important;
}
.product-price-new{
	font-weight: 700;
	color:#d50032;
	font-size: 16px !important;
}
.cart-icon{
	font-weight: 700;
	/*color:#d50032;*/
	font-size: 18px !important;
}

.product-price-old{
	color: rgba(84,75,69,.7);
	text-decoration: line-through
}

.discount{
	position: relative;
	justify-content: right;
	display: flex;
}

.discount{
	position: relative;
	justify-content: right;
	display: flex;
}

.discount span{
	color: yellow;
	width: 58px;
	background: black;
	font-size: 18px;
	font-weight: 700;
	padding-left: 6px;
}


.discountimage{
	margin-top: -23px;
}

 


.booking-doc-info .booking-doc-img {
    width: 80px;
    margin-right: 15px;
}
.booking-doc-info .booking-doc-img img {
    border-radius: 4px;
    height: 80px;
    width: 80px;
    object-fit: cover;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}


/******************
*****for header****
*******************/

.header-action-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  /*margin-left: 24px;*/
}

.search-style-1 {
  margin-right: 28px;
}

.search-style-1 form input::-moz-input-placeholder {
  color: #1a1a1a;
  opacity: 1;
}

.search-style-1 form input::-webkit-input-placeholder {
  color: #1a1a1a;
  opacity: 1;
}

.search-style-1 form input:focus {
  border: 1px solid #d50032;
  background-color: #ffffff;
}

.search-style-1 form button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  border: none;
  font-size: 19px;
  height: 100%;
  padding: 0 15px;
  background-color: transparent;
  color: #d50032;
}

.search-style-1 form button:hover {
  color: #3f81eb;
}

.header-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-action .header-action-icon {
  margin-right: 28px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.header-action .header-action-icon.header-action-mrg-none {
  margin-right: 13px;
}

.header-action .header-action-icon > a {
  font-size: 24px;
  color: #333;
  line-height: 1;
  display: inline-block;
  position: relative;
}

.header-action .header-action-icon > a span.pro-count.blue {
  background-color: #d50032;
}

.header-action .header-action-icon:hover > a {
  color: #d50032;
}

.header-action .header-action-icon:hover .cart-dropdown-wrap {
  opacity: 1;
  visibility: visible;
  top: calc(100% + 10px);
}

.header-action.header-action-hm3 .header-action-icon.header-action-mrg-none2 {
  margin-right: 0px;
}

.header-action.header-action-hm3 .header-action-icon:last-child {
  margin-right: 0;
  
}

.header-action-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
/*
.header-action-2 .header-action-icon-2:last-child {
  padding: 0 0px 0 0px;
}*/

.header-action-2 .header-action-icon-2 > a {
  font-size: 30px;
  color: #333;
  line-height: 1;
  display: inline-block;
  position: relative;
  width: 1em;
}

.header-action-2 .header-action-icon-2 > a img {
/*  width: 100%;
  max-width: 19px;
  opacity: 0.7;*/
}

.header-action-2 .header-action-icon-2 > a span.pro-count {
  position: absolute;
  right: -1px;
  top: 1px;
  color: #ffffff;
  height: 18px;
  width: 18px;
  border-radius: 100%;
  font-weight: 500;
  font-size: 11px;
  text-align: center;
  line-height: 18px;
}

.header-action-2 .header-action-icon-2 {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 11px 0 1px;
  text-align: center;
}

.header-action-2 .header-action-icon-2 > a svg {
  width: 1em;
  height: 1em;
}

.header-action-2 .header-action-icon-2 > a span.pro-count.blue {
  background-color: #d50032;
}

.header-action-2 .header-action-icon-2:hover > a {
  color: #d50032;
  fill: #d50032;
}


.cart-dropdown-wrap ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 0 20px;
}

.cart-dropdown-wrap ul li .shopping-cart-img a {
  display: block;
}

.cart-dropdown-wrap {
  position: absolute;
  right: 0;
  top: calc(100% + 20px);
  z-index: 99;
  width: 320px;
  background-color: #fff;
  -webkit-box-shadow: 0 8px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.05);
  padding: 30px 20px 27px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 4px;
  border: 1px solid #eef0ee;
}

.cart-dropdown-wrap.cart-dropdown-hm2 {
  right: -63px;
}

.cart-dropdown-wrap ul li:last-child {
  margin: 0 0 0px;
}

.cart-dropdown-wrap ul li .shopping-cart-img {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 80px;
  flex: 0 0 80px;
  margin-right: 20px;
}

.cart-dropdown-wrap ul li .shopping-cart-img a img {
  max-width: 100%;
}

.cart-dropdown-wrap ul li .shopping-cart-title {
  margin: 6px 0 0;
}

.cart-dropdown-wrap ul li .shopping-cart-title h4 {
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  margin: 0 0 9px;
}

.cart-dropdown-wrap ul li .shopping-cart-title h4 a {
  color: #088178;
}

.cart-dropdown-wrap ul li .shopping-cart-title h4 a:hover {
  color: #1a1a1a;
}

.cart-dropdown-wrap ul li .shopping-cart-title h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  margin: 0 0 0px;
  color: #088178;
}

.cart-dropdown-wrap ul li .shopping-cart-title h3 span {
  color: #696969;
  font-weight: 400;
  font-size: 15px;
}

.cart-dropdown-wrap ul li .shopping-cart-delete {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 100;
  -ms-flex-positive: 100;
  flex-grow: 100;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin: 6px 0 0;
}

.cart-dropdown-wrap ul li .shopping-cart-delete a {
  font-size: 18px;
  color: #696969;
}

.cart-dropdown-wrap ul li .shopping-cart-delete a:hover {
  color: #333;
}

.cart-dropdown-wrap .shopping-cart-footer .shopping-cart-total {
  border-top: 2px solid #f3f3f3;
  margin: 25px 0;
  padding: 17px 0 0;
}

.cart-dropdown-wrap .shopping-cart-footer .shopping-cart-total h4 {
  color: #9b9b9b;
  font-weight: 700;
  font-size: 15px;
  margin: 0;
}

.cart-dropdown-wrap .shopping-cart-footer .shopping-cart-total h4 span {
  font-size: 18px;
  float: right;
  color: #088178;
}

.cart-dropdown-wrap .shopping-cart-footer .shopping-cart-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 20px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-item-align: center;
      align-self: center;
}

.cart-dropdown-wrap .shopping-cart-footer .shopping-cart-button a {
  display: inline-block;
  font-size: 14px;
  color: #ffffff;
  border-radius: 4px;
  line-height: 1;
  padding: 10px 20px;
  background-color: #088178;
  font-family: "Spartan", sans-serif;
  border: 2px solid #088178;
}

.cart-dropdown-wrap .shopping-cart-footer .shopping-cart-button a.outline {
  background-color: transparent;
  border: 2px solid #088178;
  color: #088178;
}

.cart-dropdown-wrap .shopping-cart-footer .shopping-cart-button a.outline:hover {
  color: #ffffff;
}

.cart-dropdown-wrap .shopping-cart-footer .shopping-cart-button a:hover {
  background-color: #046963;
}

.header-action-2 .header-action-icon-2:hover .cart-dropdown-wrap {
  opacity: 1;
  visibility: visible;
  top: calc(100% + 10px);
}

.header-middle{
	
	/*background: url(../images/top-hojas_1_.png);*/
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-size: auto;
		background-position-x: 0%;
		background-position-y: 0%;
		background-repeat: repeat;
		background-size: 86%;
		background-repeat: no-repeat;
		background-position-x: center;
		background-position-y: -30px;
		padding-top: 97px;
		background-size: 94%;
}

.header-bottom-bg-color {
	min-height:40px
}
  
.header-middle-ptb-1 {
  padding: 131px 0 0px 0;
}

.header-middle-ptb-1-sm {
  padding-top: 15px;
}
.header-style-1 .search-style-2 select {
  border-width: 0 0 3px 0;
  border-style: solid;
  border-color: #414648;
  border-radius: 0;
}

.header-style-1 .header-top-ptb-1 {
  padding: 13px 0;
  border-bottom: 3px solid #39b4ac;
  background-color: #d8f4e2;
  background-image: none;
}

/* Header style 2 */
.header-style-2 .header-top-ptb-1 {
  padding: 10px 0;
  border-bottom: 3px solid #d50032 !important;
  font-size: 14px;
  line-height: 1;
  background: #fff;
}

.header-style-2 .header-middle-ptb-1 {
  padding: 35px 0;
}

.header-style-2 .select2-container {
  max-width: unset;
  min-width: 150px;
}

.header-style-2 .select2-container--default .select2-selection--single {
  border-width: 0 1px 0 0;
  border-style: solid;
  border-color: #e2e9e1;
  height: 45px;
  padding-left: 20px;
  width: 155px;
  max-width: unset;
  border-radius: 4px 0 0 4px;
}

.header-style-2 .select2-container--default .select2-selection--single .select2-selection__arrow {
  right: 10px;
}

.header-style-2 .search-style-2 form {
  border: 1px solid #e2e9e1;
  border-radius: 4px;
  max-width: 700px;
}

.header-style-2 .search-style-2 form input {
  color: #1a1a1a;
  max-width: 600px;
  background-color: #fff;
  border-width: 0px;
  border-radius: 0 4px 4px 0;
  margin-left: 20px;
}

/* Header style 3 */
.header-style-3.header-height-2 {
  border-bottom: none;
}

.header-style-3 .main-nav {
  border-top: 1px solid #e2e9e1;
  border-bottom: 1px solid #e2e9e1;
}

.header-style-3 .header-top-ptb-1 {
  background-color: #d50032;
  color: #fff;
  background-image: none;
}

.header-style-3 .header-top-ptb-1 a {
  color: #fff;
}

.header-style-3 .header-top-ptb-1 i {
  color: #fff;
}

.header-style-3 .header-top-ptb-1 .language-dropdown a {
  color: #1a1a1a;
}

.header-style-3 .header-middle-ptb-1 {
  padding: 25px 0;
}

.header-style-3 .header-bottom-bg-color {
  background-color: #fff;
}

.header-style-3 .main-categori-wrap > a {
  color: #494949;
}

.header-style-3 .main-menu.main-menu-light-white > nav > ul > li > a {
  color: #494949;
}

.header-style-3 .main-nav {
  border-top: 1px solid #e2e9e1;
  border-bottom: 1px solid #e2e9e1;
}

.header-style-3 .sticky-bar.stick.sticky-blue-bg {
  background-color: #fff;
}

.header-style-3 .hotline p {
  color: #d50032 !important;
}

.header-style-3 .select2-container {
  max-width: unset;
  min-width: 150px;
}

.header-style-3 .select2-container--default .select2-selection--single {
  border-width: 0 1px 0 0;
  border-style: solid;
  border-color: #e2e9e1;
  height: 45px;
  padding-left: 20px;
  width: 155px;
  max-width: unset;
  border-radius: 4px 0 0 4px;
}

.header-style-3 .select2-container--default .select2-selection--single .select2-selection__arrow {
  right: 10px;
}

.header-style-3 .search-style-2 form {
  border: 1px solid #e2e9e1;
  border-radius: 4px;
  max-width: 700px;
}

.header-style-3 .search-style-2 form input {
  color: #1a1a1a;
  max-width: 600px;
  background-color: #fff;
  border-width: 0px;
  border-radius: 0 4px 4px 0;
  margin-left: 20px;
}

/* Header style 4 */
.header-style-4 {
  position: relative;
}

.header-style-4.header-height-2 {
  border-bottom: 0;
}

.header-style-4 .header-top-ptb-1 {
  background-color: #d8f4e2;
  background-image: none;
  padding: 15px 0;
  border: 0;
}

.header-style-4 .header-top-ptb-1 .language-dropdown a {
  color: #1a1a1a;
}

.header-style-4 .header-middle-ptb-1 {
  padding: 40px 0;
}

.header-style-4 .select2-container {
  max-width: unset;
  min-width: 150px;
}

.header-style-4 .select2-container--default .select2-selection--single {
  border-width: 0 1px 0 0;
  border-style: solid;
  border-color: #e2e9e1;
  height: 45px;
  padding-left: 20px;
  width: 155px;
  max-width: unset;
  border-radius: 4px 0 0 4px;
}

.header-style-4 .select2-container--default .select2-selection--single .select2-selection__arrow {
  right: 10px;
}

.header-style-4 .search-style-2 form {
  border: 1px solid #e2e9e1;
  border-radius: 4px;
  max-width: 700px;
}

.header-style-4 .search-style-2 form input {
  color: #1a1a1a;
  max-width: 600px;
  background-color: #fff;
  border-width: 0px;
  border-radius: 0 4px 4px 0;
  margin-left: 20px;
}

.header-style-4 .header-bottom-bg-color {
  background-color: #fff;
  border-top: 1px solid #e2e9e1;
  border-bottom: 1px solid #e2e9e1;
}

.header-style-4 .main-categori-wrap > a {
  color: #fff;
  line-height: 60px;
  background: #d50032;
  padding: 0 30px;
}

.header-style-4 .main-categori-wrap > a i.up {
  right: 30px;
}

.header-style-4 .main-menu.main-menu-light-white > nav > ul > li > a {
  color: #494949;
}

.header-style-4 .stick .main-menu.main-menu-light-white > nav > ul > li > a {
  color: #ffffff !important;
}

.header-style-4 .categori-dropdown-active-large {
  top: 100%;
}

.header-style-4 .hotline p {
  color: #d50032 !important;
}

.header-style-4 .stick .hotline p {
  color: #fff !important;
}

.header-style-4 .main-categori-wrap > a span {
  color: #fff !important;
}

.header-style-5 .logo.logo-width-1 a img {
  width: 120px;
  margin-top: 5px;
}

.header-style-5 .select2-container--default {
  width: 115px !important;
}

.header-style-5 .select2-container--default .select2-selection--single {
  border: 1px solid #e8e8e8;
  height: 45px;
  padding-left: 10px;
  font-size: 13px;
}

.header-style-5 .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 45px;
  font-size: 13px;
}

.header-style-5 .select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 10px;
  right: 7px;
}

.header-style-5 .logo.logo-width-1 {
  margin-right: 40px;
}

.header-style-5 .header-bottom .header-action-right a span.pro-count {
  color: #fff;
}

.header-style-5 .header-action-2 .header-action-icon-2 > a {
  font-size: 24px;
}

.header-style-5 .select2-container {
  max-width: unset;
  min-width: 100px;
}

.header-style-5 .select2-container--default .select2-selection--single {
  border-width: 0 1px 0 0;
  border-style: solid;
  border-color: #e2e9e1;
  height: 45px;
  padding-left: 20px;
  width: 105px;
  max-width: unset;
  border-radius: 4px 0 0 4px;
}

.header-style-5 .select2-container--default .select2-selection--single .select2-selection__arrow {
  right: 10px;
}

.header-style-5 .search-style-2 form {
  border: 1px solid #e2e9e1;
  border-radius: 4px;
  max-width: 700px;
}

.header-style-5 .search-style-2 form input {
  color: #1a1a1a;
  max-width: 600px;
  background-color: #fff;
  border-width: 0px;
  border-radius: 0 4px 4px 0;
  margin-left: 20px;
}

span.fi-rs-apps {
  font-size: 19px;
  opacity: 0.7;
}

.hotline i {
  opacity: 0.7;
}

.header-height-2 .search-style-2 form select {
  border: solid #414648;
  border-width: 0 0 3px;
  border-radius: 0;
}


.header-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-wrap .header-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.header-wrap.header-space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content:  center;
}

.header-wrap .header-right {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}


.logo a {
  display: block;
}

.logo.logo-width-1 {
  margin-right: 123px;
}

.logo.logo-width-1 a img {
  width: 189px;
  min-width: 120px;
}

.sticky-bar.stick.sticky-white-bg {
  background-color: #fff;
}

.sticky-bar.stick.sticky-blue-bg {
  background-color: #3286e0;
}

.position-relative {
  position: relative;
}


/*LAYOUT -> NAVIGATION STYLE*/
.main-menu.main-menu-mrg-1 {
  margin: 0 0 0 27px;
}

.main-menu > nav > ul > li.position-static {
  position: static;
}

.main-menu > nav > ul > li > a {
  display: inline-block;
  font-size: 13px;
  font-weight: normal;
  color: #1a1a1a;
  padding: 5px 12px;
}
 

.main-menu > nav > ul > li > a i {
  font-size: 8px;
  position: relative;
  margin-left: 4px;
}

.main-menu > nav > ul > li > a.active {
  color: #d50032;
}

.main-menu > nav > ul > li ul.sub-menu {
  position: absolute;
  left: 0;
  top: 100%;
  background-color: #ffffff;
  min-width: 250px;
  padding: 0px;
   
  opacity: 0;
  visibility: hidden;
  margin-top: 20px;
   border-radius: 4px;
  z-index: 125;
  border: 1px solid #eef0ee;
  display: none;
   

}

.main-menu > nav > ul > li:hover > ul.sub-menu {
  display: initial; 
  padding: 10px 0px;
  
   
		
}

 
.main-menu > nav > ul > li ul.sub-menu li {
  /*line-height: 1;*/
  display: block;
  /*margin-bottom: 5px;*/
  height: 35px;
  position: relative;

}

.main-menu > nav > ul > li ul.sub-menu li:last-child {
  margin-bottom: 0;
}

.main-menu > nav > ul > li ul.sub-menu li ul.level-menu1 {
  position: absolute;
  left: 100%;
  top: 100%;
  /*top: -110px;*/
  background-color: #ffffff;
  width: 240px;
  padding: 5px 0 5px 0px;
  /*-webkit-transition: all .25s ease 0s;
  transition: all .25s ease 0s;*/
  opacity: 0;
  visibility: hidden;
  border-radius: 4px;
  margin-top: 20px;
  border: 1px solid #eef0ee;
}

.main-menu > nav > ul > li ul.sub-menu li ul.level-menu1 li ul.level-menu2 {
  position: absolute;
  left: 100%;
  top: 100%;
  background-color: #ffffff;
  width: 240px;
  padding: 5px 0 5px 0px;
  -webkit-transition: all .25s ease 0s;
  transition: all .25s ease 0s;
  opacity: 0;
  visibility: hidden;
  border-radius: 4px;
  margin-top: 20px;
  border: 1px solid #eef0ee;
}

.main-menu > nav > ul > li ul.sub-menu li ul.level-menu1 li ul.level-menu2 li ul.level-menu3 {
  position: absolute;
 /* left: 94%;*/
  top: -110px;
  background-color: #ffffff;
  width: 240px;
  padding: 33px 0 35px;
  /*-webkit-transition: all .25s ease 0s;
  transition: all .25s ease 0s;*/
  opacity: 0;
  visibility: hidden;
  border-radius: 4px;
  margin-top: 20px;
  border: 1px solid #eef0ee;
}



.main-menu > nav > ul > li ul.sub-menu li:hover ul.level-menu1 {
  opacity: 1;
  visibility: visible;
  margin-top: 0px;

}

.main-menu > nav > ul > li ul.sub-menu li ul.level-menu1 li:hover ul.level-menu2 {
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
}


.main-menu > nav > ul > li ul.sub-menu li ul.level-menu1 li ul.level-menu2 li:hover ul.level-menu2 {
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
}


.main-menu > nav > ul > li ul.sub-menu li ul.level-menu1.level-menu-modify {
  top: 0px;
}

.main-menu > nav > ul > li ul.sub-menu li ul.level-menu1 li ul.level-menu2.level-menu-modify {
  top: 0px;
}
.main-menu > nav > ul > li ul.sub-menu li ul.level-menu1 li ul.level-menu2 li ul.level-menu3.level-menu-modify {
  top: 0px;
}

.main-menu > nav > ul > li ul.sub-menu li a i {
  font-size: 9px;
  float: right;
  position: relative;
  top: 4px;
}

.main-menu > nav > ul > li ul.mega-menu {
  position: absolute;
  left: calc(35% - 25%);
  top: 100%;
  background-color: #ffffff;
  width: 80%;
  padding: 14px 0px 23px 0px;
  -webkit-transition: all .25s ease 0s;
  transition: all .25s ease 0s;
  opacity: 0;
  visibility: hidden;
  margin-top: 20px;
  border-radius: 0 0 4px 4px;
  z-index: 999;
  border: 1px solid #eef0ee;
  transition: padding-top .2s linear 0ms;
}

.main-menu > nav > ul > li ul.mega-menu li {
  line-height: 1;
  display: block;
  position: relative;
  float: left;
  padding-right: 10px;
}

.main-menu > nav > ul > li ul.mega-menu li:last-child {
  margin-bottom: 0;
}

.main-menu > nav > ul > li ul.mega-menu li.sub-mega-menu-width-22 {
  width: 22%;
}
.main-menu > nav > ul > li ul.mega-menu li.sub-mega-menu-width-20 {
  width: 20%;
}
.main-menu > nav > ul > li ul.mega-menu li.sub-mega-menu-width-25 {
  width: 25%;
}
.main-menu > nav > ul > li ul.mega-menu li.sub-mega-menu-width-33 {
  width: 33%;
}
.main-menu > nav > ul > li ul.mega-menu li.sub-mega-menu-width-34 {
  width: 34%;
}
.main-menu > nav > ul > li ul.mega-menu li.sub-mega-menu-width-50 {
  width: 50%;
}
.main-menu > nav > ul > li ul.mega-menu li a.menu-title {
  font-size: 19px;
  font-weight: 500;
  display: block;
}

.main-menu > nav > ul > li ul.mega-menu li ul {
  margin-top: 2px;
  /*margin-left: -27px;*/
  margin-left: 12px;
}

.main-menu > nav > ul > li ul.mega-menu li ul li {
  line-height: 1;
  display: block;
  margin-bottom: 14px;
  float: none;
}

.main-menu > nav > ul > li ul.mega-menu li ul li:last-child {
  margin-bottom: 0;
}

.main-menu > nav > ul > li ul.mega-menu li ul li a {
  font-size: 15px;
  color: #696158;
  display: block;
  line-height: 1.4;
}

.main-menu > nav > ul > li ul.mega-menu li ul li a:hover {
  color: #696158;
  font-weight:bold;
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap {
  overflow: hidden;
  position: relative;
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap a {
  display: block;
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap a img {
  width: 100%;
  -webkit-transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
  transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-content {
  position: absolute;
  top: 32px;
  left: 30px;
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-content h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5;
  margin: 5px 0 11px;
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-content .menu-banner-price span.old-price {
  font-size: 19px;
  font-weight: 400;
  color: #696969;
  text-decoration: line-through;
  margin-left: 5px;
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-content .menu-banner-btn a {
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  color: #ffffff;
  border-radius: 26px;
  padding: 12px 22px 14px;
  background-color: #d50032;
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-discount h3 span {
  display: block;
  line-height: 1;
  font-weight: 700;
  font-size: 20px;
  margin: 0 0 3px;
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap:hover a img {
  -webkit-transform: scale3d(1.1, 1.1, 1.1);
  transform: scale3d(1.1, 1.1, 1.1);
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap:hover .menu-banner-btn a {
  background-color: #ff3551;
}

.main-menu > nav > ul > li:hover > a {
  /*color: #000;*/
  font-weight:bold;
}

.main-menu > nav > ul > li:hover ul.sub-menu {
  opacity: 0.9;
  visibility: visible;
  margin-top: 1px !important;
}

.main-menu > nav > ul > li:hover ul.mega-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.main-menu.hover-boder > nav > ul > li > a {
  position: relative;
}

.main-menu.hover-boder > nav > ul > li > a::after {
  content: none;
  position: absolute;
  left: auto;
  right: 0;
  bottom: 34px;
  height: 1px;
  width: 0;
  -webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  background: #d50032;
}

.main-menu.hover-boder > nav > ul > li:hover > a::after {
  width: 100%;
  left: 0;
  right: auto;
}

.main-menu.hover-boder.hover-boder-white > nav > ul > li > a::after {
  bottom: 18px;
  background: #ffffff;
}

.main-menu.hover-boder.hover-boder-modify > nav > ul > li > a::after {
  bottom: 28px;
}

.main-menu.main-menu-light-white > nav > ul > li > a {
  color: white;
}

.main-menu.main-menu-padding-1 > nav > ul > li {
  /*padding: 0 17px 11px 0;*/
  padding: 0 0px 0px 0;
  color: #000 !important;
   /*borrar*/
/*  border-bottom: 1px solid #fff;*/
}



.main-menu.main-menu-padding-1 > nav > ul > li:hover {
	color: #000 !important;
  /*border-bottom: 1px solid #000;*/
}


.main-menu.main-menu-padding-1 > nav > ul > li:first-child {
  padding-left: 0 !important;
}

.main-menu.main-menu-lh-2 > nav > ul > li {
  line-height:2.3;
}

.main-menu.main-menu-lh-3 > nav > ul > li {
  line-height: 80px;
}

.main-menu.main-menu-grow {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.main-menu > nav > ul > li {
  display: inline-block;
  position: relative;
}

.main-menu > nav > ul > li ul.sub-menu li a {
  font-size: 13px;
  color: #555;
  display: block;
  padding-left : 5px;
  color: #696158 !important;
}

.main-menu > nav > ul > li ul.sub-menu li a:hover {
  color: #d50032;
  font-weight: 600;

}
/*
.main-menu > nav > ul > li ul.sub-menu li:hover {
  background-color: #d50032;

}
*/

.main-menu > nav > ul > li ul.sub-menu li a img {
 /* width:60px;
  height:30px;
  -webkit-clip-path: inset(30px 1px 30px 30px); 
  clip-path: inset(30px 60px 30px 30px);
  margin-right: -25px;*/
  
  object-fit: cover;
-o-object-position: 0;
object-position: 0;
width: 30px;
height: 30px;

}

.main-menu > nav > ul > li ul.sub-menu li:hover a img {
 /* width:60px;
  height:30px;
  -webkit-clip-path: inset(30px 1px 30px 30px); 
  clip-path: inset(30px 1px 30px 30px);
 	margin-right: 5px;
 	margin-left: -30px;*/
 	
 	object-position: 100%;
}

.main-menu > nav > ul > li ul.sub-menu li:hover > a {
  color: #000;
}


.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-content h4 {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  margin: 0;
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-content .menu-banner-price {
  margin: 0 0 28px;
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-content .menu-banner-price span.new-price {
  font-size: 24px;
  font-weight: 700;
  color: #ff3551;
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-discount {
  width: 80px;
  height: 80px;
  line-height: 80px;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ffd55a;
  position: absolute;
  top: 13%;
  right: 7%;
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-discount h3 {
  color: #333;
  margin: 0;
  text-align: center;
  font-size: 19px;
  font-weight: 600;
  line-height: 1;
}

.main-menu.main-menu-lh-1 > nav > ul > li {
  line-height: 70px;
}

.main-categori-wrap {
  position: relative;
  margin-right: 70px;
}

.main-categori-wrap > a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
}

.main-categori-wrap > a span {
  margin-right: 7px;
  color: #161c27;
}

.main-categori-wrap > a > i {
  margin-left: 5px;
  margin-top: 7px;
  color: #999;
}

.main-categori-wrap > a > i.up {
  position: absolute;
  top: 40%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  opacity: 0;
  visibility: hidden;
}

.main-categori-wrap > a.open > i {
  opacity: 0;
  visibility: hidden;
}

.main-categori-wrap > a.open > i.up {
  opacity: 1;
  visibility: visible;
}

.categori-dropdown-active-large {
  list-style-type: none;
  position: absolute;
  top: 177%;
  left: 0;
  z-index: 9;
  margin: 0;
  padding: 15px 0 10px 0;
  background: #fff;
  border: 1px solid #eef0ee;
  border-radius: 0 0 4px 4px;
  font-size: 15px;
  font-weight: 400;
  min-width: 270px;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 0;
  visibility: hidden;
}

.categori-dropdown-active-large.open {
  opacity: 1;
  visibility: visible;
}

.categori-dropdown-active-small {
  z-index: 9;
  margin: 0;
  padding: 14px 0 23px;
  background: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.09);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.09);
  font-size: 15px;
  font-weight: 400;
  display: none;
  width: 100%;
}

.categori-dropdown-wrap ul li {
  display: block;
}

.categori-dropdown-wrap ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 5px 34px;
  line-height: 1.5;
  color: #1a1a1a;
  font-size: 15px;
}

.categori-dropdown-wrap ul li a:hover {
  color: #d50032;
  background-color: rgba(78, 151, 253, 0.1);
}

.categori-dropdown-wrap ul li a i {
  margin-right: 16px;
  font-size: 18px;
  opacity: 0.9;
}

.categori-dropdown-wrap ul li:hover .dropdown-menu {
  display: block;
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
  pointer-events: auto;
}

.categori-dropdown-wrap ul li.has-children {
  position: relative;
}

.categori-dropdown-wrap ul li.has-children > a::after {
  content: "\f111";
  font-family: 'uicons-regular-straight' !important;
  position: absolute;
  right: 30px;
  top: 50%;
  margin-top: -10px;
}

.categori-dropdown-wrap ul li.has-children .dropdown-menu {
  left: 100%;
  top: 0;
  margin: 0;
  margin-top: 0px;
  border: 1px solid #eef0ee;
  min-width: 800px;
  width: 100%;
  right: 0;
  border-radius: 0;
  padding: 20px;
}

.categori-dropdown-wrap ul li.has-children .dropdown-menu .submenu-title {
  font-size: 19px;
  font-weight: 500;
  display: block;
  color: #d50032;
  padding: 5px 34px;
}

.categori-dropdown-wrap ul li.has-children .dropdown-menu .header-banner2 {
  display: block;
  position: relative;
  margin-bottom: 15px;
}

.categori-dropdown-wrap ul li.has-children .dropdown-menu .header-banner2 .banne_info {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 15px;
}

.categori-dropdown-wrap ul li.has-children .dropdown-menu .header-banner2 .banne_info a {
  text-transform: capitalize;
  position: relative;
  padding: 0;
  color: #272a2c !important;
}

.categori-dropdown-wrap ul li.has-children .dropdown-menu .header-banner2 .banne_info a::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  width: 50%;
  background-color: #272a2c;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.categori-dropdown-wrap .more_categories {
  border-top: 1px solid #eaeaea;
  padding: 10px 34px 0 34px;
  margin-top: 10px;
  color: #d50032;
  position: relative;
  font-size: 13px;
  font-family: "Spartan", sans-serif;
}

.categori-dropdown-wrap .more_categories::before {
  content: "";
  width: 15px;
  height: 1px;
  background-color: #d50032;
  position: absolute;
  right: 31px;
  top: 21px;
}

.categori-dropdown-wrap .more_categories::after {
  content: "";
  width: 1px;
  height: 15px;
  background-color: #d50032;
  position: absolute;
  right: 38px;
  top: 14px;
}

.categori-dropdown-wrap .more_categories.show::after {
  display: none;
  content: none;
}


.search-style-2 {
  width: 100%;
}

.search-style-2 form {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  justify-content: right;
}

.search-style-2 form select {
  color: #1a1a1a;
  font-size: 16px;
  font-weight: 700;
  min-height: 45px;
  border: 1px solid #e2e9e1;
  border-right: none;
  width: 140px;
  padding: 3px 35px 3px 20px;
  border-radius: 4px 0 0 4px;
  background-color: #fff;
  -webkit-transition: all .3s ease 0s;
  transition: all .3s ease 0s;
  -moz-appearance: none;
  -webkit-appearance: none;
  position: relative;
}

.search-style-2 form select:focus {
  color: #333;
}

.search-style-2 form select::after {
  position: absolute;
}

.search-style-2 form input {
  width: 38%;
  max-width: 300px;
  border-radius: 0;
  background-color: #fff;
  /*border-color: #414648;*/
  border-width: 0 1px 1px 0;
  border-style: solid;
  font-size: 14px;
 	background-image: url(../images/ico_search.png); 	
  background-repeat: no-repeat;
  background-position:  right;
  background-position-x:  right;
  padding-left: 9px;
  /*font-style: italic;*/
  border-color: #696158;
  padding-right: 18px;
  height: 24px;
	margin-top: 6px;
}

.search-style-2 form input::-moz-input-placeholder {
  opacity: 1;
}

.search-style-2 form input::-webkit-input-placeholder {
  opacity: 1;
}
/*
.search-style-2 form input:focus {
  border-color: #414648;
  border-width: 0 0 3px 0;
  border-style: solid;
}
*/
.search-style-2 form button {
  position: absolute;
  right: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: transparent;
  padding: 0;
  border: none;
  font-size: 20px;
  color: #d50032;
  height: 100%;
  padding: 5px 17px;
}

.main-menu-navigator ul{
	margin-bottom: 0rem;
	/*margin-top: 7px !important;*/
	 
}

.main-menu a{
	color: #696158;
}

.header-container{
	max-width: 1280px;
	padding-left: 20px;
	padding-right: 20px; 
}

.header-border-bottom{
	border-bottom: 1px solid rgb(240, 236, 236);
}

.header-backgound{
	/*background: url(../images/top-hojas_1_.png);

	background-repeat: no-repeat;
	background-position-x: center;
	background-position-y: -45px;
	padding-top: 33px;
	background-size: 90%;*/
}

.main-store-location{
	padding-left: 0px;
}
    
/*-----------------
	13. Footer
-----------------------*/

.footer {
    background-color: #fff;
    
   	max-width: 1280px;
padding-left: 20px;
padding-right: 20px;
}
.footer .footer-top {
	padding: 0;
	border-top: 1px solid #000;
}
.footer-title {
    color: #333;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    text-transform: capitalize;
}
.footer .footer-widget.footer-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    outline: none;
}
.footer .footer-widget .footer-logo {
	margin-bottom: 17px;
}

.footer .footer-widget .footer-logo img {
	width: 139px;
	/*height: 31px;*/
}


.footer .footer-widget .footer-about-content p {
	font-size: 11px;
color: #686058;
line-height: 1rem;
}
.footer .footer-widget .footer-about-content p:last-child {
	margin-bottom: 0;
}
.footer .footer-menu ul li {
    margin-bottom: 5px;
    position: relative;
}
.footer .footer-menu ul li:last-child {
	margin-bottom: 0;
}
.footer .footer-menu ul li a {
	color: #777;
	font-size: 13px;
	position: relative;
	transition: all 0.4s ease 0s;
}
.footer .footer-widget.footer-menu ul li a:hover {
	/*color: #a1488e;*/
	/*letter-spacing: 0.5px;*/
	text-decoration:underline;
	/*padding-left: 10px;*/
}
.footer-contact-info {
	color: #111;
	font-size: 15px;
}
.footer-contact-info .footer-address {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.footer-contact-info .footer-address span {
	margin-right: 20px;
}
.footer-contact-info .footer-address span i.fa-map-marker-alt {
	font-size: 20px;
}
.footer-contact-info p i {
	margin-right: 15px;
}
.footer .footer-bottom .copyright {
	border-top: 1px solid #d4d4d4;
	padding: 30px 0;
}
.footer .footer-bottom .copyright-text p {
	font-size: 18px;
}
.footer .footer-bottom .copyright-text p a {
	color: #111;
	-webkit-transition: all 0.4s ease 0s;
	-o-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
}
.footer .footer-bottom .copyright-text p a:hover {
	color: #111;
}
.footer .footer-bottom .copyright-text p.title {
	font-weight: 400;
	margin: 10px 0 0;
}
.footer .social-icon ul {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	list-style: none;
	padding: 0;
	margin: 0;
}
.footer .social-icon ul li {
	margin-right: 5px;
}
.footer .social-icon ul li:last-child {
	margin-right: 0;
}
.footer .social-icon ul li a {	
	color: #111;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	font-size: 20px;
	transition: all 0.4s ease 0s;
}
.footer .social-icon ul li a:hover {
	color: #a1488e;
}
.policy-menu {
    font-size: 18px;
    margin: 0;
    padding: 0;
    text-align: right;
}
.policy-menu li {
    display: inline-block;
    margin-right: 15px;
}
.policy-menu li:last-child {
	margin-right: 0;
}
.policy-menu li a {
    color: #777;
}
.policy-menu li a:hover, .policy-menu li a:focus {
	color: #a1488e;
}
.policy-menu li::after {
	color: #777;
    content: "|";
    font-weight: 300;
    position: relative;
    left: 10px;
}
.policy-menu li:last-child::after {
    content: "";
}

.custom-container{
	max-width: 1280px;
	padding-left: 20px;
	padding-right: 20px; 
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-5 {
  margin-top: 5px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.pt-5 {
  padding-top: 5px !important;
}

.product-detail-rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 15px 0;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.product-detail .section-title.style-1 {
  font-size: 22px;
}

.product-detail .social-icons.single-share {
  margin-top: 20px;
}



/*PRODUCT DETAILS*/
.detail-gallery {
  position: relative;
}

.detail-gallery .zoom-icon {
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 2;
  cursor:pointer
}

.detail-gallery .tag {
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 2;
  cursor:pointer
}


.detail-gallery .custom-product-tag {
  -webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    position: absolute;
     
    min-width: 38px;
    padding-left: 5px;
    padding-right: 5px;
    height: 28px;
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
    border-radius: 3px;
    color: #fff;
    /*-webkit-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: hidden;*/
    /*opacity: 0.9;*/
		z-index: 99;
		font-weight:bold;
		font-size:12px;
}


.discount-tag {
  background-color: #ff003c;
	padding-left: 7px;
	padding-right: 7px;
	height: 31px;
	font-size: 15px;
	color: #fff;
	font-weight: bold;
	padding-top: 7px;
}


.new-tag {
	
	 
	padding-left: 7px;
	padding-right: 7px;
	height: 31px;
	font-size: 22px;
	color: #696158;
	font-weight: bold;
	padding-top: 7px;
	opacity: 0.7;
  
    
}

.slider-nav-thumbnails .slick-slide {
  opacity: 0.5;
  position: relative;
}

.slider-nav-thumbnails .slick-slide.slick-current {
  opacity: 1;
}

.slider-nav-thumbnails .slick-slide.slick-current::before {
  border-bottom: 5px solid #333;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  content: "";
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  top: -6px;
  width: 0;
}

.slider-nav-thumbnails .slick-slide.slick-current img {
  border: 2px solid #a2d2c9;
}

.slider-nav-thumbnails div.slick-slide {
  margin: 0 3px;
}

.slider-nav-thumbnails button.slick-arrow {
  margin: 0;
}

.slider-nav-thumbnails .slick-prev {
  left: 0;
}

.slider-nav-thumbnails .slick-next {
  right: 0;
}

.slider-nav-thumbnails .slick-prev,
.slider-nav-thumbnails .slick-next {
  font-size: 12px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.product-rate {
  /*background-image: url("../imgs/theme/rating-stars.png");*/
  background-position: 0 -12px;
  background-repeat: repeat-x;
  height: 12px;
  width: 60px;
  transition: all 0.5s ease-out 0s;
  -webkit-transition: all 0.5s ease-out 0s;
}

.product-rating {
  height: 12px;
  background-repeat: repeat-x;
  /*background-image: url("../imgs/theme/rating-stars.png");*/
  background-position: 0 0;
}

.detail-info .product-price ins {
  font-size: 30px;
  text-decoration: none;
  font-weight: 600;
}

.detail-info .product-price ins span.old-price {
  text-decoration: line-through;
  color: #888;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
}
.detail-info .product-meta {
 /* border-top: 1px solid #e2e9e1;*/
  padding-top: 15px;
}


.detail-qty {
  /*max-width: 80px;
  padding: 9px 20px;
  position: relative;
  width: 100%;
  border-radius: 4px;
	border: 1px solid #b4b4b4 !important;
	line-height: 20px;*/
	display: flex !important;
	justify-content: center !important;
	/*margin-top: 10px !important;*/
	width: 32%;
}

.detail-qty-shopcart {  
	display: flex !important;
	justify-content: center !important;
	max-width: 80px
}


.detail-qty-shopcart > button {
  font-size: 16px;
 /* position: absolute;
  right: 8px;
  color: #707070;
  background:#FFF;
  border: 0px;*/
}

.detail-qty-shopcart > button:hover {
  color: #d50032;
}

.detail-extralink {
  display: flex;
  vertical-align: top;
 	width: 95%;
}

.detail-qty-shopcart > button.qty-up {
  background: #fff !important;
	border: 1px solid #b4b4b4 !important;
	height: 41px;
	padding: 5px 3px 5px 2px;
	width: 33%;
	margin-right: 0px !important;
 
}

.detail-qty-shopcart > button.qty-down {

 background: #fff !important;
	border: 1px solid #b4b4b4 !important;
	height: 41px;
	padding: 5px 3px 5px 2px;
	width: 33%;
	margin-right: 0px !important;
}

.detail-extralink-container {
	display: flex;
	/*height: 40px;*/
	width: 380px;
}
.mr--15{
	margin-right: -15px !important;	
}


.detail-qty > button {
  font-size: 16px;
 /* position: absolute;
  right: 8px;
  color: #707070;
  background:#FFF;
  border: 0px;*/
}

.detail-qty > button:hover {
  color: #d50032;
}
.qty-val {
	/*
	background: transparent;
	max-width:50px;
	border: 0px;*/
	background: #fff !important;
	border: 1px solid #b4b4b4 !important;
	height: 41px;
	padding: 5px 3px 5px 2px;
	width: 33%;
	border-radius:0px;
	text-align: center;
}
.detail-qty > button.qty-up {
  background: #fff !important;
	border: 1px solid #b4b4b4 !important;
	height: 41px;
	padding: 5px 3px 5px 2px;
	width: 33%;
	margin-right: 0px !important;

/*padding-top: 3px;
  top: 0px;
	height: 18px;
	width: 16px;
	cursor: pointer; */
}

.detail-qty > button.qty-down {
  /*bottom: 0px;
  height: 18px;
	width: 16px;
	cursor:pointer*/
	 background: #fff !important;
	border: 1px solid #b4b4b4 !important;
	height: 41px;
	padding: 5px 3px 5px 2px;
	width: 33%;
	margin-right: 0px !important;
}

.mini-qty-val {
	 
	width: 40px; 
	height: 29px;
	text-align: center;
	border: 1px solid #979797;
	border-radius: 0 !important; 
}

.detail-qty-mini {
  max-width: 56px;
  padding: 6px 8px;
  position: relative;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #a5a5a5 !important;
}

.detail-qty-button-mini {
  font-size:12px !important;
  /*padding: 5px 3px 5px 2px;*/
  padding: 0 1px 0 1px;
	height: 30px;
	min-width: 88px;
	border-radius: 0px !important;
}

.detail-add-button-mini{
	background-color: #fff ;
	border : 1px solid #b4b4b4 ;
	font-size:12px !important;
  /*padding: 5px 3px 5px 2px;*/
  padding: 0 1px 0 1px;
	height: 30px;
	min-width: 100px;
	border-radius: 0px !important;
}

.detail-add-button-mini:hover{
	background-color: #d50032;
	border-color: #d50032;
	color: #fff;
}

.detail-add-button-mini-secondary{
	background-color: #fff ;
	border : 1px solid #b4b4b4 ;
	font-size:12px !important;
  /*padding: 5px 3px 5px 2px;*/
  padding: 0 1px 0 1px;
	height: 30px;
	min-width: 100px;
	border-radius: 0px !important;
}

.detail-add-button-mini-secondary:hover{
	background-color: #676159;
	border-color: #676159;
	color: #fff;
}

.detail-qty-mini > button {
  font-size: 16px;
  position: absolute;
  right: 4px;
  color: #707070;
  background: transparent;
  border:0px;
}

.detail-qty-mini > button:hover {
  color: #d50032;
  text-decoration: none !important;
}

.detail-qty-mini > button.qty-up {
  padding-top: 3px;
  top: 0px;
	height: 18px;
	width: 18px;
	cursor: pointer;
}

.detail-qty-mini > button.qty-down {
  bottom: 0px;
  height: 18px;
	width: 18px;
	cursor:pointer
}

.special-col{
	min-height: 37px; 
	display: flex;
	justify-content: center; 
}
 

  
.icon-trash{
	width:29px !important;
	height:29px !important;
	object-fit: contain !important;
	cursor: pointer;
}
.icon-trash-mini{
	/*width:20px !important;
	height:20px !important;
	object-fit: contain !important;*/
	cursor: pointer;
	background: transparent;
	border: 0px;
}

.confirm-upd-mini {
 
	color: #fff;
	border: 0px solid #d50032;
	background: #d50032;
	border-radius: 3px;
	opacity: 0.9;
/*	margin-left: -25px;*/
	height: 29px;
	border-radius:0px !important;
}

.towishlist {
    content: "";
    width: 23px;
    height: 23px;
    display: inline-block;
    background-image: url('../images/ico_off_wishlist.svg');
    background-color: transparent;
    border:0px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 1px;
    /*top: 5px;
		position: absolute;*/
		cursor:pointer;
}

.towishlist-active {
    content: "";
    width: 23px;
    height: 23px;
    display: inline-block;
    background-image: url('../images/ico_on_wishlist.svg');
    background-color: transparent;
    border:0px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 1px;
    /*top: 5px;
		position: absolute;*/
		cursor:pointer;
}


.towishlist:hover {
    background-image: url('../images/ico_on_wishlist.svg');
}

.attr-detail .select-box select {
  height: 40px;
  width: 100%;
}
ol, ul {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
}
.attr-detail.attr-brand {
  margin-top: 23px;
}

.attr-detail.attr-brand .select-box {
  display: block;
  margin-bottom: 20px;
}

.attr-detail.attr-color table {
  margin-bottom: 15px;
}


.detail-info .product-price-cover {
  border-top: 1px solid #e2e9e1;
  /*border-bottom: 1px solid #e2e9e1;*/
  padding: 15px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.detail-info .product-price-cover .product-price {
  line-height: 1;
}

.button.button-add-to-cart {
  padding: 8px 40px;
}

.product-extra-quantity {
	width: 40%
}

.product-extra-link2-mini {
	display: flex !important;
	justify-content: center !important;
	margin-top: 10px !important;
	
}
/*
.product-extra-link2-mini -old a {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #e5e5e5;
  color: #808080;
  border-radius: 4px;
  display: inline-block;
  height: 39px;
  line-height: 39px;
  position: relative;
  text-align: center;
  vertical-align: top;
  width: 40px;
  margin: 0 3px;
  
  transition: all 0.5s ease-out 0s;
  -webkit-transition: all 0.5s ease-out 0s;
}

.product-extra-link2-mini old a:hover {
  background-color: #d50032;
  color: #fff !important;
}
*/
.attr-color, .attr-size {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}


.product-color-red {
  background: #ff596d;
}

.product-color-yellow {
  background: #ffdb33;
}

.product-color-white {
  background: #ffffff;
}

.product-color-orange {
  background: #ffbb51;
}

.product-color-cyan {
  background: #80e6ff;
}

.product-color-green {
  background: #38cf46;
}

.product-color-purple {
  background: #ff8ff8;
}

.color-filter img {
  
  width: 33px; 
}

.color-filter-mini img {
  display: block;
  width: 22px;
  height: 22px;
}

.plus-option{
	position: absolute;
	top: 0px;
	background: transparent;
	border: 0px;
	font-size: 15px;
	line-height: 14px;
	text-decoration:underline
}

.color-filter-mini .option {
  margin-right:5px;
}


.shop-product-fillter-header .color-filter {
  border-bottom: 1px solid #e2e9e1;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.deal .product-price .old-price {
  color: #cccccc;
  text-decoration: line-through;
}

.product-cart-wrap .product-content-wrap .product-price span.old-price {
  font-size: 14px;
  font-weight: 400;
  color: #90908e;
  margin: 0 0 0 7px;
  text-decoration: line-through;
}

.product-list-small .product-price span.old-price {
  font-size: 14px;
  font-weight: 400;
  color: #90908e;
  margin: 0 0 0 7px;
  text-decoration: line-through;
}



.text-brand {
  color: #d50032 !important;
  text-decoration: none !important;
  font-size: 26px;
}


.entry-meta.meta-1 a.text-brand {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.entry-meta.meta-1 a.text-brand i {
  margin-left: 5px;
}
.font-xs {
  font-size: 13px;
}
.mr-5 {
  margin-right: 5px !important;
}

.mt-15 {
  margin-top: 15px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}

.mr-10 {
  margin-right: 10px !important;
}
.border-color-1 {
  border-color: #e0dede;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.font-md {
  font-size: 16px !important;
}
.font-lg {
  font-size: 18px !important;
}
.font-xl{
	font-size: 22px !important;
}
.ml-15 {
  margin-left: 15px !important;
}

.ml-30 {
  margin-left: 30px !important;
}
.ml-35 {
  margin-left: 35px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

/*****************************
*********  SOCIAL NETWORKS  **********
******************************/
.text-center.social-icons ul {
  display: inline-block;
}

.social-icons li {
  float: left;
  list-style: none;
}

.social-icons li a {
  float: left;
  font-size: 16px;
  text-align: center;
  margin: 0 4px 4px 0;
  border-radius: 4px;
  border: 0;
  background: 0 0;
  color: #333;
  overflow: hidden;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.dark .social-icons li a {
  color: #fff;
}

.social-icons.social-icons-colored a,
.social-icons.social-icons-colored-hover a:hover {
  color: #fff !important;
  border: 0;
}

.social-icons.social-icons-colored .social-rss a,
.social-icons.social-icons-colored-hover .social-rss a:hover,
.social-icons.social-icons-colored .social-snapchat a,
.social-icons.social-icons-colored-hover .social-snapchat a:hover {
  background-color: #faa33d;
}

.social-facebook button,
.social-whatsapp button,
.social-twitter button,
.social-email button
{
  background: transparent;
	border: 0px;
}


.social-icons.social-icons-colored .social-vimeo a,
.social-icons.social-icons-colored-hover .social-vimeo a:hover {
  background-color: #35c6ea;
}

.social-icons.social-icons-colored .social-myspace a,
.social-icons.social-icons-colored-hover .social-myspace a:hover {
  background-color: #008dde;
}

.social-icons.social-icons-colored .social-youtube a,
.social-icons.social-icons-colored-hover .social-youtube a:hover {
  background-color: #ef4e41;
}

.social-icons.social-icons-colored .social-instagram a,
.social-icons.social-icons-colored-hover .social-instagram a:hover {
  background-color: #e53d00;
}
.social-icons.social-icons-colored .social-whatsapp a,
.social-icons.social-icons-colored-hover .social-whatsapp a:hover {
  background-color: #10d94b;
}

.social-icons.social-icons-colored .social-gplus a,
.social-icons.social-icons-colored-hover .social-gplus a:hover {
  background-color: #d68400;
}

.social-icons.social-icons-colored .social-stumbleupon a,
.social-icons.social-icons-colored-hover .social-stumbleupon a:hover {
  background-color: #ff5c30;
}

.social-icons.social-icons-colored .social-lastfm a,
.social-icons.social-icons-colored-hover .social-lastfm a:hover {
  background-color: #f34320;
}

.social-icons.social-icons-colored .social-pinterest a,
.social-icons.social-icons-colored-hover .social-pinterest a:hover {
  background-color: #e13138;
}

.social-icons.social-icons-colored .social-google a,
.social-icons.social-icons-colored-hover .social-google a:hover {
  background-color: #eb5e4c;
}

.social-icons.social-icons-colored .social-evernote a,
.social-icons.social-icons-colored-hover .social-evernote a:hover {
  background-color: #9acf4f;
}

.social-icons.social-icons-colored .social-dribbble a,
.social-icons.social-icons-colored-hover .social-dribbble a:hover {
  background-color: #f7659c;
}

.social-icons.social-icons-colored .social-skype a,
.social-icons.social-icons-colored-hover .social-skype a:hover {
  background-color: #13c1f3;
}

.social-icons.social-icons-colored .social-forrst a,
.social-icons.social-icons-colored-hover .social-forrst a:hover {
  background-color: #45ad76;
}
.social-icons.social-icons-colored .social-email a,
.social-icons.social-icons-colored-hover .social-email a:hover {
  background-color: #238cc8;
}

.social-icons.social-icons-colored .social-linkedin a,
.social-icons.social-icons-colored-hover .social-linkedin a:hover {
  background-color: #238cc8;
}

.social-icons.social-icons-colored .social-wordpress a,
.social-icons.social-icons-colored-hover .social-wordpress a:hover {
  background-color: #2592c3;
}

.social-icons.social-icons-colored .social-grooveshark a,
.social-icons.social-icons-colored-hover .social-grooveshark a:hover {
  background-color: #ffb21d;
}

.social-icons.social-icons-colored .social-delicious a,
.social-icons.social-icons-colored-hover .social-delicious a:hover {
  background-color: #377bda;
}

.social-icons.social-icons-colored .social-behance a,
.social-icons.social-icons-colored-hover .social-behance a:hover {
  background-color: #1879fd;
}

.social-icons.social-icons-colored .social-dropbox a,
.social-icons.social-icons-colored-hover .social-dropbox a:hover {
  background-color: #17a3eb;
}

.social-icons.social-icons-colored .social-soundcloud a,
.social-icons.social-icons-colored-hover .social-soundcloud a:hover {
  background-color: #ff7e30;
}

.social-icons.social-icons-colored .social-deviantart a,
.social-icons.social-icons-colored-hover .social-deviantart a:hover {
  background-color: #6a8a7b;
}

.social-icons.social-icons-colored .social-yahoo a,
.social-icons.social-icons-colored-hover .social-yahoo a:hover {
  background-color: #ab47ac;
}

.social-icons.social-icons-colored .social-flickr a,
.social-icons.social-icons-colored-hover .social-flickr a:hover {
  background-color: #ff48a3;
}

.social-icons.social-icons-colored .social-digg a,
.social-icons.social-icons-colored-hover .social-digg a:hover {
  background-color: #75788d;
}

.social-icons.social-icons-colored .social-blogger a,
.social-icons.social-icons-colored-hover .social-blogger a:hover {
  background-color: #ff9233;
}

.social-icons.social-icons-colored .social-tumblr a,
.social-icons.social-icons-colored-hover .social-tumblr a:hover {
  background-color: #426d9b;
}

.social-icons.social-icons-colored .social-quora a,
.social-icons.social-icons-colored-hover .social-quora a:hover {
  background-color: #ea3d23;
}

.social-icons.social-icons-colored .social-github a,
.social-icons.social-icons-colored-hover .social-github a:hover {
  background-color: #3f91cb;
}

.social-icons.social-icons-colored .social-amazon a,
.social-icons.social-icons-colored-hover .social-amazon a:hover {
  background-color: #ff8e2e;
}

.social-icons.social-icons-colored .social-xing a,
.social-icons.social-icons-colored-hover .social-xing a:hover {
  background-color: #1a8e8c;
}

.social-icons.social-icons-colored .social-wikipedia a,
.social-icons.social-icons-colored-hover .social-wikipedia a:hover {
  background-color: #b3b5b8;
}

.social-icons.social-icons-border li a {
  border: 1px solid #d7d7d7;
  background: 0 0;
  color: #333;
}

.dark .social-icons.social-icons-border li a {
  border: 1px solid #333 !important;
}

.dark .social-icons li a .social-icons.social-icons-dark li a {
  background: #888;
  color: #fff;
}

.social-icons.social-icons-light li a {
  background: #fff;
  color: #333;
  border: 1px solid #e2e9e1;
}

.social-icons.social-icons-rounded li a {
  border-radius: 50%;
}

.social-icons.social-icons-square li a {
  border-radius: 0;
}

.social-icons.social-icons-xs li a {
  height: 20px;
  width: 20px;
  line-height: 20px;
  font-size: 12px;
}

.social-icons.social-icons-sm li a {
  height: 30px;
  width: 30px;
  line-height: 30px;
  font-size: 13px;
}

.social-icons.social-icons-md li a {
  height: 38px;
  width: 38px;
  line-height: 38px;
  font-size: 16px;
}

.social-icons.social-icons-lg li a {
  height: 42px;
  width: 42px;
  line-height: 42px;
  font-size: 18px;
}

.social-icons.social-icons-xl li a {
  height: 48px;
  width: 48px;
  line-height: 48px;
  font-size: 18px;
}

.dark .social-icons:not(.social-icons-colored):not(.social-icons-colored-hover) li a:hover {
  background-color: #1f1f1f;
}

.social-icons li:hover i {
  -webkit-animation: toTopFromBottom .2s forwards;
  animation: toTopFromBottom .2s forwards;
}
.single-share img {
  width: 20px;
  opacity: 0.7;
}

.single-share img:hover {
  opacity: 1;
}
.text-grey-5,
.text-grey-5 a,
.text-hover-grey-5:hover {
  color: #a2a2a2 !important;
}
.align-botton{
    align-items: baseline;
}

.showproduct{
	transition: all 1.25s ease 0s;
	visibility: hidden;
}

.hideproduct{
	transition: all 1.25s ease 0s;
	visibility: visible;
}

.footer-module-body{
	line-height: 1rem;
	margin-top:0.5rem;
}

.footer-module-body li {
	margin-bottom: 5px;
}
.footer-module-body > li .link-text {
	cursor:pointer;
}
.footer-module-body > li .link-text:hover {
	text-decoration:underline;
}

.link-image > img:hover  {
	opacity:1 !important;
}

.footer-module-body li a span{
	font-size: 12px;
}
.short-desc{
	border-top: 1px solid #696158 ;
}

.product_sort_info {
	border-top: 1px solid #696158 ;
}

.card-combo {
	border: 1px solid #f95078 ;
	color: #d50032;
	padding: 5px;
	border-radius: 10px;
}


@media all and (min-width:779px),print {
	
	/*
	.profile-widget:hover {
		box-shadow: 0px 0px 7px 0 rgba(0,0,0,.3) !important;
	}
	*/
	.customform .col-sm-6, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
		padding-right: 5px !important;
		padding-left: 5px !important;
	}
	
	.hide-web {
		visibility: hidden;
		display:none;
	}
	
	.shop-cart h5{
		position: absolute;
		left: 25px;
		top: 0px;
	}
	
	.shop-cart div{
		display: flex;
		justify-content: left
	}
	
	.shop-cart img {
		width:22px;
		margin-right: 6px;
	}
	
	.pagebuilder-column-1{
		width: 290px;
	}
	.grid-categories {
		display:grid !important;
		grid-template-columns:minmax(42.5%,1fr);
		grid-template-rows:repeat(5,auto);
		grid-gap:0px 20px;
		margin-top:46px;
		
	}
	.grid-categories .pagebuilder-column-group:nth-child(1) {
		grid-column:2/3;
		grid-row:1/2;
	}
	.grid-categories .pagebuilder-column-group:nth-child(2) {
		grid-column:1/2;
		grid-row:1/4;
	}
	.grid-categories .pagebuilder-column-group:nth-child(3) {
		grid-column:1/2;
		grid-row:4/5
	}/*
	.grid-categories .pagebuilder-column-group:nth-child(3) h3 {
		text-align:right
	}*/
	.grid-categories .pagebuilder-column-group:nth-child(4) {
		grid-column:2/3;
		grid-row:2/5
	}
	.grid-categories .pagebuilder-column-group:nth-child(5) {
		grid-column:2/3;
		grid-row:5/6
	}
	.grid-categories .pagebuilder-column-group figure {
		height:100%
	}
	.grid-categories .pagebuilder-column-group figure img {
		height:100% !important
	}
	.grid-categories [data-content-type=divider] {
		text-align:right
	}
	.pagebuilder-column {
	  box-sizing: border-box;
	}

	.grid-categories p {
		font-weight: 400;
		font-size: 15x;
		line-height: 20px;
		text-align: justify;
		margin-bottom: 0.5rem;
	}
}
.grid-categories img {
	max-width:100%;
	height:auto;
	border:0;
	width: 100%;
	object-fit: cover;
}

.grid-categories .right-txt-nc p {
	text-align:right;
	max-width:inherit
}

.categoryRoot {
	font-size: 14px;
	/*color: #696158;*/
	font-weight: 400;
	font-style: normal;
	text-transform : capitalize;
}

.categoryRoot span {
	font-weight: bold;
}

.banner-item {
	flex-direction: row !important;
	justify-content: space-between;
	height: 100px;
	
	display: flex;	
	flex-direction: column;
	background-color: #eae7e0;
	border-radius: 6px !important;
	padding: 7px;
	font-size: 13px;
	/*height: 100%;
	
	border-radius: 6px;
	background-color: #eae7e0;
	box-sizing: border-box;   
	line-height: 1;*/
}

/*mobile*/
@media all and (max-width:799px) {
	
	
	.btn-secondary-rigth-button {
		width: 99%;
		margin-right: 0px !important;
		margin-bottom: -10px !important;
	}

	.search1 {
		
		border-top-left-radius: 25px;
		border-bottom-left-radius: 0px !important;
		border-top-right-radius: 25px;
		border-bottom-right-radius: 0px !important;
	}
	
	.search2 {
		border-top-left-radius: 0px !important;
		border-bottom-left-radius: 25px;
		border-top-right-radius: 0px !important;
		border-bottom-right-radius: 25px;
	}

	.download-link{
		margin-bottom: "auto";
		position : absolute;
		bottom: -15px;
	}

	.event-image{
		/*
		max-height: 350px !important;
		height: 350px !important;
		*/
		width: 100% !important;
		height: auto !important;
	  

		/*max-height: auto !important;*/
		margin-top: 0.25rem !important;
		/*min-width: 370px !important;*/
	}

	.modal-v2-container-body-lg {

		top: calc(50% - 90px) !important;	
		left: calc(50% - 185px)!important;
		max-width: 370px !important;	
		width: 90%;
		min-width: 370px !important;	
	}
	
	.modal-v2-container-body-lg-top {

		top: 35px !important;	
		left: calc(50% - 185px)!important;
		max-width: 370px !important;	
		width: 90%;
		min-width: 370px !important;	
	}
	
	.cart-product-name {
		max-width:330px !important; 
	}

	.profile-widget .comment-btn-container {
		top: 48% !important;
	}
	
	.modal-comment-container {
		top: 48% !important;
	}
	
	.btn-xs, .btn-group-xs > .btn {
	  padding: 0.1rem 0.25rem 0rem 0.25rem !important;
	  font-size: 0.65rem !important;
	  line-height: 1.5 !important;
	}

	.close-button{
		
		top: -15px !important;
		right: 10px !important;
		height: 15px !important;
		float: right;
	}
	.blog-preview-container{
		width:100% !important;
	}
	.zoomable-tools{
		display:none !important;
		visibility: hidden !important;
	}
	.share-url-container{
		border-radius: 6px !important;
		/*width:99% !important;*/
		margin-left: 0px !important;
		color: #d50032 !important;
	}

	.divider-container{
		width:99% !important;
	}

	.divider-container-2{
		width:99% !important;
	}

	.mini-cart-icon {
		margin-top: 0px !important;
		/*margin-right: -14px !important;*/
		padding: 0 0 0 6px !important;
		/*padding-right: 9px !important;*/
	}
	.login-icon{
		margin-right: -14px !important;
	}
	.mini-cart-icon > span.pro-count {
	 
 		height: 17px !important;
		width: 17px !important;
		font-size: 11px !important;
		top: -6px !important;
		line-height: 17px !important;
	}

	.sui-layout-header{
		margin-top: -30px;
	}
 	
 	.search-container-image {
		width: 100% !important;
		height: 100% !important;
		margin: 0px !important;
	}
 
	.search-container-col-prod-2:hover{
		background:#fff !important;
	}

	.hidePass{
		top: 1.85rem !important
	}
	table .product-thumbnail  {
		text-align: center !important;
  }

	.table thead tr th {
		font-weight: 600;
		border: 0;
		text-align: left;
	}
	
	.col-text-quantity{
		justify-content: right !important;
		align-items:right !important;
	}
	
	.col-text-right{
		text-align: right !important;
	}
	.col-text-center{
		text-align: center !important;
	}
	.table tbody tr td {
		text-align: left;
	}
	
	.accordion__button .icon {
		visibility: hidden !important;
		opacity:0 !important;
	}
	.accordion__button .title {
		font-size: 16px !important;
	}
	.box-bottom-container{ 
		left: 10px !important;
		right: 10px !important;
	}

	.box-bottom-container-2{
		left: 10px !important;
		right: 10px !important; 
	}
	 
	.box-bottom-container .label .content span {
		font-size: 16px !important;
		margin-top: 12px !important;
		/*text-align: left !important;*/
	}
	
	.box-bottom-container .label .content div {
		text-align: left !important;
	}
	
	.box-bottom-container .label .content {
 		
	  display: flex !important; 
	  padding-top: 0px !important;
		padding-bottom: 10px !important;
		padding-left: 5px !important;
	} 
	.box-bottom-container .label .content button {
		padding: 0.25rem 0.5rem !important;
	  font-size: 0.875rem !important;
	  line-height: 1.5 !important;
	  border-radius: 0.2rem !important;
	}
	.box-bottom-container .label { 
	  min-height: auto !important;
	} 
 .box-bottom-container-2 .label .content {
	  padding-top: 0px !important;
		padding-bottom: 10px !important;
		padding-left: 5px !important;
	}
	
	.box-bottom-container-2 .label .content button {
		padding: 0.25rem 0.5rem !important;
	  font-size: 0.875rem !important;
	  line-height: 1.5 !important;
	  border-radius: 0.2rem !important;
	}
	.box-bottom-container-2 .label { 
	  min-height: auto !important;
	}
	

	.modal-dialog {
  	max-width: 100% !important;
  }
	.fullheigth-modal  {
		margin:0 !important;
		display: flex;
		align-items: top !important;
	}

	.detail-add-button-mini{
		min-width: 50px !important;
	}
	.grid-categories p {
		text-align: justify !important;
	}
	
	.detail-qty-button-mini {
		border-radius: 0px !important;	  
		min-width: 52px !important;
	}
	
	.header-action-icon-2 .user-info {
    opacity: 0 !important;
    visibility: hidden !important;
/*    transform: translate3d(100%, 0, 0) !important;*/
    position: fixed !important;
    top: auto !important;
    margin-top: 24px !important;
	}
	.header-action-icon-2 .user-info.show {
    opacity: 1 !important;
    visibility: visible !important;
    /*
    -webkit-transform: translate3d(0, 0, 0) !important;
    -ms-transform: translate3d(0, 0, 0)!important;
    transform: translate3d(0, 0, 0)!important; 
    */
  	overflow: visible!important;;
		z-index: 1060 !important;
		position: fixed !important;
		right: 16px !important;
		margin-top: 24px !important;
	}
	/*
	.profile-widget .option {
		visibility: visible !important;
		display: initial !important;
		opacity: 1 !important;
	}
	*/
	
	.mobile-showCart {	
		position: fixed;
	  top: 0;
	  /*width: 360px;*/
	  width: 0px;
	  min-height: 100vh;
	  bottom: 0;
	  right: 0;
	  visibility: hidden;
	  opacity: 0;
	   
	  background-color: #ffffff;
	  
	  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
	  z-index: 1024;
	  
	}
	
	
	.mobile-showFilters {	
 
	  bottom: 0;
	  right: 0;
	  visibility: hidden;
	  opacity: 0; 
	  background-color: #ffffff; 
	  height:0px;
	  padding: 10px;
	
		transition: all 0.4s ease;
		-moz-transition: all 0.4s ease;
		-o-transition: all 0.4s ease;
		-ms-transition: all 0.4s ease;
		-webkit-transition: all 0.4s ease;
	}
	.mobile-showFilters.visible {
	  visibility: visible;
	  opacity: 1;
	  width: 100%;
	  height: auto;
	  /*-webkit-transform: translate(0, 0);
	  transform: translate(0, 0);*/
	  /*width: calc(100% - 54px);*/
	}
	
.mobile-showCart.visible {
  visibility: visible;
  opacity: 1;
  /*-webkit-transform: translate(0, 0);
  transform: translate(0, 0);*/
  width: calc(100% - 54px);
}
	
	.searcher-mobile {
		
		position: absolute;
		z-index: 1061;
		width: 100%;
		height:100%;
		background:#eee;
	}
	
	.searcher-mobile-results {
	
		background:#eee;
	}
	
	.searcher-mobile input {
		border:0px;
		font-size: 16px;
	}
	
	.searcher-mobile .searcher-mobile-button {
		border:0px;
		font-size: 15px;
		background:#fff;
		font-size: 15px;
	}
	
	.searcher-mobile .row-top {
		margin:10px;
		background:#fff;
		padding: 10px;
	}
	.searcher-mobile .options {
		margin:10px;
		background:#eee !important;
		padding: 0px;
		font-weight:bold;	
	}
	 
	.searcher-mobile .options button{
		background:#eee !important;
		font-weight:bold;	
	}
	
	.searcher-mobile .list-content{
		 
		margin: 0px -10px 0px -10px;
	}
	
	.searcher-mobile .list-content .col-prod{
		background:#fff;
		width: 50% !important;
		padding-right: 15px;
		padding-left: 15px;
		/*adding: 10px 15px 15px 10px;*/
		max-height:243px;
		border: 1px solid #eee;
		padding-bottom: 10px;
	padding-top: 10px;
	}
/*
	.searcher-mobile .list-content.col-prod:hover{
		background:#f0f0f0;
	}
	.searcher-mobile .list-content.col-prod .image{
		margin: 12px 0 12px 0 !important;
		display: flex;
		justify-content: center;
	}
	*/
	.searcher-mobile .list-content .col-prod .price{
		text-align: center;
	}
	
	.searcher-mobile .list-content .col-prod .info{
		text-align: center;
	}
	
	.price-slider {
  position: relative;
  width: 95% !important;
  height: 50px;
}
	
	.mobile-col-cat{
		background-color: #fafafa;
		border-right: 1px solid #eee;
		position: relative;
		padding: 12px 0 0;
		flex-wrap: nowrap;
		background: #fafafa;
	}
	.mobile-col-cat .col-cat-content{
	
	padding: 0 0 12px;
	border: 0px solid #fff;
	background: #fafafa;
	}
	
	.mobile-col-cat .col-cat-content button{
	 	color: #696158;
		font-size: 15px;
		padding: 0.25rem !important;
		background: #fafafa;
	}


	.mobile-col-cat .col-cat-content .categories{
		line-height: 2rem;
		border:0px #fafafa solid ;
		background: #fafafa;
	}



	.mobile-col-cat .col-cat-content .right {
		width: 81%;
		display: inline-block;	
		text-align: left;
	}

	.mobile-col-cat .col-cat-content .right:hover {
		text-decoration:underline
	}

	.mobile-col-cat .col-cat-content .left {
		width: 21%;
		display: inline;	
		cursor: pointer;
	}

	.mobile-col-cat .col-cat-content .categories >li{
	  margin-left: 0rem;
	  margin-bottom: 0rem;
	  border:0px;
	  background: #fafafa;
	}
	 
	.mobile-col-cat .col-cat-content .categories >li:hover{
		border-radius: 4px;
	background: #fafafa;
		border-color: #eee;
		text-decoration: none;
	}
	.mobile-col-cat .col-cat-content .categories >li:hover button{
	 	color: #696158 !important
	}

	.mobile-col-cat .col-cat-content .categories .active{
		background-color: #d50032;
		border-color: #d50032;
		border-radius: 4px;	
		text-decoration: none; 
	}

	.mobile-col-cat .col-cat-content .categories .active button{
		color: #fff !important;
		background: #d50032 !important;
	}

	.mobile-col-cat .col-cat-content .categories .name {
		width: 85%;
		display: inline-block;	
		text-align: left;
	}

	.mobile-col-cat .col-cat-content .categories .quantity {
		width: 15%;
		display: inline-block;	
		text-align: right;
	}
	
	.mobile-col-cat .col-cat-content .categories .title-name {
		width: 85%;
		display: inline-block;	
		text-align: left;
		font-size: 15px;
		font-weight:bold;
	}

	.mobile-col-cat .col-cat-content .categories .title-button {
		width: 15%;
		display: inline-block;	
		text-align: right;
		font-size: 15px;
		font-weight:bold;
	}

	.mobile-col-cat .col-list-content{
		overflow-x: hidden;
		overflow-y: auto;
		position: absolute;
		top: 2px;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 0 0 32px;
		border: 0 solid #eee;
	}
 	.thumb{
 		 
	  width: 95% !important; 
 	}
 	.filter-background{
 		background-color: #eee;
 		z-index:1065;
 		border-radius:4px;
 	}
	.search-style-2 {
	  width: 100%;
	  margin-top: 18px;
	}

	.search-style-2 form {
	  width: 100%;
	  display: -webkit-box;
	  display: -ms-flexbox;
	  display: flex;
	  position: relative;
	  justify-content: left;
	}
	
	.search-style-2 form input {
	  width: 100%;
	  max-width: 100%;
	  border-radius: 0;
	  background-color: #fff;
	  /*border-color: #414648;*/
	  border-width: 0 1px 1px 0;
	  border-style: solid;
	  font-size: 14px;
	 	background-image: url(../images/ico_search.png); 	
	  background-repeat: no-repeat;
	  background-position:  right;
	  background-position-x:  right;
	  padding-left: 5px !important;
	  /*font-style: italic;*/
	  border-color: #696158;
	  padding-right: 18px;
	  height: 29px;
		margin-top: 6px;
	}
	
	
	
	.banner-image{
		background: transparent !important;
		border: 0px 
	}
	
	.banner-image img{
	  margin-left: -1px !important;
		width: 101px !important;
		height: 101px !important;
		/*object-fit: cover;*/
	}
	.adjust-image{
		margin-left: 1px !important;
		margin-right: 1px !important;
		width:100% ;
	}
		
	.message-top button {
		/*
    margin-top: -3px !important;
    margin-right: 7px !important;
    */
	 	margin: 0 !important;
		/*CHECK!!!*/
	  /*position: absolute;*/
	  top: 0px;
	  right: 3px;

	}


	.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
 
    padding-right: 5px !important;
    padding-left: 5px !important;
	}
	
	.order-status-header .resume-info{
		text-align: left !important;
		margin-top: 20px !important;
	}
	

	
	.order-status-container {
    margin: 10px 0px 10px 0px !important;
    flex-wrap: wrap  !important;
		margin-top: 20px !important;
	}
	.step-order-status {
		height: auto !important;
		width: 50% !important;
		margin-top: 15px;
		margin-bottom: 53px;
		/*background: #f0f0f0 !important;*/
		border-radius: 3px  !important;;
	}
	.step-order-status div {
		width:100%;
		height: 14px !important;;
	  margin: 2px 0 2px 0 !important;
		background: #c8c8c8 !important;
		border-bottom: 0px  !important;
		border-radius: 6px  !important;;
		padding:0px;
		margin: 1px !important; 
	}
	.step-order-status .active {
		background: #4ca480 !important;
	}
	.step-order-status img, .step-order-status span{
		width:100%;
		/*visibility: hidden;
		display:none;*/
		
	}
	
	.order-status .product-detail{
		margin:10px 15px 0 15px !important;
		display:block !important;
		border-radius:15px !important;
	}
	.order-status .product-detail .image{
		width:100% !important;
	}
 
	.order-status .product-detail .detail{

		width:100% !important;
	 	padding: 0 15px 0 15px;
	}
	
	.order-status .product-detail:last-child {
    margin-bottom: 10px !important;
    border-radius:15px !important;
	}

	.shipping-form-btn {
    display: block;
    width: 110px
	}
	
	
	.hide-mobile {
		visibility: hidden;
		display:none;
	}
	.card-user-info .menu-info .active {
    
    margin: 7px 20px 7px 20px !important;
    font-size: 20px !important;
	}
	
	.card-user-info .menu-content  {
		padding: 3px 0 0 20px !important
	}
	
	.card-user-info .menu-info ul {
    margin: 7px 0 0 17px !important
	}
	
	.card-user-info .menu-info ul .or-line {
    margin: 0px 22px 0 4px !important
	}
	
	.shop-cart div{
		display: flex;
		justify-content: center
	}
	
	 
	
	.profile-widget:hover .product-action{
	 
    -webkit-transform:  none !important;
    -ms-transform:  none !important;
    transform: none !important;
	}
 
 
	.towishlist {
    content: "";
/*    width: 46px;*/
/*    height: 28px;*/
    position: initial !important;
    cursor: pointer;
    /*margin: 5px 0px 0px 3px;*/
}
	
	.profile-widget:hover {
		box-shadow: 0px 0px 0px 0 #fff !important;
	}
	
	.doc-img:hover img {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1);
	}
	
	.doc-img img {
	  
	  max-height: 150px !important;
	}
	
	.product-price {
    font-weight: 700;
    color: #d50032;
    font-size: 13px !important;
	}
	
	.product-price-new{
		font-weight: 700;
		color:#d50032;
		font-size: 13px !important;
	}
	.product-price-old {
		font-size: 13px !important;
	}
	.shop-cart h5{
		margin-top: 26px;
		font-size:1.1rem;
		text-align:center;
		
	}
	
	.shop-cart-h6{
		text-align:center;
		margin-top: 5px;
	}
	.shop-cart img {
		width:33px;
	}
	
	.header-wrap.header-space-between {
	  -webkit-box-pack: justify;
	  -ms-flex-pack: justify;
	  justify-content:  left;
	}

	.banner-item {
		width: 100%;
		height: 176px;
		flex-direction: column !important;
	}
	
	.content_slider {
		font-size: 0.7rem;
	}
	.banner-description{
		padding: 15px 0;	
	}
}

.banner-item a span:hover {
	color: #696158 !important;
	/*text-decoration: underline !important;*/
}

.content_slider {
	flex: calc(95% - 80px) 0 1;
	width: 100%;
	position: relative;
	top: unset;
	left: unset;
	align-self: center;
	line-height: 1.1;
}
.banner-image img{
	width: 73px;
	margin-top: 10px;
	object-fit: cover;
	margin-left: 10px;
}

.content_slider a span {	 
	letter-spacing: 0px !important; 
	
}

.owl-item {
	width:100%;
}

.subcategory-menu a{
	letter-spacing: 0px !important;
}

.subcategory-menu span{
	letter-spacing: 0px !important;
	font-family:
	-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
}

.subcategory-menu-list {

	width: 340px;
	position: absolute;
	background: #fff;
	left: 1.5rem;
	/*padding-left: 25px;
	padding-right: 25px;*/
	box-sizing: border-box;
	z-index: 101;
	/*height: 100%;*/
	top: 25px;
	line-height: 1.8;
	opacity: 1;
	transition: all ease-in-out .2s;
	display: block;
/*border : 1px solid red*/
}
.pointer {
	cursor: pointer;	
}
.col-2_5{
	flex: 0 0 20%;
	max-width: 20%;
}

.col-3_5{
	flex: 0 0 30%;
	max-width: 30%;
}

.col-8_5{
	flex: 0 0 70%;
	max-width: 70%;
}


/*Pagination*/

.pagination-area {
	display: flex;
  padding-left: 0;
  list-style: none;
}

.pagination-area .page-item {
  margin: 0 3px;
}

.pagination-area .page-item:first-child {
  margin-left: 0px;
}

.pagination-area .page-item:first-child .page-link {
 	border: 1px #fff solid;
 	color: #696969 !important;
	background: #fff !important; 
}

.pagination-area .page-item:last-child {
  margin-right: 0px;
  
}

.pagination-area .page-item:last-child .page-link {
 	border: 1px #fff solid;
 	color: #696969 !important;
	background: #fff !important; 
	
}


 

.pagination-area .icon-item {
  margin-right: 0px;
  margin-top: 6px;
  cursor:pointer;
}

.pagination-area .icon-item.icon-link {
 	border: 1px #fff solid;
 	color: #696969 !important;
	background: #fff !important; 
	
}



.pagination-area .page-item:last-child .page-link {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.pagination-area .page-item.active .page-link, .pagination-area .page-item:hover .page-link {
  color: #fff;
  background: #d50032; 
}

.pagination-area .page-link {
  border: 1px #696158 solid;
  padding: 0 6px;
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: 0;
  width: 26px;
  height: 26px;
  display: block;
  /*border-radius: 4px;*/
  color: #696969;
  line-height: 25px;
  text-align: center;
  /*font-weight: 700;*/
  border-radius: 17px;
  /*opacity: .9;*/
  cursor:pointer
}

.pagination-area .page-link.dot {
  background-color: transparent;
  color: #4f5d77;
  letter-spacing: 2px;
}

.pagination-area p {
  margin: 10px 0 0 10px;
  color: #8e8e90;
}

.align-items-right {
	justify-content: right;	
}

.pagination-select {
	background: white;
	border: 0.1em solid #696158;
	padding: 7px;
	font-size: 12px;
	z-index: 999;
}
.pagination-select :hover {
	border: 0.1em solid red; 
}

.pagination-search-input {
	background: white;
	border: 0;
	border-bottom: 1px solid #696158;
	padding: 7px;
	font-size: 16px;
	z-index: 2;
	width:75%;
}

.pagination-select :hover {
	border: 0.1em solid red; 
}

.shopCart-title {
	background-image: url('../images/ico_checkout_cart.svg');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	width: 33px;
	height: 30px;
	display: block;
}

.shopping-summery tbody tr img {
  border-radius: 0;
  max-width: 100px;
}

.main-heading {
	background-color : #fff;
	line-height: 2;
}

.main-heading th {
	/*border-top: 1px solid #fff !important;*/
}


table.clean td, table.clean th {
  border: 0;
  border-top: 1px solid #e2e9e1;
  vertical-align: middle !important;
}

table .product-thumbnail img {
  max-width: 120px;
}
.background-resume-order {
	background-color: #f6f4f2;
	border-radius: 10px;
	line-height: 2;
	padding: 19px;
	font-size:15px;
}

.background-resume-order-2 {
	background-color: #f6f4f2;
	border-radius: 10px;
	line-height: 1.5;
	padding: 19px;
	font-size:13px;
}

.resume-order-title{
	font-weight: bold;
	color: #696158;
}
.text-bold{
	font-weight: bold;
	font-family:"Roboto"
}

.add-more-products-link{
	color: #696158;
	text-decoration: underline;
	font-size:15px;
	font-weight: bold;
}
.short-label{
	color: #696158;
	text-decoration: underline;
	font-size:19px;
}
.medium-label{
	color: #696158;
	text-decoration: underline;
	font-size:150%;
}


.header-action-icon-2 .showCart {
	position: absolute;
	/*width: 365px;*/
	/*right: 0;*/
 background:#fff;
	top: 32px;
	z-index: 1050; 
box-shadow: 0 0 7px 0 rgba(0,0,0,0.5) !important;
border-radius: 6px;
/*	 -webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
	transform: translate3d(100%, 0, 0);*/
    opacity: 0;
    visibility: hidden;
	margin-top:5px;
	font-size:11px;

	
}

.showCart-active {
	z-index: 1062;	
	position: absolute;
	/*right: 0px;*/
	background:#fff;
/*	top: 32px;*/
	/*z-index: 125;*/
/*	box-shadow: 0 0 7px 0 rgba(0,0,0,0.5) !important;*/
	border-radius: 6px;
	font-size:11px;
	opacity: 1;
	visibility: visible;
	/*-webkit-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);*/
	-webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
	transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
	/*margin-top: 5px;*/
	width: 365px;
	right: 0;
	top: 32px;
	
}

.showCart .card {
	margin-bottom:0px;
}	
	

.showCart img {
	width:50px
}
.showCart button {
	font-size:11px
}
.flex {
	display:flex
}


@media only screen and (max-width: 779px) {
	
	
	.product-extra-link2 {
		display: flex !important;
		justify-content: center !important;
		margin-top: 12px !important;;
		width: 100% !important;;
		margin-left : 0px !important;;
	}
	
	
	.product-extra-link2 button{
	/*	border-radius: 0 3px 3px 0 !important; */
	/*	height: 30px;	 */
		width: 50%;
	}
	
	.product-extra-link2-mini {
		display: flex !important;
		justify-content: center !important;
		margin-top: 3px !important;
		width: auto !important;
		margin-left : 0px !important;;
	}
	
	 
	
	.detail-extralink-container {
    display: block !important;
    width: 100% !important;
	}
	
	.detail-extralink {
    display: flex !important;
  	justify-content:left !important;
    width: 100% !important;
	}
	/*
	.detail-extralink .detail-qty {
    max-width: 30% !important;
    width: 30% !important;
  }
  
  .detail-extralink button {
    width: 70% !important;
  }
  */ 
	
	.header-action-2 .header-action-icon-2 > a span.pro-count {
		height: 17px;
		width: 17px;
		font-size: 11px;
		top: -6px;
		line-height: 17px;
	}
  .logo.logo-width-1 {
    margin-right: 0;
    width: calc(100% - 54px);
		display: flex !important;
		justify-content: center;
  }
  .logo.logo-width-1 a img {
    width: 164px;
  }
  .header-action .header-action-icon {
    margin-right: 15px;
  }
  .header-action .header-action-icon.header-action-mrg-none {
    margin-right: 15px;
  }
  .header-action .header-action-icon.header-action-mrg-none2 {
    margin-right: 15px;
  }
  .header-action .header-action-icon > a {
    font-size: 20px;
  }
  .header-action-2 .header-action-icon-2 {
    padding: 0 6px;
  }
  .header-action-2 .header-action-icon-2:last-child {
    padding: 0 0 0 6px;
  }
  .header-action-2 .header-action-icon-2 > a {
    color: #fff;
    fill: #fff;
    font-size: 22px;
    /*margin-right: 10px;*/
  }
  .header-style-5 .logo.logo-width-1 {
    margin-right: 0;
  }
  .header-style-5 .search-style-2 {
    margin-right: 30px;
  }
  .header-style-5 .header-bottom {
    padding: 5px 0;
  }
  .cart-dropdown-wrap {
    width: 290px;
    right: -39px;
  }
  .cart-dropdown-wrap.cart-dropdown-hm2 {
    right: -63px;
  }
  .cart-dropdown-wrap ul li .shopping-cart-img {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60px;
    flex: 0 0 60px;
    margin-right: 10px;
  }
  .cart-dropdown-wrap .shopping-cart-footer .shopping-cart-button a {
    padding: 12px 22px 13px;
  }
  .categori-dropdown-wrap ul li a {
    padding: 5px 12px;
  }
  .search-popup-wrap .search-popup-content form input {
    width: 270px;
    font-size: 25px;
    padding: 21px 20px 12px 0;
  }
  .mobile-header-wrapper-style {
    /*width: 320px;*/
  }
  .mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-top {
    padding: 10px 20px;
    /*border-bottom: 1px solid #e2e9e1;*/
  }
  .mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-top .mobile-header-logo a img {
    width: 80px;
  }
  .mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area {
    padding: 30px 20px;
  }
  .header-height-1 {
    min-height: 65px;
  }
  .header-height-2 {
    min-height: 65px;
  }
  .hero-slider-1 {
    height: 350px;
  }
  .hero-slider-1 .single-slider-img-1 {
    height: 350px;
  }
  .home-slider.pt-50 {
    padding-top: 0 !important;
  }
  .hero-slider-content-2 h1 {
    font-size: 32px;
    line-height: 1.2;
  }
  .hero-slider-content-2 h2 {
    font-size: 30px;
    line-height: 1.2;
    margin-bottom: 10px;
  }
  .hero-slider-content-2 h4 {
    font-size: 16px;
    margin: 0 0 10px;
  }
  .hero-slider-content-2 .btn-default {
    color: #fff;
    background-color: #088178;
    border-radius: 50px;
    padding: 10px 22px;
    font-size: 14px;
  }
  .hero-slider-content-2 p {
    font-size: 14px;
    line-height: 1.3;
  }
  .banner-big h4 {
    font-size: 12px;
  }
  .banner-big h2 {
    font-size: 16px;
  }
  .banner-big .btn {
    background-color: #088178;
    color: #fff;
    border-radius: 3px;
    font-size: 13px;
    padding: 10px 22px;
    border: 0;
  }
  .home-slider .slider-arrow {
    display: none;
  }
  .home-slider .hero-slider-1.style-2 {
    height: 400px;
  }
  .home-slider .hero-slider-1.style-2 .hero-slider-content-2 {
    padding-left: 0;
    padding-top: 100px;
  }
  .home-slider .hero-slider-1.style-2 .single-slider-img img {
    max-width: 400px;
  }
  .home-slider .hero-slider-1.style-2 .slider-1-height-2 {
    height: 400px;
    position: relative;
  }
  .banner-img {
    float: left;
  }
  .banner-img.f-none {
    float: none;
  }
  .hero-slider-1.style-3 .slider-1-height-3 {
    height: 390px;
  }
  .hero-slider-1.style-3 .hero-slider-content-2 {
    padding-left: 20px;
  }
  .home-slide-cover .hero-slider-1.style-4 .hero-slider-content-2 {
    padding-left: 0;
  }
  .home-slide-cover .hero-slider-1.style-4 .hero-slider-content-2 h1 {
    font-size: 30px;
  }
  .home-slide-cover .hero-slider-1.style-4 .hero-slider-content-2 h2 {
    font-size: 14px;
  }
  .home-slide-cover .hero-slider-1.style-4 .hero-slider-content-2 h4 {
    font-size: 12px;
  }
  .header-style-3 .header-bottom-bg-color {
    border-bottom: 2px solid #088178;
  }
  .header-style-3 .main-nav {
    border: none !important;
  }
  .header-style-3 .sticky-bar.stick.sticky-blue-bg {
    background-color: #088178;
  }
  .header-style-4 .main-nav {
    border: none !important;
  }
  .header-style-4 .sticky-bar.stick.sticky-blue-bg {
    background-color: #088178;
  }
  .font-xxl {
    font-size: 38px;
  }
  .single-page.pl-30, .single-page.pr-30 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .download-app {
    margin-bottom: 0 !important;
  }
}



.burger-icon {
  position: relative;
  width: 24px;
  height: 20px;
  cursor: pointer;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.burger-icon > span {
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
}

.burger-icon > span.burger-icon-top {
  top: 2px;
}

.burger-icon > span.burger-icon-bottom {
  bottom: 2px;
}

.burger-icon > span.burger-icon-mid {
  top: 9px;
}

.burger-icon > span::before,
.burger-icon > span::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #333;
}

.burger-icon.burger-icon-white > span::before,
.burger-icon.burger-icon-white > span::after {
  background-color: #696158;
}



/*Tablet*/
@media only screen and (min-width: 779px) and (max-width: 991px) {
  .header-action .header-action-icon {
    margin-right: 33px;
  }
  .header-action .header-action-icon.header-action-mrg-none {
    margin-right: 33px;
  }
  .header-action .header-action-icon.header-action-mrg-none2 {
    margin-right: 33px;
  }
  .header-action-2 .header-action-icon-2 > a {
    color: #fff;
    fill: #fff;
  }
  .search-popup-wrap .search-popup-content form input {
    width: 650px;
    font-size: 35px;
  }
  .header-bottom {
    padding: 10px 0 3px 0;
    overflow: hidden;
  }
  .header-height-1 {
    min-height: 50px;
  }
  .header-height-2 {
    min-height: 50px;
    
  }
  .header-height-3 {
    min-height: 50px;
  }
  .header-height-4 {
    min-height: 50px;
  }
  .header-style-5 .header-bottom {
    padding: 5px 0;
    border-bottom: 1px solid #f7f8f9;
  }
  .comment-form .email {
    padding-left: 0px;
  }
  .loop-grid.pr-30 {
    padding-right: 0 !important;
  }
}

@media only screen and (min-width: 576px) and (max-width: 799px) {
  .header-action .header-action-icon {
    margin-right: 20px;
  }
  .header-action .header-action-icon.header-action-mrg-none {
    margin-right: 20px;
  }
  .header-action .header-action-icon.header-action-mrg-none2 {
    margin-right: 20px;
  }
  .header-action .header-action-icon > a {
    font-size: 22px;
  }
  .header-action-2 .header-action-icon-2 {
    padding: 0 10px;
  }
  .header-action-2 .header-action-icon-2:last-child {
    padding: 0 0 0 10px;
  }
  .header-action-2 .header-action-icon-2 > a {
    font-size: 22px;
  }
  .cart-dropdown-wrap {
    width: 310px;
  }
  .search-popup-wrap .search-popup-content form input {
    width: 480px;
    font-size: 25px;
    padding: 21px 20px 12px 0;
  }
  .comment-form .name {
    padding-right: 0px;
    margin-bottom: 1rem;
  }
  .header-style-5 .search-style-2 {
    display: none;
  }
}
/*small phone*/
@media only screen and (max-width: 480px) {
  .post-list .post-thumb {
    max-width: unset;
    margin-right: 0 !important;
  }
  .entry-meta.meta-2 a.btn {
    display: inline-block;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }
  .entry-meta.meta-2 .font-xs {
    max-width: 150px;
    line-height: 1.3;
  }
  .banner-bg h2 {
    font-size: 20px;
  }
  .single-header .single-header-meta, .entry-bottom {
    display: block;
  }
  .carausel-6-columns, .carausel-4-columns {
    max-width: 375px;
    overflow: hidden;
  }
  .slider-arrow .slider-btn.slider-next {
    right: 0 !important;
  }
  .hero-slider-1 {
    height: unset;
  }
  .hero-slider-content-2 {
    text-align: center;
    padding-top: 20px;
  }
  .hero-slider-content-2 p {
    width: 100%;
  }
  .header-height-2 {
    min-height: 40px;
  }
  .header-bottom-bg-color {
    padding: 10px 0 3px 0;
  }
  .banner-left-icon, .banner-img {
    margin-bottom: 15px;
  }
  .popular-categories .slider-btn.slider-prev {
    right: 50px !important;
    left: unset !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-4 {
    margin-bottom: 1rem;
  }
  .mb-sm-5 {
    margin-bottom: 2rem;
  }
  .heading-tab {
    display: block !important;
  }
  .heading-tab h3.section-title {
    margin-bottom: 15px !important;
  }
  .nav.right {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
  }
  .hero-slider-1.style-2 .single-slider-img {
    display: none;
  }
  ul.footer-list {
    margin-bottom: 30px;
  }
  .section-padding-60 {
    padding: 30px 0;
  }
  .pt-60, .pt-40 {
    padding-top: 30px !important;
  }
  .pb-60 {
    padding-bottom: 30px !important;
  }
  .mb-60 {
    margin-bottom: 30px !important;
  }
  .hero-slider-1.style-3 .slider-1-height-3 {
    height: 220px;
  }
  .hero-slider-1.style-3 .hero-slider-content-2 {
    position: relative;
    text-align: left;
    padding-left: 15px;
    padding-top: 0;
  }
  .hero-slider-1.style-3 .hero-slider-content-2 h1 {
    font-size: 18px;
  }
  .hero-slider-1.style-3 .hero-slider-content-2 h2 {
    font-size: 16px;
  }
  .hero-slider-1.style-3 .hero-slider-content-2 h4 {
    font-size: 14px;
  }
  .hero-slider-1.style-3 .hero-slider-content-2 p {
    font-size: 11px;
  }
  .hero-slider-1.style-3 .hero-slider-content-2 a.btn {
    display: none;
  }
  .header-style-5 .search-style-2 {
    display: none;
  }
  .header-style-5 .header-action-2 .header-action-icon-2 > a {
    font-size: 18px;
  }
  .header-style-5 .sticky-bar.stick.sticky-white-bg {
    background-color: #fff;
    padding: 15px 0;
  }
  .font-xxl {
    font-size: 28px;
  }
  .w-50 {
    width: 100% !important;
  }
  .product-list .product-cart-wrap {
    display: block;
  }
  .product-list .product-cart-wrap .product-img-action-wrap {
    max-width: 100%;
  }
  .shop-product-fillter {
    display: block;
  }
  .shop-product-fillter .totall-product {
    margin-bottom: 15px;
  }
  .table td {
    display: block;
    width: 100%;
    text-align: right;
  }
  .table td::before {
    content: attr(data-title) " ";
    float: left;
    text-transform: capitalize;
    margin-right: 15px;
    font-weight: bold;
  }
  .table thead {
    display: none;
  }
  .loop-grid.pr-30 {
    padding-right: 0 !important;
  }
  .loop-grid.loop-list article {
    display: block;
  }
  .d-sm-none {
    display: none;
  }
  .banner-features {
    margin-bottom: 15px;
  }
  .product-cart-wrap:not(:last-child), .mb-xs-30 {
    margin-bottom: 30px !important;
  }
  .slick-track .product-cart-wrap {
    margin-bottom: 0 !important;
  }
  .header-bottom {
    overflow: hidden;
  }
  .first-post .meta-1 .font-sm {
    display: none;
  }
  .first-post .btn.btn-sm {
    display: none;
  }
  .loop-grid .entry-content {
    padding: 20px 20px 0 20px;
  }
  .img-hover-slide {
    min-height: 232px;
  }
  .comments-area .thumb {
    min-width: 100px;
  }
  .hero-slider-1 .single-slider-img-1 {
    height: 300px;
  }
  .featured .col-lg-2 {
    width: 50%;
  }
  .nav-tabs .nav-link {
    font-size: 13px;
    padding: 10px 12px;
  }
  .deal {
    background-position: left center;
  }
  .deals-countdown .countdown-section .countdown-amount {
    width: 30px;
    height: 40px;
    line-height: 40px;
  }
  .banner-bg {
    padding: 30px;
  }
  .product-list-small figure {
    margin-bottom: 20px !important;
  }
  .product-list-small .title-small {
    font-size: 16px;
    font-weight: 600;
  }
  .newsletter .des {
    display: none;
  }
  .newsletter form {
    margin: 15px 0;
  }
  footer .col-lg-2.col-md-3 {
    width: 50%;
  }
  footer .download-app a img {
    width: 150px;
  }
  .home-slider .hero-slider-1.style-2 .hero-slider-content-2 {
    padding-left: 0;
    padding-top: 50px;
  }
  .home-slider .hero-slider-1.style-2 .hero-slider-content-2 h3 {
    line-height: 1.4;
  }
  .hero-slider-1.style-3.dot-style-1.dot-style-1-position-1 ul {
    bottom: 0;
  }
  .single-content .banner-text, .banner-img.banner-big .banner-text {
    display: none;
  }
  .comments-area {
    padding: 25px 0;
    margin-top: 0;
  }
  .entry-bottom {
    margin-bottom: 0 !important;
  }
  section.pt-150.pb-150 {
    padding: 50px 0 !important;
  }
  .product-detail .single-share {
    margin-bottom: 20px;
  }
  .product-detail .tab-style3 .nav-tabs li.nav-item a {
    padding: 0.5rem;
    text-transform: none;
  }
  .related-products .product-cart-wrap {
    margin-bottom: 30px;
  }
  .mb-sm-15 {
    margin-bottom: 15px;
  }
}

/*phone landscape*/
@media only screen and (min-width: 480px) and (max-width: 667px) {
  .header-bottom-bg-color {
    padding: 3px 0;
  }
  .header-height-2 {
    min-height: 40px;
  }
  .col-lg-4 .banner-img {
    margin-bottom: 30px;
  }
  .banner-features {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 550px) {
  .single-header .single-header-meta {
    display: block;
  }
}
.header-action-2 .header-action-icon-2 > a{
	
}
.header-container {
    padding-left: 15px;
    padding-right: 15px;
  }
/*small phone*/
@media only screen and (max-width: 376px) {
	
	
  .entry-meta .hit-count, .entry-meta.meta-2 .font-xs {
    display: none;
  }
  .deal {
    padding: 30px;
  }
}


.mobile-header-wrapper-style.sidebar-visible {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-top .mobile-header-logo a {
  display: block;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav {
  height: 100%;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .main-categori-wrap a i {
  margin-right: 15px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .main-categori-wrap .categori-dropdown-active-small {
  background-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 0;
}


.body-overlay-1 {
  background: rgba(0, 0, 0, 0.7) none repeat scroll 0 0;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  visibility: hidden;
  width: 100%;
  cursor: crosshair;
  z-index: 9999;
}

.mobile-menu-active .body-overlay-1 {
  opacity: 1;
  visibility: visible;
}

.mobile-header-wrapper-style {
  position: fixed;
  top: 0;
  /*width: 360px;*/
  width: calc(100% - 54px);
  min-height: 100vh;
  bottom: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  /*
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  */
  
  
  /*-webkit-transform: translate(200px, 0);*/
  
  background-color: #ffffff;
  /*transform: translate(200px,0);
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
  */
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
  z-index: 99999;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner {
  padding: 0px 0px 30px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 20px 30px;
  background-color: #ffffff;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-top .mobile-header-logo a img {
  width: 100px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area {
  padding: 30px 30px 30px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li {
  display: block;
  position: relative;
  padding: 10px 0;
  border-bottom: 1px solid #f2f2f2;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li:last-child {
  border-bottom: none;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.menu-item-has-children .menu-expand {
  right: 0;
  position: absolute;
  cursor: pointer;
  z-index: 9;
  text-align: center;
  font-size: 12px;
  display: block;
  width: 30px;
  height: 30px;
  line-height: 35px;
  color: #1a1a1a;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.menu-item-has-children .menu-expand i {
  font-size: 14px;
  font-weight: 300;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.menu-item-has-children.active > .menu-expand {
  background: rgba(255, 255, 255, 0.2);
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.menu-item-has-children.active > .menu-expand i::before {
  content: "\f112";
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li a {
  font-size: 16px;
  line-height: 1;
  text-transform: capitalize;
  font-weight: 700;
  position: relative;
  display: inline-block;
  color: #1a1a1a;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li a i {
  margin-right: 5px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul {
  padding: 0 0 0 10px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li {
  padding: 10px 0;
  border-bottom: none;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li.menu-item-has-children .menu-expand {
  top: 0px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li a {
  font-size: 15px;
  display: block;
  font-weight: 500;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li ul {
  margin-top: 10px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li.menu-item-has-children.active {
  padding-bottom: 0;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li:hover > a {
  color: #088178;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .categori-dropdown-wrap ul li a {
  padding: 5px 15px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap {
  padding: 25px 0 30px;
  margin-bottom: 30px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info {
  position: relative;
  margin-bottom: 13px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info:last-child {
  margin-bottom: 0;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info a {
  font-size: 15px;
  display: block;
  font-weight: 500;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info a span {
  float: right;
  font-size: 18px;
  position: relative;
  top: 8px;
  line-height: 1;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown {
  margin-top: 5px;
  display: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 10px 0 0 0;
  width: 100%;
  z-index: 11;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li {
  padding-bottom: 10px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li:last-child {
  padding-bottom: 0px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li a {
  font-size: 15px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li a:hover {
  color: #088178;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info:hover > a {
  color: #1a1a1a;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-border {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.close-style-wrap {
  position: absolute;
  right: 55px;
  top: 40px;
}

.close-style-wrap.close-style-position-inherit {
  position: inherit;
}

.close-style-wrap .close-style {
  position: relative;
  background-color: transparent;
  padding: 0;
  border: none;
  width: 30px;
  height: 30px;
}

.close-style-wrap .close-style > i {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 2px;
  margin: 0 !important;
  -webkit-transform-origin: center;
  transform-origin: center;
  display: block;
  overflow: hidden;
}

.close-style-wrap .close-style > i.icon-top {
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}

.close-style-wrap .close-style > i.icon-top::before {
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s, -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  -webkit-transform: scaleX(1) translateZ(0);
  transform: scaleX(1) translateZ(0);
  -webkit-transform-origin: right;
  transform-origin: right;
}

.close-style-wrap .close-style > i.icon-top::after {
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transform: scaleX(0) translateZ(0);
  transform: scaleX(0) translateZ(0);
  -webkit-transform-origin: left;
  transform-origin: left;
}

.close-style-wrap .close-style > i.icon-bottom {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}

.close-style-wrap .close-style > i.icon-bottom::before {
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s, -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  -webkit-transform: scaleX(1) translateZ(0);
  transform: scaleX(1) translateZ(0);
  -webkit-transform-origin: right;
  transform-origin: right;
}

.close-style-wrap .close-style > i.icon-bottom::after {
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transform: scaleX(0) translateZ(0);
  transform: scaleX(0) translateZ(0);
  -webkit-transform-origin: left;
  transform-origin: left;
}

.close-style-wrap .close-style > i::before,
.close-style-wrap .close-style > i::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #111;
}
.shippingCard {
	border: 2px solid #dbdbdb;
	border-radius: 10px;
	padding: 12px 4px 10px 8px;
}
.shippingCard img {
	width: 40px;
	margin-right: 4px;	
}

.loginModal img {
	width: 23px;
	margin-right: 2px;	
}
/*
.loginModal button {
	height: 3rem;
}*/


.loginModal span {
	opacity:0.7
}

.shippingCard .info {
font-size:1.1rem
}

.resume-order-mini{
	max-height: 314px;
	overflow-x: clip;
	overflow-y: auto;
	font-size: .8rem;
	line-height: 1.4;
	margin-right: -12px;
	margin-left: -8px;
}

.btn-primary .left-button {
	border-radius: 22px 22px 22px 22px !important;
	border-color: #b6b5b5  !important;
	background-color: #fff !important;
	color: #696158 !important;
	margin-right: -17px;
	padding-right: 16px;
	z-index: 10 !important;
}

.btn-primary-rigth-button {
	border-radius: 22px 22px 22px 22px  !important;
	border-color: #b6b5b5 !important;
	background-color: #fff !important;
	color: #696158 !important;
	margin-right: -26px;
}

.btn-primary-active {
	z-index: 120 !important;
	border-color: #d50032  !important;
	background-color:  #d50032 !important;
	color: white !important;	
	border-radius:  22px 22px 22px 22px  !important;
	z-index:111 !important 
}


.btn-secondary-left-button {
	border-radius: 22px 22px 22px 22px !important;
	border-color: #b6b5b5  !important;
	background-color: #fff !important;
	color: #696158 !important;
	margin-right: -17px;
	padding-right: 16px;
	z-index: 10 !important;
}

.btn-secondary-rigth-button {
	border-radius: 22px 22px 22px 22px  !important;
	border-color: #b6b5b5 !important;
	background-color: #fff !important;
	color: #696158 !important;
	margin-right: -26px;
}

.btn-secondary-active {
	z-index: 120 !important;
	border-color: #676159  !important;
	background-color:  #676159 !important;
	color: white !important;	
	border-radius:  22px 22px 22px 22px  !important;
	z-index:111 !important 
}

.zIndex100{
	z-index:100 !important
}
.zIndex101{
	z-index:101 !important
}
.zIndex102{
	z-index:102 !important
}
.zIndex103{
	z-index:103 !important
}
.zIndex104{
	z-index:104 !important
}
.zIndex105{
	z-index:105 !important
}
.zIndex106{
	z-index:106 !important
}
.zIndex107{
	z-index:107 !important
}

.zIndex108{
	z-index:108 !important
}

.zIndex109{
	z-index:109 !important
}

.zIndex110{
	z-index:110 !important
}


.shipping-group{
	width: 94%;
}

.shipping-group button{
/*	width: 33.3333%;*/
	font-size: 16px;
	font-weight: 400;
}

.close{
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  padding: 0px 3px 0px 0px !important;
}

.message-top{
  position: inherit !important;
  /*top: 0;*/
  width:100%;
  display: block;
	justify-content: center;
	text-align: center;
	font-size: 14px;
	line-height: 30px;
	transition: all 0.2s ease-in;
}

.message-top .event {
  background: #676159;
  font-weight: bold;
  color:#fff
}

.white-text {
  color:#fff;
  opacity: 1 !important;
}

.message-top-hide{
  display:none;
  visibility: hidden;
  opacity: 0.5;
  line-height: 20px;
	transition: all 0.2s ease-in;
}

.message-top button{
	margin-top: -27px;
	margin-right: 7px;
}


.message-top a {  
  color:#fff;
  text-decoration:none;  
  padding-right: 10px;
	padding-left: 10px;
}

.category-img {
	width:60px	
}
.dark-text{
	color:#2e2720;
}
.alert-option {
	color: #d50032;
	font-weight:bold;
}

.alert-danger {
	width:100%;
	margin-bottom: 0.5rem;
}
/*
.Toastify__toast--error {
  background: #e5efe5;
  
  background-image: url(../images/ico_success.svg);
	background-repeat: no-repeat;
	background-position: left;
	
	top: 16px;
	content: ' ';
	background-size: contain;
	 
}*/

/*-----------------
	18. Booking
-----------------------*/

.booking-doc-info {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
/*
.booking-doc-info .booking-doc-img {
    width: 80px;
    margin-right: 15px;
}
.booking-doc-info .booking-doc-img img {
    border-radius: 4px;
    height: 80px;
    width: 80px;
    object-fit: cover;
}
*/
.schedule-widget {
    border-radius: 4px;
    min-height: 100px;
}
.schedule-header h4 {
    font-size: 18px;
    font-weight: 600;
    margin: 3px 0 0;
}
.schedule-header {
    border-bottom: 1px solid #f0f0f0;
    border-radius: 4px 4px 0 0;
    padding: 10px 20px;
}
.day-slot ul {
    float: left;
    list-style: none;
    margin-bottom: 0;
    margin-left: -5px;
    margin-right: -5px;
    padding: 0;
    position: relative;
    width: 100%;
}
.day-slot li {
    float: left;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    width: 30%;
}
.day-slot li span {
    display: block;
    font-size: 18px;
    text-transform: uppercase;
}
.day-slot li span.slot-date {
    display: block;
    color: #757575;
    font-size: 14px;
}
.day-slot li small.slot-year {
    color: #757575;
    font-size: 14px;
}
.day-slot li.left-arrow {
    left: 0;
    padding: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 20px !important;
    transform: translateY(-50%);
}
.day-slot li.right-arrow {
    right: -11px;
    padding: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 20px !important;
    transform: translateY(-50%);
}
.schedule-cont {
    padding: 20px;
}
.time-slot ul {
    list-style: none;
    margin-right: -5px;
    margin-left: -5px;
	margin-bottom: 0;
    padding: 0;
}
.time-slot li {
    float: left;
    padding-left: 5px;
    padding-right: 5px;
    width: 30%;
	min-height: 1px;
}
.time-slot li .timing {
    background-color: #e9e9e9;
    border: 1px solid #e9e9e9;
    border-radius: 3px;
    color: #757575;
    display: block;
    font-size: 14px;
    margin-bottom: 10px;
    padding: 5px 5px;
    text-align: center;
	position: relative;
}
.time-slot li .timing:hover {
    background-color: #fff;
}
.time-slot li .timing:last-child {
	margin-bottom: 0;
}
.time-slot li .timing.selected {
	background-color: #42c0fb;
	border: 1px solid #42c0fb;
	color: #fff;
}
.time-slot li .timing.selected::before {
    color: #fff;
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
	font-size: 12px;
    font-weight: 900;
    position: absolute;
    right: 6px;
    top: 6px;
}
.schedule-list {
    border-bottom: 1px solid #cfcfcf;
    margin-bottom: 50px;
    padding-bottom: 50px;
}
.schedule-list:last-child {
    border-bottom: 0;
    margin-bottom: 0;
	padding-bottom: 0;
}
.submit-section.proceed-btn {
	margin: 0 0 30px;
}


.booking-schedule.schedule-widget {
		overflow-x: auto;
	}
	.booking-schedule.schedule-widget > div {
		width: 730px;
	}
	.booking-schedule .day-slot ul {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.booking-schedule .day-slot li {
		-ms-flex: 0 0 100px;
		flex: 0 0 100px;
		width: 100px;
	}
	.booking-schedule .time-slot ul {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.booking-schedule .time-slot li {
		-ms-flex: 0 0 100px;
		flex: 0 0 100px;
		width: 100px;
	}
	.booking-schedule .time-slot li .timing span {
		display: inline-block;
	}
	.booking-schedule .day-slot li.right-arrow {
		right: -20px;
	}
	
.primary-formitem, .secondary-formitem {
	background-color: #e9e9e9;
	border: 1px solid #e9e9e9;
	border-radius: 3px;
	color: #757575;
	display: block;
	font-size: 13px;
	margin-bottom: 10px;
	padding: 5px 5px;
	text-align: center;
	position: relative;
	cursor:pointer;
	width: 98%;
	min-height: 60px;
	}
	
.primary-formitem:hover{
	background-color: #fff;
	color: #d50032;
	border: 1px solid #d50032
}

.secondary-formitem:hover{
	background-color: #fff;
	color: #676159;
	border: 1px solid #676159
}


.primary-formitem-active{
	background-color: #fff;
	color: #d50032;
	border: 1px solid #d50032;
	font-weight: bold;
}

.secondary-formitem-active{
	background-color: #fff;
	color: #676159;
	border: 1px solid #676159;
	font-weight: bold;
}

.nomargin{
	padding-right: 2px;
	padding-left: 2px;
}


.itemsCard {
    border: 1px solid #dbdbdb;
    border-radius: 10px;
    padding: 10px 0px 2px 10px;
}

.left-border{
	 border-radius: 4px 0 0 4px !important;
}

.right-border{
	border-radius: 0 4px 4px 0  !important;
}
/*
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -20px;
    margin-left: -20px;
}*/

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
    padding-right: 10px;
    padding-left: 10px;
}

.ml--1{
	margin-left: -0.25rem !important
}
.ml--2{
	margin-left: -0.25rem !important
}
.search-container{
	
	/*position: absolute;
	width: 900px;
	left: calc(100% - 920px);
	top: 143px;
	height: calc(100% - 156px);
	padding-top:  5px;
	*/
	
	min-height: 400px;
	padding-right: 0px !important;
	padding-left: 0px !important;
	
	z-index: 115;
	background-color: #fff;
	border: 1px solid #ccc;
	margin-bottom: 25px;
}

.search-container-mini{
	
	position: absolute;
	width: 900px;
	/*left: calc(100% - 920px);*/
	top: 199px;
	height: calc(100% - 219px);
	min-height: 400px;
	
	z-index: 115;
	background-color: #fff;
	border: 1px solid #ccc;
}

.search-container .row{
	margin-right: 0px;
	margin-left: 0px;
	height: 100%;
}


.search-container .col{
	padding-right: 0px;
	padding-left: 0px;
}

.search-container .col-cat{
	background-color: #fafafa;
	border-right: 1px solid #eee;
	position: relative;
	padding: 32px 0 0;
	flex-wrap: nowrap;

}
.search-container .col-cat .col-cat-content{
overflow-x: hidden;
overflow-y: auto;
position: absolute;
top: 32px;
left: 0;
right: 0;
bottom: 0;
padding: 0 0 32px;
border: 0 solid #eee;
}

.search-container .col-cat .col-cat-content button{
 	color: #696158;
	font-size: 13px;
	padding: 0.25rem !important
}


.search-container .col-cat .col-cat-content .categories{
	line-height: 1rem
}

.col-cat .col-cat-content .categories .title-name {
		width: 85%;
		display: inline-block;	
		text-align: left;
		font-size: 15px;
		font-weight:bold;
	}

.col-cat .col-cat-content .categories .title-button {
	width: 15%;
	display: inline-block;	
	text-align: right;
	font-size: 15px;
	font-weight:bold;
}



.search-container .col-cat .col-cat-content .right {
	width: 81%;
	display: inline-block;	
	text-align: left;
}

.search-container .col-cat .col-cat-content .right:hover {
	text-decoration:underline
}

.search-container .col-cat .col-cat-content .left {
	width: 25%;
	display: inline;	
	cursor: pointer;
}

.search-container .col-cat .col-cat-content .categories >li{
  margin-left: 0.25rem;
  margin-bottom: .25rem;
}
 
.search-container .col-cat .col-cat-content .categories >li:hover{
	border-radius: 4px;
	background-color: #eee;
	border-color: #eee;
	text-decoration: none;
}
.search-container .col-cat .col-cat-content .categories >li:hover button{
 	color: #696158 !important
}

.search-container .col-cat .col-cat-content .categories .active{
	background-color: #d50032;
	border-color: #d50032;
	border-radius: 4px;	
	text-decoration: none; 
}

.search-container .col-cat .col-cat-content .categories .active button{
	color: #fff !important
}

.search-container .col-cat .col-cat-content .categories .name {
	width: 71%;
	display: inline-block;	
	text-align: left;
}

.search-container .col-cat .col-cat-content .categories .quantity {
	width: 29%;
	display: inline-block;	
	text-align: right;
}

.search-container .col-cat .col-list-content{
overflow-x: hidden;
overflow-y: auto;
position: absolute;
top: 2px;
left: 0;
right: 0;
bottom: 0;
padding: 0 0 32px;
border: 0 solid #eee;
}
/*
.search-container .col-cat .col-cat-content .left button{
	color: #696158;
	font-size: 13px;
}
*/

.search-container .col-list{
	flex-wrap: nowrap;
}

.search-container .col-list .list-header{
	display:flex;
	width:100%;
	font-size: 13px;
}


.search-container .col-list .list-header  > div{
	
	display:flex;
	width:60%;
	text-align:center;
	justify-content:center;
	padding: 5px 0 0 0;
	
}

.search-container .col-list .list-header  > div:first-child{
	
	display:flex;
	width:100%;
	text-align:center;
	justify-content:center;

}
.search-container .col-list .list-header  > div:first-child button{
	
	background-color: #fff;
	border: 0px;
	margin-top: -5px;
	font-size: 18px;
	padding: 0;
	margin-bottom: -7px;
	color: #696158;
}


.search-container .col-list .list-header  > div:last-child{
	
	display:flex;
	width:100%;
	text-align:center;
	justify-content:center;
	padding: 5px 5px 0px 0px;
	
}

.search-container .col-list .list-header  > div:last-child button{
	
	background-color: #fff;
	border: 0px;
	margin-top: -5px;
	font-size: 18px;
	padding: 0;
	margin-bottom: -7px;
	color: #696158;
}

.search-container .col-list .list-header   button:hover{
	color: #d50032;
}

.search-container .col-list .list-content{
	/*overflow-y: auto;
	position: absolute;
	height: 94%;*/
}

.search-container .col-list .list-content .col-prod{
	background:#fff;
	width: 203px !important;
	padding-right: 15px;
	padding-left: 15px;
	/*adding: 10px 15px 15px 10px;*/
	max-height:243px;
}

.search-container .col-list .list-content .col-prod:hover{
	background:#f0f0f0;
}
.search-container .col-list .list-content .col-prod .image{
	margin: 12px 0 12px 0 !important;
	display: flex;
	justify-content: center;
}

.search-container .col-list .list-content .col-prod .price{
	display: flex;
	justify-content: center;
}

.search-container .col-list .list-content .col-prod .info{
	display: flex;
	justify-content: center;
	margin-bottom: 5px;
}

.search-container-image {
	width: 160px;
	height: 160px;
	margin: 12px 0 12px 0;
	
}
.search-container-image-mini {
	width:90%;
	height: 90%;
	margin: 7px 0 7px 0 !important;
}

/*
.search-container .col-list .list-content .col-12{
	height: 135px;
	background: #fff;
	display: flex;
	border-bottom: 1px solid #f0f0f0;
}*/

 
/*
.search-container .col-list .list-content .col-12:hover{
	background:#f0f0f0;
}*/
.search-container .col-list .list-content .col-12 .image{
	margin: 17px 0 12px 0 !important;
	display: flex;
	justify-content: center;
}

.search-container .col-list .list-content .col-12 .description{
	display: block;
	justify-content: center;
	margin: 11px 0px 0px 11px;
}

.product-description{
	min-height: 72px;
}

.search-container .col-list .list-content .col-12 .description p {
	margin-top: 0;
	margin-bottom: 0rem;
}

.product-info p{
	margin-bottom: 0.25rem;
	/*color: #696158;*/
	/*margin-top: 0.25rem;*/
}
.product-info .sku{
	font-size: 11px;
	
}
.product-info .title-detail{
	font-size: 22px;
	font-weight: 400;
	margin-bottom:0.25rem;
	font-family: "Roboto",Arial,sans-serif;
}
.title-description{
	
}

.product-info .price-info{
	margin-top: 2rem;
	margin-bottom: 1.25rem;
}

.product-info .price-info .price{
	color: #d50032;
	font-size: 26px;
	/*font-weight: 700;*/
	
}

.btn-product-info {
	border: 1px solid transparent !important; 
	padding: 10px 10px 6px 10px !important;
  font-size: 1rem !important;
	line-height: 1.5 !important;
	border-radius: 0.25rem !important;
}

.btn-outline-secondary{
	background: #fff;	
	border: 1px solid #676159 !important;
	color:#676159;
}
.btn-outline-secondary:hover{
	background: #676159;	
	border: 1px solid #676159 !important;
	color:#fff !important;
	transition: all .5s ease-out 0s;
	-webkit-transition: all .5s ease-out 0s;
}

.btn-outline-primary{
	background: #fff;	
	border: 1px solid #d50032 !important;
	color:#d50032;
}
.btn-outline-primary:hover{
	background: #d50032;	
	border: 1px solid #d50032 !important;
	color:#fff !important;
	transition: all .5s ease-out 0s;
	-webkit-transition: all .5s ease-out 0s;
}

.profile-widget {
	text-align: center;
	padding: 0;
	border: none;
    background-color: #fff;
  /*  border: 1px solid #f0f0f0;*/
    border-radius:3px;
    margin-bottom:0px;
    position:relative;
    -webkit-transition:all .3s ease 0s;
    -moz-transition:all .3s ease 0s;
    -o-transition:all .3s ease 0s;
    transition:all .3s ease 0s;
	padding: 5px;
	font-size: 14px;
}

.profile-widget a {
	font-size: 14px;
	color : #696158 !important;
}
.profile-widget a:hover {
	font-size: 14px;
	text-decoration:underline;
	color : #696158 !important;
}


.profile-widget .fav-btn {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #fff;
    width: 30px;
    height: 30px;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
    border-radius: 3px;
    color: rgba(105, 96, 87, 1);
    -webkit-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    opacity: 0;
    visibility: hidden;
	z-index: 99;
}
.profile-widget:hover .fav-btn {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.profile-widget .fav-btn:hover {
	background-color: #d50032;
	color: #fff !important;
	
}

.profile-widget .product-tag {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    position: absolute;
    top: 4px;
    right: 1px;
    background-color: #ff003c; /* #d50032; del basico*/
    min-width: 26px;
    padding: 5px;
    
    /*padding-left: 5px;
    padding-right: 5px;
    min-height: 26px;
    */
    
		/*display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;*/
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
    border-radius: 3px;
    color: #fff;
    /*-webkit-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: hidden;*/
    opacity: 0.9;
		z-index: 99;
		font-weight:bold;
		font-size:12px;
}

.profile-widget .product-event {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    position: absolute;
    top: 4px;
    left: 1px;
    background-color: #676159; /* #d50032; del basico*/
    min-width: 26px;
    max-width:85px;
    padding: 3px;
    
    /*padding-left: 5px;
    padding-right: 5px;
    min-height: 26px;
    */
    
		/*display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;*/
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
    border-radius: 15px;
    color: #fff;
    /*-webkit-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: hidden;*/
    opacity: 0.9;
		z-index: 99;
		font-weight:400;
		font-size: 0.75rem; 
}

.rounded{
	border-radius: 15px !important;
}

.profile-widget .product-tag-info {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    position: absolute;
    bottom: 5px;
		right: 5px;
    
    min-width: 26px;
    padding: 5px;
    background-color: #fff; 
   	border-radius: 4px;
		justify-content: right;
		-webkit-justify-content: right;
		-ms-flex-pack: right;
    
    opacity: 1;
		z-index: 99;
		font-weight:bold;
		font-size:11px;
}

.profile-widget .custom-product-tag {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    position: absolute;
     
    min-width: 38px;
    padding-left: 5px;
    padding-right: 5px;
    height: 28px;
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
    border-radius: 3px;
    color: #fff;
    /*-webkit-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: hidden;*/
    /*opacity: 0.9;*/
		z-index: 99;
		font-weight:bold;
		font-size:12px;
}
.profile-widget .custom-product-tag img  {
	transform: scale(1) !important;
}


.profile-widget .custom-product-tag:hover img  {
	transform: scale(1) !important;
}



.profile-widget .product-new-tag {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    position: absolute;
    top: 1px;
    right: -4px;
    
    width: 72px;
    height: 30px;
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
    border-radius: 3px;
    color: #696158;
    /*-webkit-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: hidden;*/
    opacity: 0.7;
		z-index: 99;
		font-weight:bold;
		font-size:20px;
		font-family: 'Roboto','Helvetica Neue',Helvetica,Arial,sans-serif
}


.product-info-first-div{
	min-height: 60px;
}

.product-info a:hover{
  color: #333;
	text-decoration: underline;
}

.profile-widget .option {
	position: absolute;
	left: 0px;
	width: 100% !important;
/*	cursor: pointer; */
	-webkit-justify-content: left;
	-ms-flex-pack: left;
	border: 0;
	color: rgba(105, 96, 87, 1);
	/*-webkit-transform: translate3d(100%, 0, 0);*/

  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
    
  display: flex;
	text-align: center;
	justify-content: center;
	border:0;
	opacity: 0;
	visibility: hidden;
	
	z-index: 99;
	background: #FFF;
	margin-left: 0px;
	height: 74px;
	margin-top: -14px;
}

.product-info a:hover{
  color: #333;
	text-decoration: underline;
}

.profile-widget .comment-btn-container {
	position: absolute;
	left: 0px;
	width: 100% !important; 
	-webkit-justify-content: left;
	-ms-flex-pack: left;
	border: 0;
	color: rgba(105, 96, 87, 1); 
  display: flex;
	text-align: center;
	justify-content: center;
	border:0; 
	z-index: 99;
	background: #FFF;
	margin-left: 0px;
	height: auto;
	background: transparent;
	top: 75%;
}
 
.sku-comment-container{
  position: fixed;
	display: flex;
	-moz-box-align: center;
	align-items: center;
	-moz-box-pack: center;
	justify-content: center;
  
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1066;
	opacity:1;  
	transition: all 0.25s ease-out;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	min-height: 180px;
}

.sku-comment-container-hide {
  position: fixed;
	display: flex;
	-moz-box-align: center;
	align-items: center;
	-moz-box-pack: center;
	justify-content: center;
	min-height: 180px;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: -1;    
	opacity:0;  
	transition: all 0.25s ease-out;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.sku-comment-active {
	position: absolute;
	top: calc(50% - 90px);	
	left: calc(50% - 185px);	
	/*transform: translate(-50%, -50%);*/
	max-width: 370px;
	background-color: #fff;
	width: 90%;
	min-width: 370px;
	padding:0.5rem 1rem 0.5rem 1rem;
	min-height:180px;
	/*display: flex;
	align-items: center;
	justify-content: center;*/
	font-size: 1.25rem;
	line-height: 2rem;
	font-family: "Roboto";
	border-radius: 15px;
	border-bottom: 15px solid #676159;
 	text-align: justify;
 	opacity:1;
 	transition: all 0.5s ease-out;
}
.sku-comment {
	min-height: 110px;
	text-align: justify;
}
 
.sku-btn-container{
	text-align: right;
}

.plus-option{
	position: absolute;
	top: 3px;
	background: transparent;
	border: 0px;
	font-size: 15px;
	line-height: 14px;
	text-decoration:underline
}



.profile-widget .option .row {
	width: 100%;
	margin-left: 0px !important;
	margin-right: 0px !important;
}
.profile-widget .option .row .col-12 {
	padding-right: 0px;
	padding-left: 0px
}


.list-filter {
  /*margin-left: 0.5rem;*/
	padding: 0;
	list-style: none !important;
	margin-bottom: .25rem;
}

.list-filter li {
  display: inline-block;
  /*border: 1px solid #fff;*/
	padding: 0px;
	opacity: 0.9
}

.list-filter-li-active {
  display: inline-block;
  /*border: 1px solid #ff5c83 !important;*/
  border-radius: 3px;
	padding: 0px;
	opacity: 1 !important;
}

.list-filter li div {
  color: #555;
  display: block;
  /*min-width: 30px;*/
  text-align: center;
  position: relative;
  transition: all 0.5s ease-out 0s;
  -webkit-transition: all 0.5s ease-out 0s;
  margin-right:0px;
}

.list-filter li a button {
	background: #fff;
	border: 1px solid rgba(105,97,88,.6);
	font-size: 12px;
	line-height: 27px;
	margin-right: 7px;
	min-width: 22px;
	padding: 0px;
	color: #696158;
	border-radius: 3px;
	font-stretch: condensed;
}

.list-filter li div button {
	background: #fff;
	border: 1px solid rgba(105,97,88,.6);
	font-size: 12px;
	line-height: 27px;
	margin-right: 4px;
	min-width: 22px;
	padding: 0px;
	color: #696158;
	border-radius: 3px;
	font-stretch: condensed;
}

.list-filter li div button  span {
	padding:5px !important 
}

.list-filter li a button  span {
	padding:5px !important 
}

.list-filter li a button:hover {
	outline: 1px solid #999;
}

.list-filter.size-filter li {
  margin-right: 5px;
}
 
.color-filter.list-filter a span.product-color-white {
  border: 1px solid #ddd;
}

.color-filter.list-filter li.active a::before {
  content: "";
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  position: absolute;
  right: 3px;
  top: -3px;
  background: #4cd964;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #fff;
}

.size-filter.list-filter a {
  border-radius: 4px;
  padding: 0 7px;
  background: #fff;
}

.size-filter.list-filter li a {
  border: 1px solid #e5e5e5;
  color: #555;
  height: 30px;
  line-height: 30px;
  min-width: 30px;
  text-align: center;
  text-transform: uppercase;
}

.size-filter.list-filter li a:hover,
.size-filter.list-filter li.active a {
  color: #fff;
  background-color: #d50032;
  border-color: #d50032;
}

.profile-widget:hover .option {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    border:0;
}

.profile-widget .pro-content .title button {
    display: inline-block;
}
.profile-widget .pro-content .title a {
    display: inline-block;
}
.profile-widget .verified {
	color: #28a745;
	margin-left: 3px;
}
.profile-widget p.speciality {
    font-size: 13px;
    color: #757575;
    margin-bottom: 5px;
    min-height: 40px;
}
.rating {
    list-style: none;
    margin: 0 0 7px;
    padding: 0;
    width: 100%;
}
.rating i {
    color: #dedfe0;
}
.rating i.filled {
    color: #f4c150;
}
.profile-widget .rating {
    color: #757575;
    font-size: 14px;
    margin-bottom: 15px;
}
.profile-widget .rating i {
	font-size: 14px;
}


.profile-widget .product-action {
	opacity: 0;
  visibility: hidden;
}
 
.profile-widget:hover .product-action{
	opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
 
.product-action > div {
  display: inline-block;
  vertical-align: top;
  margin-top: 4px !important;
	height: 31px; 
}
 
.doc-img {
    position: relative;
    overflow: hidden;
    z-index: 100;
    border-radius: 4px;
}

.doc-img img {
    border-radius: 4px;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
	transform: translateZ(0);
	-moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    width: 100%;
}
.doc-img:hover img {
	/*
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  transform: scale(1.15);
  */
  transform: translateZ(0);
	-moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}


.pro-content {
    padding: 15px 0 0;
    
}
.pro-content .title {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 5px;
}

.pro-content p {
  margin-bottom: .5rem;
	padding-right: 10px;
	padding-left: 10px;
	margin-top: -.5rem;
	min-height: 47px;
	max-height: 47px;
	text-align: center;
	width: 100%;
}

.product-extra-link2 {
	margin-left: 0.25rem;
}


.header-action-icon-2 .shipping-info {
	position: absolute;
	/*width: 351px;
	right: -117px;
	top: 31px;
	*/
 	background:transparent;

	z-index: 150;
	/*transform: translate3d(100%, 0, 0);*/
  opacity: 0;
  visibility: hidden; 
  /*margin: 0 !important;*/
  /*margin-top: 5px;*/
  padding-top:0.5rem;
}

.no-margin  {
	margin:0 !important;
}
.no-margin-row{
	display: flex;
	flex-wrap: wrap;
	margin-left:0px !important;
	margin-right:0px !important;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}

.no-margin-row >div:last-child {
	margin-bottom: 1rem;
}

.no-margin-row >div >a:hover {
	text-decoration:underline;
}

.header-action-icon-2:hover .shipping-info {
    opacity: 1;
    visibility: visible;
   /* -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);*/
}
.header-action-icon-2 .shipping-info a:hover {
	color: #696158 !important;
}

.header-action-icon-2 .shipping-info span {
	min-width: 50px;
	font-weight: 400;
	font-size: 14px;	
}

.header-action-icon-2 .shipping-info > div {
	border: 1px solid;
	border-radius: 6px;
/*	margin-top: 	4px;*/
	/*background:#fff;*/
	/*margin-bottom: 	10px;*/
}

.header-action-icon-2 .shipping-info .btn {
	font-size: 12px !important;	
}

.header-action-icon-2 .user-info {
	position: absolute;
	width: 161px;
	right: -45px;
 	background:transparent;
	top: 31px;
	/*box-shadow: 0 0 7px 0 rgba(0,0,0,0.5) !important;
	border-radius: 6px;*/
	/*transform: translate3d(100%, 0, 0);*/
  opacity: 0;
  visibility: hidden;
  z-index: 150;
  margin: 0;
  padding-top:0.5rem;
}

.header-action-icon-2:hover .user-info {
    opacity: 1;
    visibility: visible;
   /* -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);*/
}


.header-action-icon-2 .user-info ul {
	box-shadow: 0 0 7px 0 rgba(0,0,0,0.5) !important;
	border-radius: 6px;
	margin-top: 	4px;
	background:#f6f4f2;
}
.div-shadow{
	box-shadow: 0 0 7px 0 rgba(0,0,0,0.5) !important;
}

.header-action-icon-2 .user-info ul li {
	margin: 14px 14px 14px 25px;
	color: #696158;
	font-size: 14px;
	text-align: left;
}

.header-action-icon-2 .user-info ul li:hover a {
	
	color: #696158 !important;
	text-decoration: underline;
}


.user-info .card {
	margin-bottom:0px;
}
	

.user-info img {
	width:50px
}
.user-info button {
	font-size:11px
}

.card-user-info {
	border: 1px solid #e6e6e6 !important;
	margin-bottom: 1rem !important;

}

.card-user-info .menu-info {
	border-right: 1px solid #e6e6e6 !important;
	padding-right: 0px;
	color:#696158;
}

.card-user-info .menu-info ul {
	 
	margin:7px;
}

.card-user-info .menu-info ul li {
	/*margin-top:5px;*/
	
  line-height: 3rem;
}


.card-user-info .menu-info ul li:hover {
  font-weight:700;
  color:red
}


.card-user-info .menu-info .active {
	
	background: #f6f4f2;
  line-height: 3rem;
  margin: 7px;
	font-size: 23px;
	padding: 7px;
}


.card-user-info .menu-info ul li button {
background: transparent !important;
border: 0px !important;
font-size: 15px;
}

.card-user-info .menu-info ul li button:hover {
  font-weight:700;
}

.card-user-info .menu-info .option-active {
  font-weight:700;
}

.card-user-info .menu-content  {
	padding: 23px 0 0 70px;
	color:#696158;
}

.card-user-info .menu-content .or-line {
  margin: 0px 0px 0 0px !important
  /*margin: 0px 40px 0 0px !important*/
}

.gmap-container {
	height: '300px';
	width: '95%' 
}

.old-price {
  color: #cccccc;
  text-decoration: line-through;
  font-size: 22px;
}
.save-price {
 
  font-size: 22px;
}
.col-form-left{
	padding-left: 0px;
	padding-top: 1px;
	margin-left: 15px;
	/*flex: 0 0 66.666667%;*/
	max-width: 66.666667%;
	position: relative;
	width: 100%;
}


.col-form-right{
	padding-right: 0px;
	display: flex;
	align-content: center;
	align-items: center;
	/*flex: 0 0 33.333333%;*/
	max-width: 33.333333%;
	position: relative;
	padding-top: 3px;
}

.Toastify__toast--success{
		color: #006400 !important;
		background: url(../images/ico_success.svg) !important;
    background-color: #e5efe5 !important;
    background-position-y: center !important;
    background-repeat: no-repeat !important;
    background-size: 14% !important;
		padding-left: 40px  !important;
    min-height:48px !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05) !important;
}

.Toastify__toast--success button{
	color: #006400 !important;
}

.Toastify__toast--warning{
	color: #6f4400 !important;
		background: url(../images/ico_warning.svg) !important;
    background-color: #fdf0d5 !important;
    background-position-y: center !important;
    background-repeat: no-repeat !important;
    background-size: 14% !important;
		padding-left: 40px !important;
    min-height:48px !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05) !important;
}

.Toastify__toast--warning button{
	color: #6f4400 !important;
}

.Toastify__toast--error{
	color: #f52e0c !important;
	background: url(../images/ico_error.svg) !important;
  background-color: #ff9c9c !important;
  background-position-y: center !important;
  background-repeat: no-repeat !important;
  background-size: 14% !important;
	padding-left: 40px !important;
  min-height:48px !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05) !important;
}

.Toastify__toast--error button{
	color: #f52e0c !important;
}

.price-slider {
  position: relative;
  width: 215px;
  height: 50px;
}

.price-slider__track,
.price-slider__range {
  border-radius: 3px;
  height: 5px;
  position: absolute;
  height: 10px;	
}

.price-slider__track {
  background-color: #ced4da;
  width: 100%;
  z-index: 1;
}

.price-slider__range {
  background-color: #9fe5e1;
  z-index: 2;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 223px;
  outline: none;
  margin-top: 3px;
}

.thumb--zindex-3 {
  z-index: 3;
}

.thumb--zindex-4 {
  z-index: 4;
}

.thumb--zindex-5 {
  z-index: 5;
}

input[type="range"]::-moz-range-track {
	height: 3px !important;
	border: none !important;
	background: none !important;
}

input[type="range"]:focus::-moz-range-track {
    background: none !important;
}

input[type="range"]::-moz-range-track {
	height: 3px !important;
	border: none !important;
	background: none !important;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
    background: none !important;
}

input[type="range"]::-webkit-slider-runnable-track {
	/*height: 3px;*/
	border: none !important;
	background: none !important;
}
 
/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
	
	background: url(../images/vertical-line.png) !important;  
	background-size: 95% !important;  
	background-repeat: no-repeat !important;  
	background-position-x: center !important;  
	background-position-y: center !important; 
	
  /*background-color: #fdfdfe  !important;*/
  border: 1px solid #bbb7b7  !important;
  border-radius: 3px !important;
  cursor: pointer !important;
  height: 24px !important;
  width: 18px !important;
  margin-top: 3px !important;
  pointer-events: all !important;
  position: relative !important;
}	
/* For Firefox browsers */
.thumb::-moz-range-thumb {
	
	background: url(../images/vertical-line.png) !important;  
	background-size: 95% !important;  
	background-repeat: no-repeat !important;  
	background-position-x: center !important;  
	background-position-y: center !important;  
		
  /*background: #fdfdfe  !important;  */
  
  border: 1px solid #d9d9d9  !important;
  border-radius:5px !important;  
  cursor: pointer !important;
  height: 24px !important;
  width: 24px !important;
  margin-top: 5px !important;
  pointer-events: all !important;
  position: relative !important;
  box-shadow: inset 0 0 1px #fff,inset 0 1px 7px #ebebeb,0 3px 6px -3px #bbb;
 
}

.price-slider__left-value,
.price-slider__right-value {
  color: #9aa4ac;
  font-size: 12px;
  margin-top: 30px;
  position: absolute;
  
}

.price-slider__left-value {
  left: -9px;
  /*margin-left: -10px;*/
}

.price-slider__right-value {
  right: -10px;
  
}

.price-slider-container {
	height: 35px;	
}

.price-slider-container .item {	
	position: absolute;
	background: #fff;
	padding: 2px;
	border: 1px solid #e4e4e4;
	border-radius: 3px;
	font-size:11px;
}
.price-slider .item-mark{
	width: 2px;
	height: 5px;
	position: absolute;
	background: #ccc;
	margin-top: 20px;
}

.price-slider .item-mark-big{
	width: 2px;
	height: 8px;
	position: absolute;
	background: #ccc;
	margin-top: 20px;
}

.active-div{
	width:34px;
	height:33px;
	position:relative;
	margin-top: -33px;
	border-radius: 16px !important;
	/*outline: 2px solid #999 ;*/
	box-shadow: 0 0 4px #000;
	border: 2px solid #999797;
	margin-left: 0px;
}

	 
.ban-div{
/*  border:1px solid rgba(105,97,88,.6);*/
  width:34px;
  height:34px;
  position:relative;
  margin-top: -33px;
  border-radius: 16px; 
	/*box-shadow: 0 0 3px #000;*/
	border: 2px solid #1e1d1c;
	margin-left: 0px;
	cursor: no-drop !important;
}


.ban-div:after{
  content:"";
  position:absolute;
  border-top:2px solid #1e1d1c;
  width: 33px;
  /*width: 45px;*/
	transform: rotate(45deg);
	transform-Origin: 0% 0%;
	margin-left: -11px;
	margin-top: 2px;
	cursor: no-drop !important;

	/*margin-left: -15px;
	margin-top: -1px;*/
	
}

.active-mini-div{
	width: 24px;
	height: 23px;
	position: relative !important;
	margin-top: -26px;
	border-radius: 11px !important;
	box-shadow: 0 0 2px #000;
	/*outline: 1px solid red;*/
	border: 2px solid #999797;
	margin-left: 0px;
}


.option-button{
	background: #fff;
	border: 1px solid rgba(105,97,88,.6);
	font-size: 12px;
	line-height: 27px;
	margin-right: 4px;
	min-width: 22px;
	padding: 0px;
	color: #696158;
	border-radius: 3px;
	font-stretch: condensed;
}
.option-button span {
	padding: 5px !important;
}


.ban-option-button{
	border: 2px solid #646464  !important;
	cursor: no-drop !important;
}

.active-option {
	border: 1px solid #999797 !important;
	/*box-shadow: 0 0 2px #000;*/
	background: #ececec !important;
	color: #696158;
	font-weight: 700;
}

.ban-option {
	background: linear-gradient(to top right, 
	rgba(255, 255, 255, 0) 0%, 
	rgba(255, 255, 255, 0) calc(50% - 1.2px),
	rgb(0, 0, 0) 50%, 
	rgb(255, 255, 255, 0) 
	calc(50% + 1.2px), 
	rgba(255, 255, 255, 0) 100%) !important;
	opacity: 0.8;
}






.ban-div-mini{
  border: 1px solid rgba(105,97,88,.6);
	width: 28px;
	height: 28px;
	position: relative;
	margin-top: -31px;
	border-radius: 2px;
	cursor: no-drop !important;
}

.ban-div-mini:after{
  content:"";
  position:absolute;
  border-top:1px solid #696158;
  width: 38px;
	transform: rotate(45deg);
	transform-Origin: 0% 0%;
	margin-left: -14px;
	margin-top: -1px;
	cursor: no-drop !important;
}

.ban-option-mini {
	 
	 background: linear-gradient(to top right, 
	 rgba(255, 255, 255, 0) 0%, 
	 rgba(255, 255, 255, 0) calc(50% - 0.8px),
	  rgb(0, 0, 0) 50%, 
	  rgb(255, 255, 255, 0) 
	 calc(50% + 0.8px), 
	 rgba(255, 255, 255, 0) 100%) !important;
		cursor: no-drop !important;
}

.ban-option-mini span {
	padding: 5px !important ;
	cursor: no-drop !important;
}
	 

.row{
	margin-left:	-5px;
	margin-right:	-5px;
}
.formIcon{
	max-width: 33px !important;
	top: 3px;
	position: absolute;
	left: 4px;
}
.formIcon2{
	max-width: 33px !important;
	top: -6px;
	position: absolute;
	left: 4px;
}

.formIcon-span{
	margin-left:18px;
}
.form-span{
	font-family: 'Roboto','Helvetica Neue',Helvetica,Arial,sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	margin-top: 5px;
	color: #696158;
}
.border-right{
	border-right: 1px solid #ddd;
}

.userForm input {
	height: 50px !important

}

.userForm input[type="checkbox"] {
	height: auto !important

}
.checkbox-label {
	padding-left : 3px;
}

.checkbox-label > label {
	margin-bottom:0rem;
	font-size: 14px;
}

.checkbox-is-invalid{
	
}

.text-underline{
	text-decoration: underline;
}
.no-button{
	background: #fff !important;
	border: 1px solid #fff !important;
}

.no-button-transparent {
	/*background: transparent !important;*/
	border: 0px solid transparent !important;
}

.transparent-button{
	background: transparent !important;
	border: 0 !important;
}
.product-qty{
	background: #fff !important;
	border: 1px solid #b4b4b4 !important;
	height:30px;
	padding: 5px 3px 5px 2px;
	width: 23px;
	text-align: center;
	border-radius: 0px !important;
	max-width: 25px;
	
	
}
.product-qty-updwown1{
	background: #fff !important;
	border-right: 1px solid #b4b4b4 !important;
	
	border-top: 0px solid #b4b4b4 !important;
	border-left: 0px solid #b4b4b4 !important;
	border-bottom: 0px solid #b4b4b4 !important;

	/*padding: 5px 3px 5px 2px;*/
	width:1px;
	margin-right:0px !important;
}
.product-qty-updwown{
	background: #fff !important;
	border: 1px solid #b4b4b4 !important;
	height:30px;
	padding: 5px 3px 5px 2px;
	width:33%;
	margin-right:0px !important;
	border-radius: 0px !important;	
	max-width: 25px;
}

.order-list {
	margin-top:15px;

}

.order-list li {
	margin-top:10px;
	margin-left: 10px;
}
.order-status{
 	margin: 0px;
	background: #f0f0f0;
}

.order-status .history-label{
  font-size:15px;
}

.order-status .history-label:first-child{
  font-weight:600;
}

.order-status-white{
	background: #fff;
}
.order-status .title{
	font-size: 27px;
	font-weight: bold;
}

.order-status span{
	font-size: 22px
}

.order-status-container{
	margin: 22px -10px 22px -10px;
}

.order-status-header{
	line-height: 2rem;
}

.order-status-header .resume-info{
	text-align: right
}

.order-status-header .resume-info p {
	margin-bottom:0rem
}


.step-order-status{
	width:25%;
	height: 100px;
/*	margin: 10px;*/
	background: #fff;
}


.order-status .product-detail{
	background: #fff;
	margin:18px 18px 0 18px;
	border-radius:4px;
	width:100%;
	display:flex;
}
.order-status .product-detail .image{

	width:20%;
	text-align: center;
	align-content: center;
	display: flex;
	align-items: center;
}

.order-status .product-detail .image img{
	width: 80%;
} 
.order-status .product-detail .detail{
	
	width:80%;
 	text-align:left;
 	font-size: 24px;
 	margin: 20px 0 20px 0;
 	
}


.order-status .product-detail:last-child {
 margin-bottom: 20px;
 border-radius:4px;
}

.order-status .product-detail .detail span{
 	font-size: 14px;
}

.order-status .product-detail .detail p{
 	margin:0px
}


.step-order-status img{
	height:107px;
	width:100%;
	/*margin-right:10px*/
}

.step-order-status div {
	width:100%;
	height: 100%;
  /*margin: 10px;*/
	/*background: #f0f0f0;*/
	/*border-bottom: 12px solid #c8c8c8;*/
	border-radius: 3px;
	/*padding:3px*/
}

.step-order-status .active {
	border-bottom: 12px solid #4ca480;
}

.confirm-upd {
	margin-left: 4px;
	min-width: 37px;
	color: #fff;
	border: 0px solid #d50032;
	background: #d50032;
	border-radius: 3px;
	opacity: 0.9;
	height: 40px;
	/*align-self: center;*/
}

.cart-product-name {
	text-align: left !important; 
	max-width:280px
}
.cart-product-name ul{
	font-size: 13px;
}
.text-right{
	text-align: right;
}

.text-justify{
	text-align: justify;
}

.text-09{
	font-size: 0.9rem;
}

.text-08{
	font-size: 0.8rem;
}

.mt--40{
	margin-top : -40px;
}

.relative{
	position:relative
}
.direction-column{
	flex-direction: column;
}

.logo-modal-store{
	text-align: center;
}

.logo-modal-store img{
	max-width:220px
}

.modal-store-item{
	height: 45px;
	border: 1px solid #a1a1a1;
	margin-bottom: 15px;
	font-size:18px;
	border-radius: 3px;
	-webkit-transition: all 0.1s ease-out;
}

.modal-store-item:hover{
	height: 43px;
	margin-bottom: 17px;
	color: 	#d50032 !important;
	border: 1px solid #d50032;
	border-radius: 3px;
}

.modal-store-item button{
	background: none;
	border: 0px;
	display: block;
	width: 100%;
	height: 100%;
}

.modal-store-item button:hover{
	color: 	#d50032 !important;
}

.addressCard {
	border: 2px solid #dbdbdb;
	border-radius: 10px;
	padding: 12px 4px 10px 8px;
	background-color: #f6f4f2;
	width: 95%;
}

.addressCard .titleAddress{
	font-size: 15px;
	font-weight:500;	
}
.accout-btn{
	height: 47px;
}

.mobile-social-menu{
	font-size:15px;
}

.mobile-social-menu > nav > ul.mobile-menu > li a{
	line-height: 3rem;
	border-bottom: 1px solid #d1d1d1;
	align-items: center;
	display: flex;
}

.mobile-social-menu > nav > ul.mobile-menu > li a img {
	width: 35px;
	margin-right: 5px;
}


.mobile-social-menu > nav > ul.mobile-menu > li a div{
	width: 41px;
	height: 32px;
	overflow: hidden;
	margin-left: -10px;
	position: relative;
	margin-right: -10px;
}


.mobile-social-menu > nav > ul.mobile-menu > li a div img {
	position: relative;
	margin: auto;
  margin-left: auto;
	min-height: 32px;
	min-width: 42px;
}

.mobile-social-menu > nav > ul.mobile-menu > li.category-mobile-menu > div{
	display:flex;
	border-bottom: 1px solid #d1d1d1;
}
.mobile-social-menu > nav > ul.mobile-menu > li.category-mobile-menu > div a {
	 width:95%;
	 border-bottom: 0px ;
}
.mobile-social-menu > nav > ul.mobile-menu > li.category-mobile-menu > div i {
	line-height: 3rem !important;
} 

.mobile-social-menu > nav > ul.mobile-menu > li.category-mobile-menu  > ul > li  {
	/*line-height: 2rem; */
	align-items: center;
	/*display: flex;*/
	border-top: 0px
}
.subcategory-mobile-menu2 > li > div {
	display:flex;
	/*border-top: 1px solid #fff;*/
}
.subcategory-mobile-menu2 > li > div a {
	 width:95% !important;
	 /*border-top: 0px ;*/
	 border-bottom: 1px solid #fff !important;
	 line-height: 1.8rem !important; 
	 font-size:14px !important;
	 padding-left: 12px;
}

.subcategory-mobile-menu2 > li > div a img {
	width: 40px !important;
  /*clip-path: inset(20px 20px 20px 40px);*/
 	margin-right: 5px;
 	margin-left: -20px !important;
}

.subcategory-mobile-menu2 > li > div i {
	line-height: 1.8rem !important;
}

.subcategory-mobile-menu3 > li > div {
	display:flex;
	/*border-top: 1px solid #fff;*/
}
.subcategory-mobile-menu3 > li > div a {
	 width:95% !important;
	 /*border-top: 0px ;*/
	 border-bottom: 1px solid #fff !important;
	 line-height: 1.8rem !important; 
	 font-size:14px !important;
	 padding-left: 24px;
}
.subcategory-mobile-menu3 > li > div i {
	line-height: 1.8rem !important;
}

.subcategory-mobile-menu4 > li > div {
	display:flex;
	/*border-top: 1px solid #fff;*/
}
.subcategory-mobile-menu4 > li > div a {
	 width:95% !important;
	 /*border-top: 0px ;*/
	 border-bottom: 1px solid #fff !important;
	 line-height: 1.8rem !important; 
	 font-size:14px !important;
	 padding-left: 36px;
}
.subcategory-mobile-menu4 > li > div i {
	line-height: 1.8rem !important;
}

.adjust-image{
		margin-left: -3px;
		margin-right: -6px;
		width:100%
	}
.showPass{
	border-color: #696158;
	padding-right: calc(1.5em + 0.75rem);
	background-image: url("../images/ico_eye_lock.png");
	background-repeat: no-repeat;
	background-position: right calc(0.375em + 0.1875rem) center;
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
	color: #696158;
	width: 16px;
	height: 16px;
	position: absolute;
	top: 23px;
	right: 3px;
	opacity: 0.7;
	cursor:pointer;
	z-index:990;
	background-color: transparent;
	border: 0px;
}
.hidePass{
	border-color: #696158;
	padding-right: calc(1.5em + 0.75rem);
	background-image: url("../images/ico_eye.png");
	background-repeat: no-repeat;
	background-position: right calc(0.375em + 0.1875rem) center;
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
	color: #696158;
	width: 16px;
	height: 16px;
	position: absolute;
	top: 1.6rem;
	right: 3px;
	opacity: 0.7;
	cursor:pointer;
	z-index:990;
	background-color: transparent;
	border: 0px;
}

.modal-frame {
  /*position: absolute;*/
  width: 95%;
  max-width: 95% !important;
 /*margin: 0 !important;*/
}
.slider-img{
	display: flex;
	justify-content: center;	
}

.link-form{
	color: #006bb4;
	text-decoration: none;
	font-size: 16px;
}

.link-form:hover{
	color: #006bb4 !important;
	text-decoration: underline !important;
	font-size: 16px;
}

.text-left {
	text-align: justify;
}


.order-status-resume {
  margin: 0px;
  background: #f0f0f0;
}

.order-status-resume .title{
	font-size: 20px;
	font-weight: bold;
}

.order-status-resume span{
	font-size: 22px
}

.order-status-resume-container{
	margin: 22px -10px 22px -10px;
}

.order-status-resume-header{
	line-height: 2rem;
}

.order-status-resume-header .resume-info{
	text-align: right
}

.order-status-resume-header .resume-info p {
	margin-bottom:0rem
}


.step-order-status-resume{
	width:25%;
	height: 100px;
/*	margin: 10px;*/
	background: #fff;
}


.order-status-resume .product-detail{
	background: #fff;
	margin:18px 18px 0 18px;
	border-radius:4px;
	width:100%;
	display:flex;
}
.order-status-resume .product-detail .image{

	width:20%;
	text-align: center;
	align-content: center;
	display: flex;
	align-items: center;
}

.order-status-resume .product-detail .image img{
	width: 55%;
} 
.order-status-resume .product-detail .detail{
	
	width:80%;
 	text-align:left;
 	font-size: 16px;
 	margin: 15px 0 20px 0;
 	
}


.order-status-resume .product-detail:last-child {
 margin-bottom: 20px;
 border-radius:4px;
}

.order-status-resume .product-detail .detail span{
 	font-size: 14px;
}

.order-status-resume .product-detail .detail p{
 	margin:0px
}


.step-order-status-resume img{
	height:107px;
	width:100%;
	/*margin-right:10px*/
}

.step-order-status-resume div {
	width:100%;
	height: 100%;
  /*margin: 10px;*/
	/*background: #f0f0f0;*/
	/*border-bottom: 12px solid #c8c8c8;*/
	border-radius: 3px;
	/*padding:3px*/
}

.step-order-status-resume .active {
	border-bottom: 12px solid #4ca480;
}

.order-status-customer {
	font-size: 18px;
}

.btn-text{
	border:0px;
	background: #fff;
	height: 49px;
	font-size: 16px;
	font-weight: bold !important;
	text-decoration: underline;
}

.valitation-message{
	color:red !important;
	position: absolute;
	/*top: 40px;*/
	top: 32px;
}

.useForm-valitation-message{
	color:red !important;
	position: absolute;
	top: 50px;
}

.aditional-message{
	position: absolute;
	top: 50px;
	font-size: 0.8rem;
}

.product-actions{
	max-width: 350px;
justify-content: center;
}

.popup-map{
	height:510px;
	padding: 0.5rem;
}
.pac-container{
	z-index: 99999;
}
.discount-span{
	color: #fff;
	font-weight: bold !important;
	background-color: #d50032;
	padding: 2px;
	border-radius: 3px;
	font-size: 13px !important;
	padding-right: 4px;
}
.cart-price{
	font-size: 15px !important;
	font-weight: 700;
}
.discount-span div {
  color: #d50032;
  width:100%;
  text-align: right !important;
  font-weight: bold !important;
  text-align: right !important;
}

.discount-2-span{
	color: #696158;
	font-weight: bold !important;
	/*background-color: #fff;*/
	padding: 2px;
	border-radius: 3px;
	font-size: 13px !important;
}

.discount-2-span div {
  color: #fff;
  width:100%;
  text-align: right !important;
  font-weight: bold !important;
  text-align: right !important;
}

.payment-type{
	border: 1px solid #e8e4e4;
	min-height: 50px;
	padding-top: 15px;
	border-radius: 5px;
	margin-right: -25px !important;

}

.autocomplete-dropdown-container{
	position: absolute;
	top: 45px;
	z-index:1050;
	width:94%;
	
	line-height:25px;
	
	padding-left: 0px !important;
}
.autocomplete-dropdown-container .loading{
	background:#fff;
	width:94%
}

.location-search-input{
	height: 40px !important;
	padding-left: 4px !important;
	margin-top: 0px !important;
	margin-bottom: 6px !important;
	width: 95% !important;
	border-top:0;
	border-left:0;
	border-right:0;
}


.social-footer-icon{
	width:  2rem;
	height: 2rem;
	margin-top:0.5rem;
	margin-right:0.5rem;
	opacity:0.8
}

.text-price{
	font-size:15px;
	font-family: 'Roboto','Helvetica Neue',Helvetica,Arial,sans-serif;
}
.mini-cart{
	max-height: 437px;
	overflow-x: clip;
	overflow-y: auto;
}

.discount-mini-label{
	color: #757575 !important;
  font-style: oblique;
	font-size: 11px;
	line-height : 1.5 !important;
	margin-top : 0.125rem !important;
}

.cursor-pointer{
	cursor:pointer
}

.box-bottom-container{
	
	position: absolute;
	left: 23px;
	right: 23px;
	bottom: 45px;
}

.box-bottom-container .label {
	width: 100%;
	min-height: 107px;
	background: rgba(255,255,255,.8)
}

.box-bottom-container .label .content {
	padding-top: 10px;
}
.box-bottom-container .label .content span {
	
	display: block;
	font-family: 'Roboto Condensed','Helvetica Neue',Helvetica,Arial,sans-serif;
	margin-bottom: -5px;
	font-weight: bold;
	color: #766e64;
	font-size: 21px;
}

.box-bottom-container .label .content a {
	
	display: block;
	font-family: 'Roboto Condensed','Helvetica Neue',Helvetica,Arial,sans-serif;
	margin-top: 12px; 
	font-weight: bold;
	color: #766e64;
	font-size: 21px;
}


.box-bottom-container-2{

	position: absolute;
	left: 23px;
	right: 23px;
	bottom: 30px;
}

.box-bottom-container-2 .label {
	width: 100%;
	min-height: 107px;
	background: rgba(255,255,255,.8)
}

.box-bottom-container .label .content {
	padding-top: 18px;
}
.box-bottom-container-2 .label .content span {
	
	display: block;
	font-family: 'Roboto Condensed','Helvetica Neue',Helvetica,Arial,sans-serif;
	margin-bottom: 0px; 
	margin-top: 12px;
	font-weight: bold;
	color: #766e64;
	font-size: 15px;
}

.box-bottom-container-2 .label .content a {
	
	display: block;
	font-family: 'Roboto Condensed','Helvetica Neue',Helvetica,Arial,sans-serif;
	margin-top: 12px; 
	font-weight: bold;
	color: #766e64;
	font-size: 21px;
}

/*
.box-bottom-container .label .content div {
	text-align: center;
}*/
.btn-img-red{
	background-color: #d50032;
	border-color: #d50032;
	color:#fff;
	border-radius: 0px !important;
}
.btn-img-red:hover {
	color:#fff;
}

.btn-img-yellow{
	background-color: #fee300;
	border-color: #fee300;
	color:#000;
	border-radius: 0px !important;
}
.btn-img-yellow:hover {
	color:#000;
}
.btn-img-blue{
	background-color: #009efb;
	border-color: #009efb;
	color:#fff;
	border-radius: 0px !important;
}
.btn-img-blue:hover {
	color:#fff;
}
.btn-img-green{
	background-color: #7bb13c;
	border-color: #7bb13c;
	color:#fff;
	border-radius: 0px !important;
}
.btn-img-green:hover {
	color:#fff;
}
.btn-img-gray{
	background-color: #696158;
	border-color: #696158;
	color:#fff;
	border-radius: 0px !important;
}
.btn-img-gray:hover {
	color:#fff;
}


.accordion {
  font-size: 17px;
  margin: 0px;
}

.accordion__button {
  border: 0;
  margin: 0;
  padding: 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: inherit;
  border-bottom: 1px solid #d7d7d7;
}

.accordion__button img { 
  width: 100%; 
}

.accordion__button span {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #000;
  font-size: 15px;
  margin-right: 10px;
}

.accordion__button:focus {
  outline: none;
}

.accordion__content {
  padding: 0 20px;
  opacity: 0;
  -webkit-transition: all .5s;
  transition: all .5s;
  
  height: 0;
  margin-bottom: -22px;
  /*margin-top: -10px;*/
  border: 1px solid #f1f1f1;
  border-top: 0;
  width:100%;
  height:0%;
  margin-top:0 !important
}

.accordion__content.active {
    height: auto;
    opacity: 1;
    padding: 0px;
    width:100%;
    height:100%; 
	margin-top:0 !important;
	margin-bottom: 0px !important;
}

.accordion__content img {
		width:100%;
		height:0px;
		 
}
.accordion__content.active img {
    width:100%;
    height:100%;
     
}

.accordion__button .title {
	height: 55px;
	display: flex;
	align-items: inherit;
	margin-left: 10px;
	font-size: 19px;
	font-weight: bold;
	text-align: left;
}

.accordion__button .icon {
	width: 20px;
	height: 20px;
	font-size: 9px;
	background: #696158;
}

.accordion__content_text {
  padding: 0 20px;
  opacity: 0;
  -webkit-transition: all .25s;
  transition: all .25s; 
  height: 0;
 	margin-bottom: 1px;
  /*margin-top: -10px;*/
  border: 1px solid #f1f1f1;
  border-top: 0;
  width:100%;
  height:0px;
  font-size: 17px;
	padding: 4px !important;
	text-align: justify;
}

.accordion__content_text.active {
    height: auto;
    opacity: 1;
    /*padding: 0px;*/
    width:100%;
    height:100%;
     
 	  /*min-height:50px;*/
}

.product-price-new-mini{
	font-weight: 700;
	color:#d50032;
	font-size: 15px !important;
/*	font-family: 'Roboto','Helvetica Neue',Helvetica,Arial,sans-serif;*/
	/*font-weight: bolder;*/
}
.product-price-old-mini{
	color: rgba(84,75,69,.7);
	text-decoration: line-through;
	font-size: 14px !important;
}

.font-sm {
	font-size:10px;
}
.col-text-quantity{
	justify-content: center;
	align-items: center;
}

.search-container-2{                                              	
	position: absolute;
	width: 900px;
	/*width: '50%', */
	/*left: calc(100% - 920px);*/
	top: 143px;
	/*height: calc(100% - 156px);*/
	min-height: 400px;
	flex-wrap:wrap;
	z-index: 1000;
	background-color: #fff;
	border: 1px solid #ccc;
	display: flex;
	padding-bottom: 15px;
                                         	
}
.suggest-label{
	cursor: pointer;
	margin-bottom: 0.5rem;
	margin-left:10px;
}

.searcher-mobile-col-prod{
		background:#fff;
		width: 100% !important;
		padding-right: 1px;
		padding-left: 1px; 
		max-height:243px;
		border: 1px solid #eee;
	}
 
.search-container-col-prod{
	background:#fff;
	width: 203px !important;
	padding-right: 15px;
	padding-left: 15px; 
	max-height:243px;
}
 
.search-container-col-prod:hover{
	background:#f0f0f0;
}

.search-container-col-prod-2{
	background:#fff;
	/*width: 243px !important;*/
	padding-right: 15px;
	padding-left: 15px; 
	/*max-height:243px;*/
}
 
.search-container-col-prod-2:hover{
	background:#f0f0f0;
}

.search-container-image-2 {
	width:200px;
	height: 200px;
	margin: 10px 0 10px 0 !important;
}

.sui-layout-body, .sui-layout-sidebar , .sui-sorting, .sui-sorting__label, .sui-select, 
.sui-multi-checkbox-facet, .sui-facet__title,sui-multi-checkbox-facet__option-count, .sui-multi-checkbox-facet__option-count ,
.sui-facet, .rc-pagination-item, .rc-pagination , sui-layout-main-footer, .sui-layout-header {
	
	font-family:"Roboto Condensed" !important;
	color:#696158 !important;
}
.sui-facet{
	margin-top: 18px !important;
}
/*
.rc-pagination-item {
	font-size:16px !important;
}*/

.rc-pagination {
  font-size: 13px !important;
  display: flex;
	padding-left: 0;
	list-style: none;  
}

.sui-multi-checkbox-facet__option-count{
	font-size: 0.9rem;
	cursor:pointer;
}
.sui-facet__title{
	font-weight:bold;
}

.sui-select__control{
	border: 1px solid #ccc;
}
.sui-multi-checkbox-facet__input-text{
	padding-left: 5px;
}
/*
.sui-select__control--is-focused{
	border: 1px solid #d50032;
}*/

.sui-layout-main-footer {
  margin-top: 10px;
}

.rc-pagination-item{
	margin: 0 3px !important;
}
.rc-pagination-item a{
	border: 1px #696158 solid;
	padding: 0 6px;
	box-shadow: none;
	outline: 0;
	width: 26px;
	height: 26px;
	display: block;

	color: #696969 !important;
	line-height: 25px;
	text-align: center;
	border-radius: 17px;
	cursor: pointer;
	background-color: #fff;
	margin-left: -1px;
	position: relative;
}

.rc-pagination-item-active a {
	color: #fff !important;
	background: #d50032 !important;;
}

.rc-pagination-item-link{
	color: #696969 !important;
}

.rc-pagination-item-link a{
	color: #696969 !important;
}

.rc-pagination-item-link a:hover{
	color: #696969 !important;
}

.rc-pagination-item a:hover {
  background: #f8f8f8;
	color: #fff !important;
	background: #d50032 !important;;  
}


.rc-pagination-item:hover {
  background: #f8f8f8;
  
}

.sui-layout-header{
	padding: 10px 10px;
	border-bottom: 1px solid #eeeeee !important;
}

.search-style-3 {
  width: 100%;
  margin-top: 18px;
}
.search-style-n {
  width: 100%;
}
.search-style-n div {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  justify-content: right;
}

.search-style-n div select {
  color: #1a1a1a;
  font-size: 16px;
  font-weight: 700;
  min-height: 45px;
  border: 1px solid #e2e9e1;
  border-right: none;
  width: 140px;
  padding: 3px 35px 3px 20px;
  border-radius: 4px 0 0 4px;
  background-color: #fff;
  -webkit-transition: all .3s ease 0s;
  transition: all .3s ease 0s;
  -moz-appearance: none;
  -webkit-appearance: none;
  position: relative;
}

.search-style-n div select:focus {
  color: #333;
}

.search-style-n div select::after {
  position: absolute;
}

.search-style-n div input {
  width: 38%;
  max-width: 300px;
  border-radius: 0;
  background-color: #fff;
  /*border-color: #414648;*/
  border-width: 0 1px 1px 0;
  border-style: solid;
  font-size: 14px;
 	background-image: url(../images/ico_search.png); 	
  background-repeat: no-repeat;
  background-position:  right;
  background-position-x:  right;
  padding-left: 9px;
  /*font-style: italic;*/
  border-color: #696158;
  padding-right: 18px;
  height: 24px;
	margin-top: 6px;
}

.search-style-n div input::-moz-input-placeholder {
  opacity: 1;
}

.search-style-n div input::-webkit-input-placeholder {
  opacity: 1;
}
/*
.search-style-2 form input:focus {
  border-color: #414648;
  border-width: 0 0 3px 0;
  border-style: solid;
}
*/
.search-style-n div button {
  position: absolute;
  right: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: transparent;
  padding: 0;
  border: none;
  font-size: 20px;
  color: #d50032;
  height: 100%;
  padding: 5px 17px;
}
.btn-add-coupon{
	border-radius: 15px;
	width: 74px;
	font-size: 12px;
}

.coupon-label{
	color:#f00;
	font-size:12px;
	
}
.couponmessage{
	border: 2px solid #d50028;
	border-radius: 10px;
	padding: 8px 0px 0px 8px;
	background: #f6f4f2;
}

.couponmessage p{
	margin-bottom: 0.5rem !important
}

.p-fix {
	margin-bottom: 0rem !important;
	margin-top: 0rem !important;
}
.mini-cart-icon {
	/*margin-top: 9px;
	margin-right: -13px;
	*/
}

.mini-cart-icon > span.pro-count {
	position: relative;
  right: 15px;
  top: -12px;
  color: #ffffff;
  height: 18px;
  width: 18px;
  border-radius: 100%;
  font-weight: 500;
  font-size: 11px;
  text-align: center;
  line-height: 18px;
  background-color: #d50032;
  display: inline-block
}

.mini-event-icon > span.pro-count {
	position: relative;
	right: -11px;
	top: -30px;
	color: #ffffff;
	height: 18px;
	width: 18px;
	border-radius: 100%;
	font-weight: 500;
	font-size: 11px;
	text-align: center;
	line-height: 18px;
	background-color: #d50032;
	display: inline-block;
  }

.mini-stores-icon {
	padding-top: 13px;
	padding-left: 0px;
}

.mini-stores-icon > img {
	max-width:34px;
}

.button-fit{
	line-height: normal !important;
	padding:0.275rem;
}

.share-url-container{
	padding: 1.2rem;
	border-radius: 9px;
	border: 1px solid #b3ada7;
	width:100%;
	color: #696158;
	font-size: 1rem;
}
.share-url-container span{
	 
	color: #696158;
}



.zoomable-tools{
	position: absolute;
	top: 8px;
	right: 8px;
	z-index:9999
}

.zoomable-tools div{
	background: #fff !important;
	border: 1px solid #fff !important;
	font-size:2rem;
	cursor:pointer;
	font-family: 'Roboto Light'  !important;
	font-weight: normal;
	text-align: center;
	opacity:0.75;
	padding-top:5px;
}

.zoomed-image {
   background-size: cover;
   cursor: pointer;
}
.tag-support-left{
	margin-right: -20px;
	border-bottom: 2px solid #fe235b;
	width: 20px;
	padding-top: 8px;
	position: relative;
	top: -7px;
}
.tag-support-right{
	margin-left: -20px;
	border-bottom: 2px solid #fe235b;
	width: 20px;
	padding-top: 8px;
	position: relative;
	top: -7px;
}
	
.blog-preview-container{
	width:80%
}

.blog-preview-container img{
	border-radius: 15px;
}

.iiz, .iiz__img, .iiz__zoom-img , .iiz__zoom-img {
  cursor: zoom-in;
}

.DragUploader-drag-box {
  /* background-color: green; */
  position: relative;
  /* padding: 15px; */
  margin-top: 25px;
}

.DragUploader-loader-box {
  position: absolute;
  top: 0;
  min-height: 90px;
  height: 100%;
  width: 100%;
  display: block;
  background-color: rgba(255, 255, 255, 0.8);
}

.DragUploader-progress-wrapper {
  width: 75px;
  height: 100%;
  margin: 0 auto;
  display: block;
  position: relative;
}

.DragUploader-progress {
  display: block;
  width: 75px;
  height: 75px;
  position: absolute;
  top: 50%;
  margin-top: -38px !important;
}

.close-button{
	position:relative;
	background: transparent;
	border:0px;
	z-index:999; 
	top: 1px;
	right: 6px;
	height: 36px;
	font-size:14px !important;
	font-weight: bold !important;
}

.validation-message p {
	margin-bottom: 0.25rem  !important;
}

.btn-xs, .btn-group-xs > .btn {
  padding: 0rem 0.5rem 0rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.45;
  border-radius: 0.2rem;
}

.event-sku-comment{
	text-align: left !important; 

	display:flex;
}

.event-sku-comment textarea{
	width:80%;
	border-bottom-left-radius: 15px;
	border-top-left-radius: 15px;
	background: #f6f4f2;
	border: 1px solid #f6f4f2;
	padding: 0.5rem 1rem 0rem 1rem;  
	line-height: 1rem;
}

.event-sku-comment div{
	width:20%;
	border-bottom-right-radius: 15px;
	border-top-right-radius: 15px;
	background: #f6f4f2;
	border: 1px solid #f6f4f2;
	display: flex;
	align-items: end;
	justify-content: right;
	padding-bottom: 0.5rem;
	padding-right: 0.5rem;

}
 
.modal-v2-container{
  position: fixed;
	display: flex;
	-moz-box-align: center;
	align-items: center;
	-moz-box-pack: center;
	justify-content: center;
  
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1066;
	opacity:1;  
	transition: all 0.25s ease-out;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	min-height: 180px;
}

.modal-v2-container-hide {
  position: fixed;
	display: flex;
	-moz-box-align: center;
	align-items: center;
	-moz-box-pack: center;
	justify-content: center;
	min-height: 180px;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: -1;    
	opacity:0;  
	transition: all 0.25s ease-out;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}


	
.modal-v2-container-body {
	position: absolute;
	top: calc(50% - 90px);	
	left: calc(50% - 185px);	
	/*transform: translate(-50%, -50%);*/
	max-width: 370px;
	background-color: #fff;
	width: 90%;
	min-width: 370px;
	padding:1rem;
	min-height:180px;
	/*display: flex;
	align-items: center;
	justify-content: center;*/
	font-size: 1.25rem;
	/*line-height: 2rem;*/
	line-height: 1rem;
	font-family: "Roboto";
	border-radius: 15px;
	border-bottom: 15px solid #676159;
 	text-align: justify;
 	opacity:1;
 	transition: all 0.5s ease-out;
}
 
.modal-v2-container-body .content {
	min-height: 140px;
	text-align: justify;
}
 
.modal-v2-container-body .btn-container{
	text-align: right;
}

.modal-v2-container-body-lg {
	position: absolute;
	top: calc(50% - 180px);	
	left: calc(50% - 400px);	
	/*transform: translate(-50%, -50%);*/
	max-width: 800px;
	background-color: #fff;
	width: 90%;
	min-width: 800px;
	padding:0.5rem 1rem 0.5rem 1rem;
	min-height:180px;
	/*display: flex;
	align-items: center;
	justify-content: center;*/
	font-size: 1.25rem;
	line-height: 2rem;
	font-family: "Roboto";
	border-radius: 15px;
	border-bottom: 15px solid #676159;
 	text-align: justify;
 	opacity:1;
 	transition: all 0.5s ease-out;
}
 
.modal-v2-container-body-lg .content {
	min-height: 110px;
	text-align: justify;
}
 
.modal-v2-container-body-lg .btn-container{
	text-align: right;
}

.modal-v2-container-body-lg-top {
	position: absolute;
	top: 35px;
	left: calc(50% - 400px);	
	/*transform: translate(-50%, -50%);*/
	max-width: 800px;
	background-color: #fff;
	width: 90%;
	min-width: 800px;
	padding:0.5rem;
	min-height:180px;
	/*display: flex;
	align-items: center;
	justify-content: center;*/
	font-size: 1.25rem;
	line-height: 2rem;
	font-family: "Roboto";
	border-radius: 15px;
	border-bottom: 15px solid #676159;
 	text-align: justify;
 	opacity:1;
 	transition: all 0.5s ease-out;
}
 
.modal-v2-container-body-lg-top .content {
	min-height: 110px;
	text-align: justify;
}
 
.modal-v2-container-body-lg-top .btn-container{
	text-align: right;
}


.image-hover img {
	opacity:1;
	transition: all 0.25s ease-out;
}

.image-hover img:hover {
	opacity:0.65;
	transition: all 0.25s ease-out;
}
.link-menu:hover   {
	cursor:pointer;
}
.link-menu:hover span {
	text-decoration: underline;
}
.event-image{
	color : #696158;
	/*height: 400px;
	max-height: 400px;*/
	width: 100%;
	/*margin-top: 0.5rem;*/
/*	min-width:640px;*/
}

.no-border {
	border : 0 !important;
}
.divider-container{
	width:85%;
	border-bottom: 1px solid #d50032;
	border-radius: 0;
}

.divider-container-2{
	width:85%;
	border-bottom: 1px solid #b3ada7;
	border-radius: 0;
}

.product-event-image {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    position: absolute;
    top: 4px;
    left: 1px;
    background-color: #676159;
    min-width: 26px;
    max-width:85px;
    padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 10px;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	border-radius: 15px;
	color: #fff;
	opacity: 0.9;
	z-index: 99;
	font-weight:400;
	font-size: 0.75rem; 
}
.event-pro-count {	 
  color: #ffffff !important;  
  background-color: #d50032 !important;
}
.badge-secondary {
	color: #fff;
	background-color: #d50032;
}
.btn-event{
	font-size: 1.5rem;
	color: #696158;
	font-weight: 700;
	border: 2px solid #696158;
	border-radius: 25px;
	padding: 5px 10px 5px 10px;
	cursor: pointer;
}

.btn-filled-event{
	font-size: 1.2rem;
	color: #696158;
	background-color: #fff;
	font-weight: 700;
	border: 2px solid #696158;
	border-radius: 25px;
	padding: 5px 15px 5px 15px;
	cursor: pointer;
}

.btn-filled-event:hover{
	color: #696158 !important;
}

.row-2 {
	margin-left: 0px;
	margin-right: 0px;
	flex-wrap: wrap;
}

.download-link {
	margin-bottom: 8px;
}

.show-events-modal .modal-content {
	background-color: #f4f3f0 !important;
	border-radius: 25px;
}
.show-events-modal .h4-responsive {
	color: #000;
	text-transform: uppercase;
}

.show-events-modal span {
	
	cursor: pointer;
	font-size: 1.25rem;
}

.show-events-modal img {
	height: 45px;
	width: 45px;													
	object-fit: cover;
	cursor: pointer;
}

.show-events-modal .body-content {
	max-height: 250px;
	min-height: 150px;
	overflow-y:auto;
	scroll-snap-align: 1;
}

.event-button2 , .event-button2:hover {
	background: #fff;
	border-radius: 25px;
	color: #696158;
	text-transform: uppercase;
	border: 2px solid #696158;
	font-size: 1.25rem;
	font-weight: bold;
}

.event-button {
	background: #000;
	border-radius: 25px;
	color: #fff;
	text-transform: uppercase;
}
.event-button:hover {
	background: #000;
	border-radius: 25px;
	color: #fff
}

.searcher-event .css-13cymwt-control {
	background: #f6f4f2;
	border-color: #f6f4f2;
	color:#000
}

.searcher-event .css-t3ipsp-control {
	background: #f6f4f2;
	border-color: #f6f4f2;
	color:#000
}
.searcher-event .css-t3ipsp-control :hover {
	background: #f6f4f2 !important;
	border-color: #f6f4f2 !important;
	color:#000;
}



.search1 {
	background: rgb(246, 244, 242);
	height: 73px;
	padding-top: 17px;
	border-top-left-radius: 25px;
	border-bottom-left-radius: 25px;
}

.search2 {
	background: rgb(246, 244, 242);
	height: 73px;
	padding-top: 17px;
	border-top-right-radius: 25px;
	border-bottom-right-radius: 25px;
}

.clickere {
	width: 45px !important;
	height: 45px;
	position: relative;
	top: 0;
	float: right
}


.big-text{
	display: flex;
	justify-content: center;
	font-size: 1.75rem;
	font-weight: 700;
	margin: 35px 0;
}
.highlighted-text{
	color: red !important
}


.login-with-google-btn {
  transition: background-color .3s, box-shadow .3s;
  cursor: pointer;
  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
  
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
  
  &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
  }
  
  &:active {
    background-color: #eeeeee;
  }
  
  &:focus {
    outline: none;
    box-shadow: 
      0 -1px 0 rgba(0, 0, 0, .04),
      0 2px 4px rgba(0, 0, 0, .25),
      0 0 0 3px #c8dafc;
  }
  
  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
  }
}
